import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import Details from '../Details/Details';
import MainTable from '../Table/MainTable';
import SupplementalMaterials from '../Details/SupplementalMaterials';
import AdditionalResources from '../Details/AdditionalResources';
import FormatUtil from '../../utils/formatUtil';
import ChevronDown from '../../Icons/ChevronDown';
import Loading from '../Loading/Loading';
import NiceTksList from './NiceTksList';

function ActivePane({ panes, active }) {
  return panes.length && panes[active] && panes[active].content ? panes[active].content : null;
}

function Module({ learningActivities, handleItemClick, isFreeUser, courseIsFree, enrollmentData }) {
  return learningActivities.map((module, idx) => {
    const key = module.title;
    const listPaddingStyle = idx !== 0 ? 'pt-5' : 'pt-0';
    return (
      <div key={key}>
        <Collapsible
          transitionTime={200}
          className="font-normal text-gray-600 module-collapsible"
          openedClassName="text-black font-semibold"
          open={idx === 0}
          triggerTagName="div"
          trigger={
            <div className={`flex items-center cursor-pointer ${listPaddingStyle}`}>
              <div className="flex-1 mr-4">
                <div>{FormatUtil.formatModuleTitle(module.title, idx + 1)}</div>
              </div>
              <div>
                <div className="text-right section-duration">{module.duration}</div>
              </div>
              <button className="flex justify-end w-12" aria-label="Toggle Module Dropdown">
                <ChevronDown classes="h-5 w-5" />
              </button>
            </div>
          }
        >
          <MainTable
            content={module.lessons}
            handleClick={handleItemClick}
            moduleNum={idx + 1}
            moduleKey={key}
            isFreeUser={isFreeUser}
            courseIsFree={courseIsFree}
            enrollmentData={enrollmentData}
          />
        </Collapsible>
      </div>
    );
  });
}

function setLearningModules(comingSoon, learningActivities, isFreeUser, courseIsFree, enrollmentData, handleItemClick) {
  // Check if learning activities (Modules) Exist
  if (!comingSoon && learningActivities && learningActivities.length) {
    return {
      label: 'OUTLINE',
      content: (
        <Module learningActivities={learningActivities} isFreeUser={isFreeUser} courseIsFree={courseIsFree} enrollmentData={enrollmentData} handleItemClick={handleItemClick} />
      ),
    };
  }
  return null;
}

function setDescription(description, shortDescription, typeStyle) {
  // Check if description or short description exists
  if (!!description || !!shortDescription) {
    return {
      label: typeStyle && typeStyle.descriptionLabel ? typeStyle.descriptionLabel : 'DESCRIPTION',
      content: <Details header="" classes="has-markdown-inside text-gray-600" content={description || shortDescription} markdown />,
    };
  }
  return null;
}

function setResources(meta, detailsHeaderStyles, additionalMaterials) {
  // We may have meta information attached to the course, which would include additional resources.
  if (meta && (meta.additionalResources || meta.supplementalMaterials)) {
    const { additionalResources, supplementalMaterials } = meta;
    const resourcesContent = [];
    if (supplementalMaterials && supplementalMaterials.length) {
      const supplementalMaterialsContent = (
        <div className="mb-8">
          <SupplementalMaterials items={supplementalMaterials} />
        </div>
      );
      resourcesContent.push(
        <Details key="supplementalMaterials" header="Supplemental Materials" headerStyle={detailsHeaderStyles} content={supplementalMaterialsContent} markdown={false} />
      );
    }
    if (additionalResources && additionalResources.length) {
      const additionalResourcesContent = (
        <div className="mb-8">
          <AdditionalResources items={additionalResources} />
        </div>
      );
      resourcesContent.push(
        <Details key="additionalResources" header="Additional Resources" headerStyle={detailsHeaderStyles} content={additionalResourcesContent} markdown={false} />
      );
    }
    if (resourcesContent.length) {
      return {
        label: 'RESOURCES',
        content: resourcesContent,
      };
    }
  } else if (additionalMaterials) {
    // Check if legacy additional materials Exist
    return {
      label: 'RESOURCES',
      content: <Details header="Syllabus / Recommended Materials" headerStyle={detailsHeaderStyles} content={additionalMaterials} markdown />,
    };
  }
  return null;
}

function setPrerequisites(prereqs, detailsHeaderStyles) {
  // Check if pre-reqs exist. If so, push to array
  if (!!prereqs && prereqs.length) {
    return {
      label: 'PRE-REQUISITES',
      content: <Details header="Pre-Requisites" headerStyle={detailsHeaderStyles} content={prereqs} />,
    };
  }
  return null;
}

function ContentBody({
  comingSoon,
  learningActivities,
  description,
  shortDescription,
  typeStyle,
  meta,
  additionalMaterials,
  prereqs,
  userIsFree,
  isUserOnActiveTeam,
  courseIsFree,
  enrollmentData,
  handleItemClick,
  niceTks,
}) {
  const [arePanesLoading, setPanesLoading] = useState(true);
  const [panes, setPanes] = useState([]);
  const [active, setActive] = useState(0);
  const isFreeUser = userIsFree && !isUserOnActiveTeam;
  useEffect(() => {
    const settingPanes = [];
    const detailsHeaderStyles = { fontWeight: '700', color: 'black', paddingBottom: '1rem' };
    // Learning Modules
    const learningModulesPane = setLearningModules(comingSoon, learningActivities, isFreeUser, courseIsFree, enrollmentData, handleItemClick);
    if (learningModulesPane) {
      settingPanes.push(learningModulesPane);
    }
    // Description
    const descriptionPane = setDescription(description, shortDescription, typeStyle);
    if (descriptionPane) {
      settingPanes.push(descriptionPane);
    }
    // Nice TKS tab
    if (niceTks?.length) {
      settingPanes.push({
        label: 'NICE TKS',
        content: <NiceTksList items={niceTks} />,
      });
    }
    // Resources
    const resourcesPane = setResources(meta, detailsHeaderStyles, additionalMaterials);
    if (resourcesPane) {
      settingPanes.push(resourcesPane);
    }
    // Prerequisites
    const prerequisitesPane = setPrerequisites(prereqs, detailsHeaderStyles);
    if (prerequisitesPane) {
      settingPanes.push(prerequisitesPane);
    }

    if (settingPanes.length) {
      setPanes(settingPanes);
      setPanesLoading(false);
    }
    return () => {
      setPanes([]);
    };
  }, []);

  if (arePanesLoading) {
    <Loading />;
  }
  return (
    <>
      <div className="flex flex-col pt-4 mb-8 w-full border-gray-400 sm:flex-row sm:border-b-xs lg:pt-12">
        {panes && panes.length
          ? panes.map((pane, idx) => {
              const isActive = active === idx;
              const buttonClass = isActive ? 'text-black border-cyb-pink-500 hover:border-black' : 'text-gray-600 border-transparent hover:text-black';
              return (
                <button key={pane.label} onClick={() => setActive(idx)} className={`px-4 py-2 sm:border-b-xs bg-transparent focus:outline-none ${buttonClass}`}>
                  {pane.label}
                </button>
              );
            })
          : null}
      </div>
      <ActivePane panes={panes} active={active} />
    </>
  );
}

export default ContentBody;
