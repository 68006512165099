import React from 'react';
import Icon from '../Icon/Icon';
import { useImmersive } from '../../providers/ImmersiveProvider';

function A11yPause() {
  const { includeA11yPause, setA11yPaused } = useImmersive();

  if (!includeA11yPause) {
    return null;
  }
  return (
    <button
      onClick={() => setA11yPaused(true)}
      className="flex absolute -top-48 focus:top-0 left-8 items-center py-2.5 px-6 text-sm font-bold 
        leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm transition-all"
    >
      <Icon name="pause" className="mr-2 w-6 h-6" /> Pause this video
    </button>
  );
}

export default A11yPause;
