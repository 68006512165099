import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer, inject } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import Container from '../Container/Container';
import Loading from '../Loading/Loading';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import SearchInput from '../Search/SearchInput';
import If from '../If/If';
import AddLink from '../AddLink/AddLink';
import useQueryParams from '../../hooks/useQueryParams';
import useSandboxCookie from '../../hooks/cookies/useSandboxCookie';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import BaselineEmptyText from './modules/BaselineEmptyText';
import { USERPILOT_EVENTS } from '../../constants';
import FormatUtil from '../../utils/formatUtil';
import { ChartSliceTooltip, ChartTooltip, ChartPointSymbol, CustomLineLayer, graphColorDefaults, getAssessmentPathDisplayName } from './shared';

// approximate duration of chart animated transition between zoomed/default states,
// 700ms seems to be the perfect number
const CHART_TRANSITION_DURATION = 700;

// timer to hold async chart interfacing actions,
// this allows us to safely clear timeouts when component unmounts
let CHART_DELTA_TIMER = null;

const getCellColorContrast = (score) => {
  if (score < 0) {
    return 'bg-white';
  }
  if (score >= 0 && score <= 40) {
    return 'bg-yellow-300 text-black';
  }
  if (score > 40 && score <= 60) {
    return 'bg-green-400 text-black';
  }
  if (score > 60 && score <= 80) {
    return 'bg-emerald-600 text-white';
  }
  if (score > 80 && score <= 89) {
    return 'bg-cyan-700 text-white';
  }
  return 'bg-blue-900 text-white';
};

function OverviewChart({ orgId, pathId, cumulativeScores }) {
  const { memberId } = useQueryParams();

  const { cumulativeUserScores, isLoadingCumulativeUserScores, cumulativeUserAttempts, isLoadingCumulativeUserAttempts, actions } = useCybAssessmentPath();

  const currentCumulativeUserScores = useMemo(() => cumulativeUserScores[`${orgId}${pathId}${memberId}`], [cumulativeUserScores, pathId, orgId, memberId]);

  const currentCumulativeUserAttempts = useMemo(() => cumulativeUserAttempts[`${orgId}${pathId}${memberId}`], [cumulativeUserAttempts, pathId, orgId, memberId]);

  const isLoading = useMemo(
    () => (isLoadingCumulativeUserScores && !currentCumulativeUserScores) || (currentCumulativeUserAttempts && !currentCumulativeUserAttempts),
    [currentCumulativeUserScores, currentCumulativeUserAttempts, isLoadingCumulativeUserScores, isLoadingCumulativeUserAttempts]
  );

  const parsedData = useMemo(() => {
    if (currentCumulativeUserAttempts?.default && currentCumulativeUserScores?.default) {
      const lowAttempts = Object.entries(currentCumulativeUserAttempts?.default)
        .filter(([key]) => key.includes('_Low_Attempts'))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      return Object.entries({
        ...currentCumulativeUserScores?.default,
        ...lowAttempts,
      });
    }

    return Object.entries(cumulativeScores?.default || {});
  }, [currentCumulativeUserScores?.default, currentCumulativeUserAttempts?.default, cumulativeScores?.default]);

  const getScatteredPointColor = useCallback(
    (lowAttemptDisplayName) => {
      // Cybersecurity Fundamentals_Low_Attempts > Cybersecurity Fundamentals
      const canonicalPathDisplayName = lowAttemptDisplayName.split('_Low_Attempts')[0];

      let graphColor = '';

      parsedData.forEach(([key], index) => {
        if (canonicalPathDisplayName === key) {
          graphColor = graphColorDefaults.d3SchemaCategory10[index];
        }
      });

      return graphColor;
    },
    [parsedData]
  );

  const formattedData = parsedData?.map(([key, value]) => {
    const displayName = getAssessmentPathDisplayName(key);

    let mappedData = [];

    if (memberId && !key.includes('_Low_Attempts')) {
      const consolidatedData = {};

      value.forEach((entry, index) => {
        if (index === value.length - 1) {
          consolidatedData.endPoint = {
            ...entry,
            marker: true,
            completed_at: moment(entry.completed_at).add(1, 'months').startOf('month').format('YYYY-MM-DD'),
          };
        } else if (entry.score !== null) {
          if (!(entry.score in consolidatedData) || entry.completed_at < consolidatedData[entry.score].completed_at) {
            consolidatedData[entry.score] = entry;
          }
        } else if (index === 0) {
          consolidatedData.starterPoint = {
            ...entry,
            marker: true,
          };
        }
      });

      const result = Object.values(consolidatedData);

      mappedData = result.map((entry, index) => {
        const score = Math.round(parseFloat(entry.score ?? 0));
        let y = Number(score);

        if (index === 0 && entry.marker) {
          const nextScore = Math.round(parseFloat(result[index + 1].score ?? 0));
          y = Number(nextScore);
        }

        return {
          x: moment(entry.completed_at).format('YYYY-MM-DD'),
          y,
          marker: entry.marker,
        };
      });
    } else {
      mappedData = value.map((entry) => {
        const score = Math.round(parseFloat(entry.score));
        const y = Number(score);

        return {
          x: moment(entry.completed_at ?? entry.period).format('YYYY-MM-DD'),
          y,
          scattered: displayName.includes('_Low_Attempts'),
          scatteredPointColor: displayName.includes('_Low_Attempts') ? getScatteredPointColor(displayName) : undefined,
        };
      });
    }

    return {
      id: displayName,
      data: mappedData,
    };
  });

  const [zoomChartData, setZoomChartData] = useState(null);

  const [isInteractive, setIsInteractive] = useState(true);

  // set the chart refresh key between zoomed/default states,
  // this way the crosshair and slices layers render smoothly between transitions
  const [chartRefreshKey, setChartRefreshKey] = useState('default');

  const chartData = useMemo(() => zoomChartData ?? formattedData, [formattedData, zoomChartData]);

  const tickValues = useMemo(() => {
    if (zoomChartData) {
      const mapped = zoomChartData
        .flatMap((struct) => struct.data)
        .filter((item) => !item.marker)
        .map((item) => item.y)
        .sort((a, b) => a - b);

      return FormatUtil.dedupArray(mapped);
    }

    return [0, 20, 40, 60, 80, 100];
  }, [zoomChartData]);

  const xScaleAndAxis = useMemo(() => {
    let maxDataPointsCount = 0;

    chartData?.forEach((struct) => {
      if (struct.data.length > maxDataPointsCount) {
        maxDataPointsCount = struct.data.length;
      }
    });

    const xScale = {
      format: '%Y-%m-%d',
      precision: memberId ? 'day' : 'month',
      type: 'time',
      useUTC: false,
    };

    const axisBottom = {
      tickSize: 10,
      tickPadding: 10,
      tickRotation: 0,
      format: memberId ? '%b %Y' : '%b %d %Y',
    };

    if (!memberId) {
      axisBottom.tickValues = 'every month';
    }

    if (memberId && maxDataPointsCount >= 4) {
      axisBottom.format = '%b %Y';
      axisBottom.tickValues = 'every month';
    }

    return {
      xScale,
      axisBottom,
    };
  }, [chartData, memberId]);

  const gridXValues = useMemo(() => {
    if (memberId) {
      return undefined;
    }

    return (zoomChartData ?? chartData)?.[0]?.data?.map((struct) => moment(struct.x).toDate());
  }, [memberId, chartData, zoomChartData]);

  const gridYValues = useMemo(() => {
    if (!zoomChartData) {
      return undefined;
    }

    return tickValues;
  }, [zoomChartData]);

  const layers = useMemo(() => {
    if (memberId) {
      return ['grid', 'markers', 'areas', CustomLineLayer, 'points', 'axes', 'mesh', 'slices', 'crosshair'];
    }

    return undefined;
  }, [memberId]);

  const STAT_SECTION_TITLE = useMemo(() => {
    if (memberId) {
      return 'User Performance by Assessment over time';
    }
    return 'Company Performance by Assessment over time';
  }, [memberId]);

  const STAT_SECTION_TOOLTIP = useMemo(() => {
    if (memberId) {
      return `The learner's best attempts for all Assessment Topics within the Assessment Path`;
    }
    return 'The average of your Team’s best attempts for all Assessment Topics within the Assessment Path';
  }, [memberId]);

  const onChartClick = useCallback(
    (point) => {
      if (memberId) {
        return;
      }

      if (zoomChartData) {
        // disable chart interactions between animated transitions
        setIsInteractive(false);

        // remove zoomed chart data
        setZoomChartData(null);

        CHART_DELTA_TIMER = setTimeout(() => {
          // enable chart interactions
          setIsInteractive(true);
          // set chart refresh key back to `default` status
          setChartRefreshKey('default');
        }, CHART_TRANSITION_DURATION);

        return;
      }

      // disable chart interactions between animated transitions
      setIsInteractive(false);

      // get the target x value (the month of the clicked slice)
      const targetXValue = point.points[0].data.xFormatted;

      // terraform chart data to represent zoomed state
      const updatedChartData = chartData.map((struct) => {
        // reference copy of struct data array
        let dataArray = [...struct.data];

        // get index of target x value
        const targetIndex = struct.data.findIndex((item) => item.x === targetXValue);

        if (targetIndex >= 0) {
          const startIndex = Math.max(0, targetIndex - 1);
          const endIndex = Math.min(struct.data.length - 1, targetIndex + 1);
          // slice dataArray to represent:
          // [
          //   `startIndex` (or previous month, if exists),
          //   `targetIndex` (or current target month),
          //   `endIndex` (or next month, if exists)
          // ]
          dataArray = struct.data.slice(startIndex, endIndex + 1);

          // if `targetIndex` is first item in array,
          // duplicate it and insert at the beginning
          if (targetIndex === 0) {
            const targetEntry = {
              ...dataArray[0],
            };
            dataArray.unshift({
              ...targetEntry,
              // set the `month` to one month before target x value
              x: moment(targetEntry.x).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            });
          }

          // if `targetIndex` is last item in array,
          // duplicate it and push to end
          if (targetIndex === struct.data.length - 1) {
            const targetEntry = {
              ...dataArray[dataArray.length - 1],
            };

            dataArray.push({
              ...targetEntry,
              // set the `month` to one month after target x value
              x: moment(targetEntry.x).add(1, 'months').startOf('month').format('YYYY-MM-DD'),
            });
          }

          // Add `marker` flag to start and end items,
          // This hides the point element for those values
          dataArray = dataArray.map((item, index) => {
            const reference = {
              ...item,
            };

            if (index === 0 || index === dataArray.length - 1) {
              reference.marker = true;
            }

            return reference;
          });
        }

        return {
          id: struct.id,
          data: dataArray,
        };
      });

      // set zoomed chart data
      setZoomChartData(updatedChartData);

      CHART_DELTA_TIMER = setTimeout(() => {
        // enable chart interactions
        setIsInteractive(true);
        // set chart refresh key to `zoomed` status
        setChartRefreshKey('zoomed');
      }, CHART_TRANSITION_DURATION);
    },
    [memberId, chartData, zoomChartData]
  );

  useEffect(() => {
    return () => {
      clearTimeout(CHART_DELTA_TIMER);
    };
  }, []);

  useEffect(() => {
    if (memberId && zoomChartData) {
      clearTimeout(CHART_DELTA_TIMER);
      setZoomChartData(null);
      setIsInteractive(true);
    }
  }, [memberId, zoomChartData]);

  useEffect(() => {
    if (memberId) {
      if (!currentCumulativeUserScores) {
        actions.getCumulativeUserScores(orgId, pathId, memberId);
      }
      if (!currentCumulativeUserAttempts) {
        actions.getCumulativeUserAttempts(orgId, pathId, memberId);
      }
    }
  }, [orgId, pathId, memberId]);

  return (
    <div className="relative">
      <div className="flex flex-row justify-between items-center mr-[40px]">
        <h3 className=" font-bold">{STAT_SECTION_TITLE}</h3>
        <Tooltip content={<span className="group flex w-full font-semibold text-center">{STAT_SECTION_TOOLTIP}</span>}>
          <div className="flex justify-center items-center h-full">
            <Icon name="question-circle" className="w-5 h-5 text-stone-400 hover:text-black" />
          </div>
        </Tooltip>
      </div>
      <div className="relative w-full h-[300px]">
        <ResponsiveLine
          data={chartData}
          key={chartRefreshKey}
          margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
          curve="monotoneX"
          xFormat="time:%Y-%m-%d"
          xScale={xScaleAndAxis.xScale}
          yFormat=" >-.2f"
          yScale={{
            type: 'linear',
            min: tickValues[0],
            max: tickValues[tickValues.length - 1],
            clamp: Boolean(zoomChartData),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            tickValues,
            format: (value) => `${value}%`,
          }}
          axisBottom={xScaleAndAxis.axisBottom}
          lineWidth={zoomChartData ? 6 : 2}
          colors={{ scheme: 'category10' }}
          layers={layers}
          pointSize={zoomChartData ? 15 : 10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointSymbol={ChartPointSymbol}
          tooltip={ChartTooltip}
          sliceTooltip={ChartSliceTooltip}
          onClick={onChartClick}
          gridXValues={gridXValues}
          gridYValues={gridYValues}
          enableSlices={memberId || zoomChartData ? false : 'x'}
          isInteractive={isInteractive}
          crosshairType="cross"
          useMesh
        />
        <If condition={isLoading}>
          <div className="flex absolute top-0 left-0 flex-col justify-center items-center w-full h-full bg-white/90">
            <Container>
              <Loading message="Loading..." />
            </Container>
          </div>
        </If>
      </div>
      <If condition={formattedData?.length > 0}>
        <div className="flex flex-row items-start ml-9">
          {formattedData?.map((entry, index) => (
            <If condition={!entry.id.includes('_Low_Attempts')} key={entry.id + index.toString()}>
              <div className="flex flex-row items-center py-1 px-2 mr-3 hover:bg-black/[0.03] rounded-sm cursor-default">
                <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: graphColorDefaults.d3SchemaCategory10[index] }} />
                <span className="text-[11px] font-semibold text-gray-600 whitespace-nowrap">{entry.id}</span>
              </div>
            </If>
          ))}
        </div>
      </If>
    </div>
  );
}

function HeaderLegend() {
  const definitions = useMemo(
    () => [
      {
        id: uuidv4(),
        label: 'No data',
        color: 'bg-white',
      },
      {
        id: uuidv4(),
        label: 'Novice',
        color: 'bg-yellow-300',
        minScore: 0,
        maxScore: 40,
      },
      {
        id: uuidv4(),
        label: 'Intermediate Low',
        color: 'bg-green-400',
        minScore: 41,
        maxScore: 60,
      },
      {
        id: uuidv4(),
        label: 'Intermediate',
        color: 'bg-emerald-600',
        minScore: 61,
        maxScore: 80,
      },
      {
        id: uuidv4(),
        label: 'Advanced Low',
        color: 'bg-cyan-700',
        minScore: 81,
        maxScore: 89,
      },
      {
        id: uuidv4(),
        label: 'Advanced',
        color: 'bg-blue-900',
        minScore: 90,
        maxScore: 100,
      },
    ],
    []
  );

  return (
    <div className="absolute bottom-15">
      <div className="flex flex-col items-start">
        {definitions.map((def) => (
          <div className="flex flex-row items-center mb-2" key={def.id}>
            <span className={`relative ${def.color} h-[10px] w-full rounded-full overflow-hidden min-w-[100px] ${def.color === 'bg-white' ? 'border border-black' : ''}`} />
            <span className="mx-2 text-sm font-semibold whitespace-nowrap">{def.label}</span>
            <If condition={Boolean(def.maxScore)}>
              <span className="text-sm whitespace-nowrap">
                ({def.minScore} - {def.maxScore}%)
              </span>
            </If>
          </div>
        ))}
      </div>
    </div>
  );
}

export function OverviewHeader({ team, topicBreakdown, learnersCount }) {
  return (
    <table className="sticky top-[30px] mt-18 bg-white border-collapse">
      {/* shim that allows `OverviewLearnerTable` to be completely hidden behind sticky `OverviewHeader` when scrolling  */}
      <div className="absolute top-[-30px] -mx-2 w-[105%] h-18 bg-white" />
      <thead>
        <tr>
          {/* First column header is not rotated */}
          <th className="!bg-transparent" />
          {/* Following headers are rotated */}
          <th className="h-[152px] whitespace-nowrap !bg-transparent">
            <div className="w-[70px] rotate-[315deg] translate-x-[40px] translate-y-[30px]">
              <span className="py-5 px-3 text-sm font-semibold border-b border-gray-400">
                <span className="relative -left-6">Total Learners Assessed</span>
              </span>
            </div>
          </th>
          {topicBreakdown.default.map((topic) => (
            <th className="h-[152px] whitespace-nowrap !bg-transparent" key={topic.topic_id}>
              <div className="w-[70px] rotate-[315deg] translate-x-[40px] translate-y-[30px]">
                <span className="py-5 px-3 text-sm font-semibold border-b border-gray-400">
                  <span className="relative -left-6">{topic.name}</span>
                </span>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="relative bg-white">
        <HeaderLegend />
        <tr>
          <th className="pl-4 font-semibold !bg-transparent border border-r-0 border-gray-400 text-start">{team?.name}</th>
          <td className="py-3 w-[70px] text-center border border-l-0 border-gray-400">
            <span>{learnersCount}</span>
          </td>
          {topicBreakdown.default.map((topic) => {
            const score = topic.score ? Math.round(parseFloat(topic.score)) : 0;
            const colorContrastClasses = getCellColorContrast(score);

            return (
              <td className={`py-3 w-[70px] text-center border border-gray-400 ${colorContrastClasses}`} key={topic.topic_id}>
                <span>{score}%</span>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

export function OverviewLearnerTable({ orgId, pathId, learners, topicBreakdown, downloadReport, downloadLoading }) {
  const navigate = useNavigate();

  const location = useLocation();

  const { memberId } = useQueryParams();

  // remove `Totals` entry from learners report data
  const DEFAULT_LEARNER_DATA = useMemo(() => learners.default.filter((entry) => entry.real_name !== 'Totals'), [learners]);

  const [filteredLearnerData, setFilteredLearnerData] = useState(DEFAULT_LEARNER_DATA);

  const [inputValue, setInputValue] = useState('');

  const mappedLearnerTopicData = useMemo(
    () =>
      filteredLearnerData.map((learner) => {
        const topicScores = topicBreakdown.default.map((topic) => {
          const score = learner[`assessment_course_${topic.course_id}`];

          return {
            course_id: topic.course_id,
            score,
          };
        });

        return {
          ...learner,
          topicScores,
        };
      }),
    [filteredLearnerData, topicBreakdown, memberId]
  );

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);

      setFilteredLearnerData(() => DEFAULT_LEARNER_DATA.filter((item) => item.real_name.toLowerCase().includes(value.trim().toLowerCase())));
    },
    [DEFAULT_LEARNER_DATA]
  );

  const onChange = useCallback(
    (e) => {
      const input = e.target.value;

      handleInputChange(input);
    },
    [handleInputChange]
  );

  const onClear = useCallback(() => {
    handleInputChange('');

    if (memberId) {
      navigate(window.location.pathname);
    }
  }, [memberId]);

  const onQueryParamChange = useCallback(() => {
    if (memberId) {
      // find the target learner based on `memberId` query param
      const targetLearner = filteredLearnerData.find((learner) => learner.user_id === Number(memberId));

      if (targetLearner) {
        // update controlled input value
        setInputValue(targetLearner.real_name);

        // filter learners by `user_id` of target learner
        setFilteredLearnerData((prevState) => prevState.filter((item) => item.user_id === targetLearner.user_id));

        // Fire UP event for query-param specific view
        // (UP can't see query params, so we fire an event to tell it we hit this specific nested view)
        Userpilot.track(USERPILOT_EVENTS.VIEWED_BASELINE_INDIVIDUAL_REPORT);
      }
    } else if (inputValue !== '') {
      onClear();
    }
  }, [memberId, filteredLearnerData, onClear]);

  const exportTableData = useCallback(() => downloadReport('assessment-path-learners', orgId, pathId, 'table', false), [orgId, pathId]);

  useEffect(() => {
    onQueryParamChange();
  }, [memberId]);

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-row justify-between mb-4">
        <div className="w-2/5">
          <SearchInput placeholder="Search Members" value={inputValue} onChange={onChange} ariaLabel="Search members" onClose={onClear} isClearable />
        </div>
        <Tooltip
          position="left"
          omitTabIndex
          triggerContent={
            <button
              aria-label="Export table data"
              className="p-2 text-sm font-bold text-center text-black bg-gray-200 rounded-sm disabled:opacity-20 disabled:cursor-wait"
              onClick={exportTableData}
              disabled={downloadLoading}
            >
              <Icon name="download" />
            </button>
          }
          content="Export table data"
        />
      </div>
      <table className="bg-white border-collapse">
        <tbody>
          {mappedLearnerTopicData.map((learner) => {
            // navigate to single member view by adding `memberId` and `memberName` query params
            const toHref = `${location.pathname}?memberId=${learner.user_id}&memberName=${learner.real_name}`;

            return (
              <tr key={learner.user_id}>
                <th className="pl-4 font-medium !bg-transparent border border-r-0 border-gray-400 text-start">
                  <AddLink className="hover:text-cyb-pink-500 hover:underline" to={toHref}>
                    {learner.real_name}
                  </AddLink>
                </th>
                {learner.topicScores.map((topicScore) => {
                  const score = topicScore.score !== undefined && topicScore.score !== null ? Math.round(parseFloat(topicScore.score)) : -1;
                  const colorContrastClasses = getCellColorContrast(score);
                  const toHrefScore = `${location.pathname}/${orgId}/paths/assessments/${pathId}/${topicScore.course_id}/summary?learnerId=${learner.user_id}&learnerName=${learner.real_name}&targetScore=${score}`;

                  return (
                    <td className={`py-3 w-[72px] text-center border border-gray-400 ${colorContrastClasses}`} key={topicScore.course_id}>
                      <If condition={score >= 0}>
                        <AddLink className="hover:underline" to={toHrefScore}>
                          {score}%
                        </AddLink>
                      </If>
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <If condition={mappedLearnerTopicData.length === 0}>
            <div>
              <p className="text-gray-600">No members found...</p>
            </div>
          </If>
        </tbody>
      </table>
    </div>
  );
}

const BaselineTeamsPathOverview = inject(
  'userStore',
  'reportsStore'
)(
  observer(({ userStore, reportsStore, pathId }) => {
    const orgId = userStore?.adminTeam?.id;

    const { isBaselineSandboxEnabled } = useSandboxCookie();

    const { learners, isLoadingLearners, topicBreakdowns, isLoadingTopicBreakdown, cumulativeScores, isLoadingCumulativeScores, actions } = useCybAssessmentPath();

    const { downloadReport, downloadLoading } = reportsStore;

    const currentPathLearners = useMemo(() => learners[pathId], [learners, pathId]);

    const currentPathLearnersCount = useMemo(() => {
      if (currentPathLearners?.default) {
        return currentPathLearners.default.length - 1;
      }
      return 0;
    }, [currentPathLearners]);

    const currentPathTopicBreakdown = useMemo(() => topicBreakdowns[pathId], [topicBreakdowns, pathId]);

    const currentPathCumulativeScores = useMemo(() => cumulativeScores[`${orgId}${pathId}`], [cumulativeScores, pathId, orgId]);

    useEffect(() => {
      if (!currentPathLearners) {
        actions.getLearners(orgId, pathId);
      }

      if (!currentPathTopicBreakdown) {
        actions.getTopicBreakdown(orgId, pathId);
      }

      if (!currentPathCumulativeScores) {
        actions.getCumulativeScores(orgId, pathId);
      }
    }, [orgId, pathId, currentPathLearners, currentPathTopicBreakdown, currentPathCumulativeScores]);

    if (isLoadingLearners || isLoadingTopicBreakdown || isLoadingCumulativeScores) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }

    if (!currentPathLearners || !currentPathTopicBreakdown || !currentPathCumulativeScores) {
      if (isBaselineSandboxEnabled) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }

      return (
        <Container>
          <BaselineEmptyText orgId={orgId} />
        </Container>
      );
    }

    return (
      <div className="flex flex-col mt-5">
        <OverviewChart orgId={orgId} pathId={pathId} cumulativeScores={currentPathCumulativeScores} />
        <div className="flex flex-col w-[calc(100%-8rem)]">
          <OverviewHeader team={userStore?.adminTeam} topicBreakdown={currentPathTopicBreakdown} learnersCount={currentPathLearnersCount} />
          <OverviewLearnerTable
            orgId={orgId}
            pathId={pathId}
            learners={currentPathLearners}
            topicBreakdown={currentPathTopicBreakdown}
            downloadReport={downloadReport}
            downloadLoading={downloadLoading}
            key={pathId}
          />
        </div>
      </div>
    );
  })
);

export default BaselineTeamsPathOverview;
