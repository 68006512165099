import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import AddLink from '../../components/AddLink/AddLink';
import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip/Tooltip';
import Icon from '../../components/Icon/Icon';

function TooltipWrapper({ shouldDisplay, contentText, iconColor }) {
  if (!shouldDisplay) {
    return null;
  }
  return (
    <Tooltip
      content={<span className="text-sm">{contentText}</span>}
      triggerContent={<Icon name="information-circle" className={`inline-block w-4 h-4 ${iconColor}`} active />}
      position="right"
      action="mouseenter"
      theme="dark"
      arrow
    />
  );
}

/**
 * Offer summary of the selected product
 */
function OfferSummary({ formattedOffer, loading }) {
  if (loading) {
    return <Loading />;
  }
  const { applyCoupon, adjustPricing, currentPrice, newPrice, currentBasePrice, newBasePrice, dollarAmountOffPrice, nextPaymentDate } = formattedOffer;
  if (!applyCoupon && !adjustPricing) {
    return null;
  }
  const basePrice = adjustPricing ? newBasePrice : currentBasePrice;
  let offerPrice = '';
  if (applyCoupon) {
    offerPrice = newPrice;
  }
  if (adjustPricing && !applyCoupon) {
    offerPrice = newBasePrice;
  }
  return (
    <table className="w-full border-collapse table-auto">
      <thead>
        <tr className="bg-none border-none">
          <th scope="col" className="col-span-2 py-2 px-4 text-left">
            <h3 className="my-2 text-xl font-black normal-case">Offer Summary</h3>
          </th>
          <th aria-label="empty-headercell" />
        </tr>
      </thead>
      <tbody className="text-sm">
        <tr className="bg-none border-b">
          <td className="py-2 px-4">
            <p>
              Your current subscription price{' '}
              <TooltipWrapper
                shouldDisplay={adjustPricing}
                contentText={`Our prices have changed and you currently pay an old subscription price. You will be charged the new price on your next bill
                if you choose to continue with your Cybrary Insider Pro membership.`}
                iconColor="text-red-500"
              />
            </p>
          </td>
          <td className="py-2 px-4 text-right">
            <p>{currentPrice}</p>
          </td>
        </tr>
        {offerPrice ? (
          <tr className="bg-none border-b">
            <td className="py-2 px-4">
              <p>
                The new lower price{' '}
                <TooltipWrapper shouldDisplay={applyCoupon} contentText={`${dollarAmountOffPrice} off of the ${basePrice} base price`} iconColor="text-gray-600" />
              </p>
            </td>
            <td className="py-2 px-4 text-right">
              <p>{offerPrice}</p>
            </td>
          </tr>
        ) : null}
        <tr className="bg-none border-b">
          <td className="py-2 px-4">
            <p>
              Your next payment date{' '}
              <TooltipWrapper
                shouldDisplay
                contentText="If you choose to continue with your Cybrary Insider Pro membership, the new price will take effect on your next payment date"
                iconColor="text-gray-600"
              />
            </p>
          </td>
          <td className="py-2 px-4 text-right">
            <p>{nextPaymentDate}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CancellationOffer({ subscriptionId, plan, gateway, formattedOffer, loading, updateSubscription }) {
  const navigate = useNavigate();
  const { applyCoupon, adjustPricing } = formattedOffer;
  let header = 'Would you like to continue your membership?';
  if (!loading && (adjustPricing || applyCoupon)) {
    const n = header.lastIndexOf('?');
    header = `${header.substring(0, n)} at a lower price${header.substring(n)}`;
  }
  const handleAcceptOffer = async () => {
    await updateSubscription();
    navigate(`/cybrary-insider-pro/thanks?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`);
  };
  return (
    <div className="pt-12 pb-8">
      <h2 className="mb-4 text-2xl text-black">We&apos;re sorry to hear you want to cancel your Insider Pro membership</h2>
      <p className="text-sm">
        Our job at Cybrary is to guide you to achieve success toward your career goals. If we have fallen short in providing you the tools and guidance you needed, then we want to
        do whatever we can to get you to your next level.
      </p>
      <Header as="h3" className="my-6">
        {header}
      </Header>

      <div>
        <OfferSummary formattedOffer={formattedOffer} loading={loading} />
      </div>

      {!loading && (
        <div className="my-8">
          <button
            className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
            onClick={() => handleAcceptOffer()}
          >
            Yes
          </button>
          <AddLink
            to={`/cybrary-insider-pro/steps?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`}
            className="py-2.5 px-6 mx-4 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
          >
            No
          </AddLink>
        </div>
      )}
      <div>
        <p className="text-sm">
          If you choose to continue your membership, we would like to connect with you personally to get you whatever you need to be successful. We hope you consider giving Insider
          Pro another chance to get you to your next level.
        </p>
      </div>
    </div>
  );
}

export default CancellationOffer;
