import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import './settings.css';
import withRouter from '../../components/Router/withRouter';
import Agents from '../../agents/agents';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import Title from '../../components/Title/Title';
import Tooltip from '../../components/Tooltip/Tooltip';
import UserTypeId from '../../components/UserType/UserTypeId';
import Modal from '../../components/Modal/Modal';
import Icon from '../../components/Icon/Icon';
import GaUtil from '../../utils/gaUtil';
import {
  CHARGEBEE_ANNUAL_CIP_PLANS,
  CHARGEBEE_ANNUAL_PLANS,
  CHARGEBEE_ANNUAL_TEAMS_PLANS,
  CHARGEBEE_MONTHLY_CIP_PLANS,
  PADDLE_PRICE_ID_CIP_ANNUAL,
  PADDLE_PRICE_ID_CIP_MONTHLY,
  PADDLE_PRICE_ID_TEAM_ANNUAL,
} from '../../constants';
import usePaddleSubscription from '../../hooks/checkout/usePaddleSubscription';
import CipCancelModal from '../../components/CIPCancelModel/CIPCancelModel';

function BillingButton({ isCip, activeSubscriptions }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const PaddleSubscriptions = usePaddleSubscription();

  const chargebeeTeamsSubscriptions = activeSubscriptions.filter((subscription) => CHARGEBEE_ANNUAL_TEAMS_PLANS.includes(subscription.plan_id));
  const paddleTeamsSubscriptions = activeSubscriptions.filter((subscription) => subscription.price_id === PADDLE_PRICE_ID_TEAM_ANNUAL);
  // if not a cip user and is not a teams subscription user return null...
  // note: although chargebee subscription users will return isEnterprise = true... they are different from standard teams
  if (!isCip && !chargebeeTeamsSubscriptions.length && !paddleTeamsSubscriptions.length) {
    return null;
  }

  if (loading) {
    return <p className="text-sm text-gray-600">Redirecting...</p>;
  }

  const navigateToBillingPortal = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    try {
      // For now, we only support one subscription at a time so we can just grab the most recent one
      const subscription = activeSubscriptions[activeSubscriptions.length - 1];
      let result;
      switch (subscription.source) {
        case 'paddle':
          result = await PaddleSubscriptions.getTransactionId(subscription.id);
          if (result) {
            window.location.href = `/upgrade/checkout/paddle?txid=${result}`;
          } else {
            throw new Error('No billing portal link found');
          }
          break;
        case 'chargebee':
          result = await Agents.subscriptions.chargebeePortal();
          if (result?.accessUrl) {
            window.location.href = result.accessUrl;
          } else {
            throw new Error('No billing portal link found');
          }
          break;
        default:
          throw new Error('Unknown subscription source');
      }
    } catch (err) {
      Bugsnag.notify(err);
      // If we are still here... there's an error that needs to be displayed
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  const handleOnClick = isCip ? navigateToBillingPortal : () => setIsModalOpen(true);
  return (
    <div>
      <button
        onClick={handleOnClick}
        className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm manage-subscriptions-button"
      >
        Manage Subscriptions
      </button>
      <Modal open={isModalOpen} toggle={() => setIsModalOpen(false)} position="center" size="sm" ariaLabel="Manage Subscription Modal" paddingBottom="pb-0">
        <div className="flex justify-center items-center p-8">
          <p>
            Please reach out to Support at{' '}
            <a className="text-cyb-pink-500" href="mailto: support@cybrary.it">
              support@cybrary.it
            </a>{' '}
            to cancel your Cybrary for Teams subscription.{' '}
          </p>
        </div>
      </Modal>
      {!!errorMessage && <p className="text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
}

function LabelStatus({ subscription, expiration_date }) {
  const { status, scheduled_changes } = subscription;
  let content = status.charAt(0).toUpperCase() + status.slice(1);
  let color = '';
  switch (status) {
    case 'active':
      // Paddle subscriptions have an active status when they are cancelled but still active
      // Check if we have a scheduled change and if it's a cancellation
      if (scheduled_changes?.action === 'cancel') {
        color = 'yellow';
        content = 'Non-Renewing';
      } else {
        color = 'green';
      }
      break;
    case 'cancelled':
      color = 'red';
      if (expiration_date > moment.now) {
        content = 'Non-Renewing';
      }
      break;
    default:
      color = 'yellow';
  }
  return (
    <Tooltip
      triggerContent={
        <div className="relative">
          <div className={`absolute top-0 right-0 w-8 h-4 bg-${color}-600 border-t-[1.75rem] border-l-[32px] border-t-transparent border-l-white`} />
          <Icon name="information-circle" className="absolute top-0 right-0 w-4 h-4 text-white" />
        </div>
      }
      content={content}
    />
  );
}
function CipStopAutomaticBillingButton({ isActiveSubscription, isPendingCancellation, isTeamsPlan, subscriptionId, plan, gateway, toggleCIPModal, user }) {
  if (!isActiveSubscription || isTeamsPlan || isPendingCancellation) {
    return null;
  }
  const handleOnClick = () => {
    GaUtil.fireEvent('Submit', 'CIP Cancellation', 'Cancel subscription');
    if (user?.is_cip) {
      toggleCIPModal(subscriptionId, plan, gateway);
    }
  };
  return (
    <div className="pt-4 mt-4 border-t-xs border-gray-400">
      <button className="px-6 h-10 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-cyb-gray-500 rounded-sm" onClick={handleOnClick}>
        Stop Automatic Billing
      </button>
    </div>
  );
}
function MembershipCards({ activeSubscriptions, isLoading, navigate, toggleCIPModal, user }) {
  if (isLoading) {
    return <Loading />;
  }

  if (activeSubscriptions.length === 0) {
    return <div>You do not have any active subscriptions</div>;
  }
  return (
    <div className="flex flex-col flex-wrap  md:flex-row">
      {activeSubscriptions.map((subscription) => {
        if (!subscription) {
          return null;
        }

        let text = 'Ended';
        switch (subscription.status) {
          case 'active':
            // Paddle subscriptions have an active status when they are cancelled but still active
            // Check if we have a scheduled change and if it's a cancellation
            if (subscription.scheduled_changes?.action === 'cancel') {
              text = 'Non-Renewing';
            } else {
              text = 'Renews';
            }
            break;
          case 'non_renewing':
          case 'cancelled':
            text = 'Non-Renewing';
            break;
          default:
            break;
        }

        const plan = CHARGEBEE_ANNUAL_PLANS.includes(subscription.plan_id) ? 'yr' : 'mo';
        const isTeamsPlan = CHARGEBEE_ANNUAL_TEAMS_PLANS.includes(subscription.plan_id) || PADDLE_PRICE_ID_TEAM_ANNUAL === subscription.price_id;
        const isAnnualCIPPlan = CHARGEBEE_ANNUAL_CIP_PLANS.includes(subscription.plan_id) || PADDLE_PRICE_ID_CIP_ANNUAL === subscription.price_id;
        const isMonthlyCIPPlan = CHARGEBEE_MONTHLY_CIP_PLANS.includes(subscription.plan_id) || PADDLE_PRICE_ID_CIP_MONTHLY === subscription.price_id;
        let subscriptionHeaderText = subscription.plan;
        if (isTeamsPlan) {
          subscriptionHeaderText = 'Cybrary for Teams - 1 Year';
        }
        if (isAnnualCIPPlan) {
          subscriptionHeaderText = 'Cybrary Insider Pro - 1 Year';
        }
        if (isMonthlyCIPPlan) {
          subscriptionHeaderText = 'Cybrary Insider Pro - 1 Month';
        }
        const scheduledChanges = subscription.scheduled_changes;
        // Paddle subscriptions have an active status when they are cancelled but still active
        // Make sure we have no scheduled changes or that we have a scheduled change that is not a cancellation before showing the stop automatic billing button
        const isPendingCancellation = scheduledChanges && scheduledChanges.action === 'cancel';
        const isActiveSubscription = subscription.status === 'active';
        return (
          <div key={subscription.id} className="relative mx-auto w-full md:mx-0 md:w-80">
            <div className="p-4 m-2 rounded-sm border-xs border-gray-400">
              <div className="absolute top-2 right-2 w-4 h-12">
                <LabelStatus subscription={subscription} />
              </div>
              <div>
                <h3 className="w-11/12 text-lg font-bold">{subscriptionHeaderText}</h3>
                <p className="text-sm text-gray-600">
                  {text} on {moment.unix(subscription.expiration_date).format('MMM Do, YYYY')}
                </p>
              </div>
              <CipStopAutomaticBillingButton
                isActiveSubscription={isActiveSubscription}
                isPendingCancellation={isPendingCancellation}
                isTeamsPlan={isTeamsPlan}
                subscriptionId={subscription.id}
                plan={plan}
                gateway={subscription.source}
                navigate={navigate}
                toggleCIPModal={toggleCIPModal}
                user={user}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function DisplayMembershipCards({ isEnterprise, activeSubscriptions, isLoading, navigate, toggleCIPModal, user }) {
  if (isEnterprise && !activeSubscriptions.length) {
    return null;
  }
  const { is_cip: isCip } = user;
  return (
    <>
      <Divider />
      <div className="flex justify-between w-full">
        <h2 className="mb-4 text-2xl font-black">Active subscriptions</h2>
        <BillingButton isCip={isCip} activeSubscriptions={activeSubscriptions} />
      </div>
      <MembershipCards activeSubscriptions={activeSubscriptions} isLoading={isLoading} navigate={navigate} toggleCIPModal={toggleCIPModal} user={user} />
    </>
  );
}

const SettingsMembership = inject(
  'commonStore',
  'userStore',
  'profileStore',
  'authStore'
)(
  observer(({ commonStore, userStore, profileStore, authStore, navigate }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCIPModal, setShowCIPModal] = useState(false);
    // state to proxy the subscriptionId and plan from the membership cards to the modal for query params
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [plan, setPlan] = useState(null);
    const [gateway, setGateway] = useState('chargebee');

    const fetchSubscriptions = async () => {
      setIsLoading(true);
      try {
        const subscriptionsResponse = await profileStore.getSubscriptions();
        setSubscriptions(subscriptionsResponse);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        commonStore.triggerToast('error', {
          errorCode: error.response.status,
        });
      }
    };

    useEffect(() => {
      authStore.fireAttributionEvent();
      profileStore.setActiveTab('Membership', 'settings');
      profileStore.setDefaultProfileData('membership');
      fetchSubscriptions();
      return () => {
        setSubscriptions([]);
        setIsLoading(true);
        profileStore.setInactiveTab('Membership', 'settings');
      };
    }, []);

    // Helps proxy state from the membership cards to the modal so it can set query params on the cancel link
    const toggleCIPModal = (toggleSubId, togglePlan, toggleGateway) => {
      setShowCIPModal(!showCIPModal);
      setSubscriptionId(toggleSubId);
      setPlan(togglePlan);
      setGateway(toggleGateway);
    };

    // Get active subscriptions.
    const activeSubscriptions = subscriptions?.filter((subscription) => subscription.status !== 'cancelled') || [];
    // Get CIP subscription from active subscriptions based on selected subscriptionId
    const cipSubscription = activeSubscriptions?.find(({ id }) => id === subscriptionId);

    // Get start and expiration dates for CIP subscription timeline in modal
    const cipSubscriptionStartedDate = cipSubscription ? cipSubscription.start_date : null;
    const cipSubscriptionExpiredDate = cipSubscription ? moment.unix(cipSubscription.expiration_date).format('MMM Do, YYYY') : null;
    const isFirstWeekCancellation = cipSubscriptionStartedDate && userStore.isFirstWeekCancellation(cipSubscriptionStartedDate);
    return (
      <Container className="mb-12 settings" size="md">
        <Title title="Membership" />
        <h2 className="mb-4 text-2xl font-black">Details</h2>
        <UserTypeId userStore={userStore} commonStore={commonStore} />
        <DisplayMembershipCards
          isEnterprise={userStore?.user?.is_enterprise}
          activeSubscriptions={activeSubscriptions}
          isLoading={isLoading}
          user={userStore.user}
          navigate={navigate}
          toggleCIPModal={toggleCIPModal}
        />
        <CipCancelModal
          showCIPModal={showCIPModal}
          toggleCIPModal={toggleCIPModal}
          subscriptionId={subscriptionId}
          plan={plan}
          gateway={gateway}
          cipSubscriptionExpiredDate={cipSubscriptionExpiredDate}
          isFirstWeekCancellation={isFirstWeekCancellation}
        />
      </Container>
    );
  })
);

export default withRouter(SettingsMembership);
