import React from 'react';

function NiceTksList({ items }) {
  if (!items?.length) {
    return null;
  }

  return (
    <div className="pb-4">
      <ol className="pl-4 list-decimal list-inside">
        {items.map((item) => {
          const { id, name, description } = item;
          return (
            <li key={id} className="py-2">
              {name} - {description}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default NiceTksList;
