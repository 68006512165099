import React from 'react';
import './series-display.css';
import ReactMarkdown from 'react-markdown';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';

/**
 * the header of a series landing page
 * @param props
 * @returns {*}
 * @constructor
 */
function SeriesDisplayHeader(props) {
  const bgImage = props.series && props.series.series_meta && props.series.series_meta.background_image ? props.series.series_meta.background_image : null;
  const seriesImage = props.series && props.series.thumbnail_url ? props.series.thumbnail_url : null;

  const bgStyles = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'grey',
  };
  if (bgImage) {
    bgStyles.backgroundImage = `url(${bgImage})`;
  }
  return (
    <div style={bgStyles}>
      <div className="series-overlay">
        <div className="series-display-content">
          <div className="md:grid md:grid-cols-2 md:gap-x-8">
            <div>
              <img src="https://www.cybrary.it/wp-content/uploads/2018/09/cybrary_live_new_logo_light_2.png" width="300px" alt="cybrary-live" />
              <h1 style={{ color: 'white' }}>{props.title}</h1>
              <div style={{ color: 'white' }} className="live-dark-description">
                <ReactMarkdown className="has-markdown-inside">{props.description}</ReactMarkdown>
              </div>
              <div style={{ margin: '2em 0' }}>
                {props.series && props.series.series_meta && props.series.series_meta.calendar_url ? (
                  <AddLink
                    className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
                    to={props.series.series_meta.calendar_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="calendar" className="inline-block mr-2 mb-1 w-4 h-4" />
                    Subscribe to calendar
                  </AddLink>
                ) : null}
              </div>
            </div>
            <div>{seriesImage ? <img src={seriesImage} style={{ height: 'auto', width: 'auto' }} alt={props.title} /> : null}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeriesDisplayHeader;
