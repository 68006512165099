import React from 'react';
import { observer, inject } from 'mobx-react';
import Icon from '../Icon/Icon';
import Header from '../Header/Header';
import AdminTable from '../AdminTable/AdminTable';
import Table from '../Table/Table';
import AddLink from '../AddLink/AddLink';
import Segment from '../Segment/Segment';
import FormInviteModal from './FormInviteModal';
import MemberTableActions from './MemberTableActions';
import FormatUtil from '../../utils/formatUtil';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import GroupAddMembers from './GroupAddMembers';
import CreateGroup from './CreateGroup';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Container from '../Container/Container';

const GroupMembers = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(
    class GroupMembers extends React.Component {
      state = {
        inviteModalOpen: false,
        addMembersModalOpen: false,
        editGroupModalOpen: false,
      };

      componentDidMount() {
        this.getGroupMembers();
      }

      componentDidUpdate(prevProps) {
        // If group ID in url changes (if using switcher), get new data
        if (this.props.groupId !== prevProps.groupId) {
          this.getGroupMembers();
        }
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultTeamMemberTableData();
        this.props.enterpriseStore.setDefaultGroupList();
        this.props.enterpriseStore.setDefaultTeamInviteUrl();
      }

      /**
       * Get group members and group meta
       */
      getGroupMembers = () => {
        const { groupId } = this.props;
        this.props.enterpriseStore.setDefaultTeamInviteUrl();
        this.props.enterpriseStore.setDefaultTeamMemberTableData();
        if (groupId) {
          this.props.enterpriseStore.getTeamMemberTableData(groupId, false, false);
          this.props.enterpriseStore.getGroupProfileData(groupId);
        }
      };

      /**
       * handle invite modal close
       */
      modalClose = () => {
        const newState = { ...this.state, inviteModalOpen: false };
        this.setState(newState);
      };

      /**
       * handle invite modal open
       */
      openInviteModal = () => {
        // If we don't already have the invite URL, get it
        if (!this.props.enterpriseStore.teamInviteUrl.url) {
          this.props.enterpriseStore.getTeamInviteUrl(this.props.groupId);
        }
        const newState = { ...this.state, inviteModalOpen: true };
        this.setState(newState);
      };

      /**
       * handle add members modal toggle
       */
      toggleEditMembersModal = (view, status) => {
        const newState = {
          ...this.state,
        };
        newState[view] = status || !newState[view];
        this.setState(newState);
      };

      /**
       * Handles header checkbox change - Selects/deselects all child checkboxes
       */
      headerCheckBoxFunc = (e, { checked }) => {
        this.props.enterpriseStore.setAllSelectedRows('teamMemberTableData', checked);
      };

      /**
       * Handles checkbox changes - Accumulates selection indexes in an array
       */
      handleCheckBoxes = (e, { checked }, idx) => {
        this.props.enterpriseStore.setCheckboxState(this.props.enterpriseStore.teamMemberTableData, idx, checked);
      };

      /**
       * Sets sort column and direction vars in store for service call query params
       */
      tableSort = (sortHeading) => {
        if (sortHeading) {
          if (sortHeading === this.props.enterpriseStore.teamMemberTableData.queryParams.sortCol) {
            this.props.enterpriseStore.teamMemberTableData.queryParams.sortDirection =
              this.props.enterpriseStore.teamMemberTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            this.props.enterpriseStore.teamMemberTableData.queryParams.sortCol = sortHeading;
            this.props.enterpriseStore.teamMemberTableData.queryParams.sortDirection = 'desc';
          }
          this.props.enterpriseStore.teamMemberTableData.queryParams.activePg = 1;
          this.props.enterpriseStore.getTeamMemberTableData(this.props.groupId);
        }
      };

      /**
       * Sets page number from pagination in store for service call query params
       */
      pagChangePg = (page) => {
        this.props.enterpriseStore.teamMemberTableData.queryParams.activePg = page;
        this.props.enterpriseStore.getTeamMemberTableData(this.props.groupId);
      };

      /**
       * Sets up a custom column to be passed into the admin table component
       */
      customTableCol = (data) => {
        const userIdIdx = FormatUtil.getColIndex(this.props.enterpriseStore.teamMemberTableData.tableHeadings, 'id');
        const userId = userIdIdx > -1 ? data[userIdIdx].value : null;
        if (!userId) {
          return null;
        }
        return (
          <Table.Cell collapsing key={userId}>
            <AddLink className="text-cyb-pink-500 hover:text-black underline cursor-pointer" to={`/enterprise/${this.props.orgId}/organization/member/${userId}/dashboard`}>
              View
            </AddLink>
          </Table.Cell>
        );
      };

      /**
       * Format the role value shown in the table
       */
      formatRole = (col) => {
        if (!!col && col.value) {
          return FormatUtil.convertRoleName(col.value, 'Group');
        }
        return null;
      };

      /**
       * After adding members in modal view, refresh the member table and close the modal
       */
      editGroupCallback = (view) => {
        // If edited a group, get new group data. Otherwise, get new member data
        if (view === 'editGroupModalOpen') {
          this.props.enterpriseStore.getGroupProfileData(this.props.groupId);
        } else {
          this.props.enterpriseStore.getTeamMemberTableData(this.props.groupId, false, true);
        }
        this.toggleEditMembersModal(view, false);
      };

      /**
       * Display icon to link to page to change group info or open modal based on props
       */
      getEditGroupIcon = () => {
        if (!this.props.userStore.team || !this.props.userStore.team.permissions || !this.props.userStore.team.permissions.canManageTeam) {
          return null;
        }
        return (
          <>
            <button onClick={() => this.toggleEditMembersModal('editGroupModalOpen', true)}>
              <Icon name="edit" className="w-5 h-5 text-cyb-pink-500" />
            </button>
            <Modal open={this.state.editGroupModalOpen} toggle={() => this.toggleEditMembersModal('editGroupModalOpen', false)} arialLabel="Edit group">
              <div className="py-8 px-4">
                <CreateGroup teamId={this.props.parentId} groupId={this.props.groupId} view="edit" modalView callback={() => this.editGroupCallback('editGroupModalOpen')} />
              </div>
            </Modal>
          </>
        );
      };

      /**
       * Display button to link to page to add members or open modal based on props
       */
      getAddMembersButton = () => {
        return (
          <>
            <Button color="gray" onClick={() => this.toggleEditMembersModal('addMembersModalOpen', true)}>
              Add Members
            </Button>
            <Modal open={this.state.addMembersModalOpen} toggle={() => this.toggleEditMembersModal('addMembersModalOpen', false)} ariaLabel="Add members to group">
              <div className="px-4 pt-12">
                <GroupAddMembers groupId={this.props.groupId} submitCallback={() => this.editGroupCallback('addMembersModalOpen')} parentGroupName={this.props.parentGroupName} />
              </div>
            </Modal>
          </>
        );
      };

      render() {
        const topText = 'Invite new members to your group!\nClick the icon to copy the url and send to the person you want to invite.';
        const bottomText = 'Once they click it, they will be prompted to create an account and will automatically join the group.\nZero hassle.';
        const { invitedTeamMembers, getTeamInviteUrlByEmail, getInvitedTeamMembers, groupProfileData, teamInviteUrl, teamMemberTableData } = this.props.enterpriseStore;
        const { triggerToast } = this.props.commonStore;
        const { auto_grant_seats, permissions } = this.props.userStore.team;
        const { viewOnly, omitDashboardLinks } = this.props;

        if (groupProfileData.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (groupProfileData.error) {
          return (
            <Container>
              <StyledError error={groupProfileData.error} />
            </Container>
          );
        }
        const { role, license, groupId, searchQuery } = teamMemberTableData.queryParams;
        return (
          <Segment className="border-none organization-group-members">
            <div className="lg:grid lg:grid-cols-12">
              <div className="lg:col-span-9">
                <div className="flex gap-x-4 items-start">
                  <Header id="group-title" as="h2">
                    {groupProfileData.profileData.name}
                  </Header>
                  {!viewOnly ? this.getEditGroupIcon() : null}
                </div>
              </div>
              {!viewOnly && (
                <div className="lg:col-span-3 lg:text-right invite-btn">
                  <FormInviteModal
                    url={teamInviteUrl.url}
                    loading={teamInviteUrl.loading}
                    error={teamInviteUrl.error}
                    modalOpen={this.state.inviteModalOpen}
                    modalClose={this.modalClose}
                    triggerToast={triggerToast}
                    getTeamInviteUrlByEmail={getTeamInviteUrlByEmail}
                    getInvitedTeamMembers={getInvitedTeamMembers}
                    autoGrantSeats={auto_grant_seats}
                    invitedTeamMembers={invitedTeamMembers}
                    topText={topText}
                    groupId={this.props.groupId}
                    orgId={this.props.orgId}
                    bottomText={bottomText}
                    omitLicenseSelection={!permissions || !permissions.canManageAdmins}
                  />
                  <button
                    className="py-2.5 px-6 mr-2 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
                    onClick={() => this.openInviteModal()}
                  >
                    + Invite to Group
                  </button>
                  {this.getAddMembersButton()}
                </div>
              )}
            </div>
            <MemberTableActions
              teamId={this.props.groupId}
              groupScope
              customRoleConfirmHandler={this.props.customRoleConfirmHandler}
              customRemoveConfirmHandler={this.props.customRemoveConfirmHandler}
              viewOnly={!!viewOnly}
            />
            <AdminTable
              /* Table Data */
              headings={teamMemberTableData.tableHeadings}
              data={teamMemberTableData.tableData}
              tableLoading={teamMemberTableData.loading}
              tableError={teamMemberTableData.error}
              displayCheckBox={!viewOnly}
              headerCheckBoxFunc={this.headerCheckBoxFunc}
              headerClickFunc={this.tableSort}
              sortCol={teamMemberTableData.queryParams.sortCol}
              sortDirection={teamMemberTableData.queryParams.sortDirection}
              rowCheckBoxFunc={this.handleCheckBoxes}
              headerCheckBoxState={teamMemberTableData.headerCheckBox}
              checkBoxesState={teamMemberTableData.ckBoxesState}
              addCustomCol={
                !omitDashboardLinks
                  ? [
                      {
                        method: this.customTableCol,
                        position: 6,
                        header: 'Profile',
                      },
                    ]
                  : null
              }
              /* Pagination options */
              pagNumPgs={teamMemberTableData.pagPagesCount}
              pagChangePg={this.pagChangePg}
              pagActivePg={teamMemberTableData.queryParams.activePg}
              formatColumns={[
                {
                  method: this.formatRole,
                  colIdx: FormatUtil.getColIndex(teamMemberTableData.tableHeadings, 'role'),
                },
              ]}
              numOfResults={teamMemberTableData.totalRecords}
              showResultsSummary={role || license || groupId || (searchQuery && searchQuery.length)}
            />
          </Segment>
        );
      }
    }
  )
);

export default GroupMembers;
