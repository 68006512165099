import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import WidgetContainer from '../../components/Container/WidgetContainer';
import RoleDropdown from '../../components/Dashboard/CybraryAssessmentsWidget/RoleDropdown';
import Loading from '../../components/Loading/Loading';
import If from '../../components/If/If';
import StyledError from '../../components/Error/StyledError';
import AssessmentsGraphWidget from '../../components/Measure/Assessments/AssessmentsGraphWidget';
import AssessmentsTableWidget from '../../components/Measure/Assessments/AssessmentsTableWidget';

const Assessments = inject(
  'authStore',
  'userStore',
  'commonStore'
)(
  observer(({ authStore, userStore, commonStore }) => {
    const orgId = userStore?.teamData?.id;

    const { tracks, error, isLoading: isLoadingTracks } = useCybAssessmentAnalytics();

    const roles = useMemo(
      () =>
        tracks[userStore.user?.id]
          ?.filter((track) => track.status !== 'Unlisted')
          .concat()
          .reverse(),
      [tracks, userStore.user?.id]
    );

    const [selectedRole, setSelectedRole] = useState(roles?.[0] ?? null);

    const onChangeRole = useCallback(
      (roleId) => {
        const role = roles?.find((item) => item['content-id'] === roleId);

        if (role) {
          setSelectedRole(role);
        }
      },
      [roles]
    );

    useEffect(() => {
      commonStore.setPageTitle('Measure - Assessments | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    useEffect(() => {
      if (roles?.length > 0 && !selectedRole) {
        setSelectedRole(roles[0]);
      }
    }, [roles]);

    return (
      <Container className="pt-0 sm-576:px-4" md omitPadding>
        <Title title="Assessments" omitPadding wrapperClasses="my-8" classes="text-2xl lg:text-2xl" />
        <div className="flex flex-col">
          <div className="flex flex-col py-8 px-14 mb-8 text-[15px] bg-gray-100">
            <p>
              Take Cybrary&apos;s role-based assessments to measure your strengths and weaknesses in key topic areas. Get custom learning recommendations from Cybrary&apos;s
              content catalog to improve your scores.
            </p>
          </div>
          <If condition={isLoadingTracks}>
            <Loading message="Loading..." />
          </If>
          <If condition={error}>
            <StyledError error={error} />
          </If>
          <If condition={!isLoadingTracks && !error}>
            <WidgetContainer omitPadding>
              <div className="flex absolute top-0 right-0 z-20 flex-row items-center mt-4 mr-4">
                <span className="mr-2 font-bold capitalize">role:</span>
                <RoleDropdown optionData={roles} controlledValue={selectedRole} onChange={onChangeRole} isCompact />
              </div>
              <AssessmentsGraphWidget orgId={orgId} memberId={userStore.user?.id} selectedRole={selectedRole} />
              <div className="mx-auto mt-7 mb-5 w-3/4 border-b-1 border-b-gray-300" />
              <AssessmentsTableWidget orgId={orgId} memberId={userStore.user?.id} selectedRole={selectedRole} />
            </WidgetContainer>
          </If>
        </div>
      </Container>
    );
  })
);

export default Assessments;
