import React, { useMemo } from 'react';
import withRouter from '../../components/Router/withRouter';
import AssessmentSummary from '../../components/Assessments/AssessmentSummary';
import useQueryParams from '../../hooks/useQueryParams';
import Container from '../../components/Container/Container';
import ValidationUtil from '../../utils/validationUtil';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';

function AssessmentResultsSummary({ match: { params } }) {
  const { orgId, pathId, contentId } = params;
  const {
    tracks,
    isLoading,
    actions: { getTrackIdByAssessmentId },
  } = useCybAssessmentAnalytics();
  const { learnerId, learnerName, teamId } = useQueryParams();

  const matchedPathId = useMemo(() => {
    if (pathId === 'match') {
      const trackId = getTrackIdByAssessmentId(contentId);

      if (!trackId) {
        return null;
      }

      return trackId;
    }
    return pathId;
  }, [pathId, contentId, tracks]);

  const rootBreadcrumb = useMemo(() => {
    let href = '';
    let label = '';

    const { pathname } = window.location;

    const segments = pathname.split('/');

    if (segments[1] === 'baseline') {
      if (segments[2] === 'teams') {
        // Remove the last two url segments,`/:contentId/summary`
        segments.pop();
        segments.pop();

        // reconstruct href
        href = segments.join('/');

        // append applicable query params
        if (learnerId && learnerName) {
          href += `?memberId=${learnerId}&memberName=${learnerName}`;
        }

        // define label
        label = 'Baseline Teams';
      } else {
        href = '/baseline';
        label = 'Baseline';
      }
    } else if (segments[1] === 'measure') {
      if (segments[2] === 'baseline') {
        const splitPathParts = pathname.split('/paths');

        [href] = splitPathParts;

        // define label
        label = 'Baselining Goal';
      } else if (segments[2] === 'assessments') {
        if (segments[3] === 'results') {
          href = '/measure/assessments/results';
          label = 'Assessment Results';
        } else {
          href = '/measure/assessments';
          label = 'Assessments';
        }
      }
    } else if (pathname.includes('/organization/member/')) {
      const basePath = pathname.split('/paths/assessments/match')[0];

      href = `${basePath}/dashboard`;
      label = learnerName ?? 'Member Dashboard';
    } else {
      href = `/enterprise/${orgId}/paths/assessments`;
      label = 'Paths';
    }

    return {
      href,
      label,
    };
  }, [orgId, learnerId, learnerName]);

  const breadcrumbs = useMemo(() => {
    if (!tracks || (!ValidationUtil.isValidNumber(matchedPathId) && !ValidationUtil.isValidNumber(contentId) && !ValidationUtil.isValidNumber(learnerId))) {
      return null;
    }

    const { pathname } = window.location;

    const segments = pathname.split('/');

    const isRootBaseline = rootBreadcrumb.label.includes('Baseline') && !rootBreadcrumb.href.includes('/measure');

    const isRootMeasure = rootBreadcrumb.href.includes('/measure');

    const isRootMemberDashboard = rootBreadcrumb.href.includes('/organization/member/') && rootBreadcrumb.href.includes('/dashboard');

    const crumbies = [rootBreadcrumb];

    if (isRootMemberDashboard) {
      return crumbies;
    }

    const assessmentPath = tracks[Number(learnerId)]?.find((track) => track['content-id'] === Number(matchedPathId));

    if (assessmentPath) {
      let href = '';

      if (isRootBaseline || isRootMeasure) {
        href = rootBreadcrumb.href;
      } else {
        // Remove the last two url segments,`/:contentId/summary`
        segments.pop();
        segments.pop();
        // reconstruct href
        href = segments.join('/');
      }

      // define label
      const label = assessmentPath['content-name'];

      crumbies.push({
        href,
        label,
      });
    }

    const assessment = assessmentPath?.children.find((assess) => assess['content-id'] === Number(contentId));

    if (assessment) {
      let href = '';

      if (isRootBaseline || isRootMeasure) {
        href = rootBreadcrumb.href;
      } else {
        // Remove the last url segment, `/summary`
        segments.pop();
        // reconstruct href
        href = segments.join('/');
      }

      // define label
      const label = assessment['content-name'];

      crumbies.push({
        href,
        label,
      });
    }

    if (learnerId && learnerName) {
      crumbies.push({
        href: window.location.pathname + window.location.search,
        label: learnerName,
      });
    }

    return crumbies;
  }, [orgId, learnerId, learnerName, matchedPathId, contentId, tracks, window.location]);

  if (!matchedPathId) {
    if (isLoading) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }

    return (
      // empty `error` object returns default error message
      <Container>
        <StyledError error={{}} />
      </Container>
    );
  }

  return (
    <Container className="pt-0 sm-576:px-4" lg omitPadding>
      <AssessmentSummary pathId={matchedPathId} contentId={contentId} userId={learnerId} orgId={orgId ?? teamId} breadcrumbs={breadcrumbs} />
    </Container>
  );
}

export default withRouter(AssessmentResultsSummary);
