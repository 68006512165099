import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Modal from '../Modal/Modal';
import Checkbox from '../FormFields/Checkbox';
import Loading from '../Loading/Loading';
import If from '../If/If';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

function confirmContinue(onConfirm, doNotShowAgainId, selectedDoNotShowAgain) {
  // If this confirm has a 'Do Not Show Again' option, and it was selected set in cookie for future clicks
  if (doNotShowAgainId && selectedDoNotShowAgain) {
    const COOKIE_PROPS = {
      ...DEFAULT_COOKIE_SETTINGS,
      path: '/',
      expires: 730,
    };

    const dismissedConfirmsCookie = Cookies.get('dismissedConfirms');
    const dismissedConfirms = dismissedConfirmsCookie ? dismissedConfirmsCookie.split(',') : [];
    dismissedConfirms.push(doNotShowAgainId);
    Cookies.set('dismissedConfirms', dismissedConfirms.join(','), COOKIE_PROPS);
  }
  onConfirm();
}

function confirmCancel(setDoNotShowAgain, onCancel) {
  // Reset state then call provided cancel function
  setDoNotShowAgain(false);
  onCancel();
}

function Confirm({ open, content, cancelButton, confirmButton, onCancel, onConfirm, doNotShowAgainId, doNotShowAgainText, focusLockDelay, isLoading }) {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  return (
    <Modal
      open={open}
      focusLockDelay={focusLockDelay}
      toggle={() => confirmCancel(setDoNotShowAgain, onCancel)}
      size="small"
      paddingBottom="pb-5"
      position="center"
      omitCloseX
      ariaLabel="Confirmation Modal"
    >
      <div style={{ borderRadius: '2px' }}>
        <div style={{ padding: '1.5rem', lineHeight: '1.5rem' }}>
          {content}
          {doNotShowAgainId && (
            <div style={{ marginTop: '2rem' }}>
              <Checkbox
                style={{ fontSize: '0.875rem' }}
                checked={doNotShowAgain}
                onChange={(e, data) => setDoNotShowAgain(data.checked)}
                label={doNotShowAgainText || 'Do not show me this again'}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row justify-end p-8 pb-4 text-right border-t">
          <button onClick={() => confirmCancel(setDoNotShowAgain, onCancel)} className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">
            {cancelButton || 'Never Mind'}
          </button>
          <button
            onClick={() => confirmContinue(onConfirm, doNotShowAgainId, doNotShowAgain)}
            className="py-2.5 px-6 ml-4 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
          >
            <If condition={isLoading}>
              <Loading wrapperClassName="py-0" className="w-4 h-4 border-2" color="white" />
            </If>
            <If condition={!isLoading}>{confirmButton || "Let's do it"}</If>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Confirm;
