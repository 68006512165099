import { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useTimer } from 'react-timer-hook';

const GlobalTimer = inject('commonStore')(
  observer(({ commonStore, expires, onEnd, ttl = 60 }) => {
    const handleExpire = () => {
      if (onEnd) {
        onEnd();
      }
    };

    const { isRunning, minutes, hours } = useTimer({ expiryTimestamp: new Date(expires), autoStart: true, onExpire: handleExpire });

    useEffect(() => {
      if (!isRunning) {
        return;
      }
      const halfway = ttl ? Math.round(ttl / 2) : 30;
      // When we hit one of our minutes values, show a notification
      const showOn = [halfway, 15, 10, 5, 1];
      const actualMinutes = 60 * hours + minutes;
      if (showOn.includes(actualMinutes)) {
        const toastMessage = `${actualMinutes} minute${minutes === 1 ? '' : 's'} remaining`;
        commonStore.triggerToast('success', { content: toastMessage, header: 'Lab Time Remaining', icon: 'clock', color: 'green' });
      }
    }, [minutes, hours]);

    return null;
  })
);

export default GlobalTimer;
