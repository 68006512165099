import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import CountUp from 'react-countup';
import moment from 'moment';
import Agents from '../../agents/agents';
import Loading from '../../components/Loading/Loading';
import DEMO_ORG_STATS_DATA from './Demo/DEMO_ORG_STATS_DATA.json';
import Container from '../../components/Container/Container';
import StyledError from '../../components/Error/StyledError';

function StatCardBox({ stat, label, loading }) {
  return (
    <div className="flex flex-col justify-center items-center p-6 text-center bg-white border border-gray-400">
      <p className="text-lg font-bold text-blue-900 sm:text-xl md:text-3xl">{loading ? <Loading /> : <CountUp separator="," end={1 * (stat || 0)} duration={3} />}</p>
      <p className="text-lg font-semibold sm:text-xl md:text-2xl">{label}</p>
    </div>
  );
}

function StatCards({ filters, orgId, groupId, isDemo = false, memberId = null }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [learningHours, setLearningHours] = useState(null);
  const [xpEarned, setXpEarned] = useState(null);
  const [badgesEarned, setBadgesEarned] = useState(null);
  const [goalsCompleted, setGoalsCompleted] = useState(null);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    // Build our query string, which will be used when making the request
    const queryParams = filters
      ? {
          startDate: moment(filters.startDate).format('YYYY-MM-DD 00:00:00'),
          endDate: moment(filters.endDate).format('YYYY-MM-DD 23:59:59'),
        }
      : {};

    if (memberId) {
      queryParams.userId = memberId;
    }
    setQuery(`?${queryString.stringify(queryParams)}`);
  }, [filters, memberId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setLearningHours(null);
      setXpEarned(null);
      setBadgesEarned(null);
      setGoalsCompleted(null);
      try {
        let response;
        if (isDemo) {
          response = DEMO_ORG_STATS_DATA;
        } else {
          response = await Agents.reports.getReportData(groupId || orgId, 'team-overview', query);
        }
        setLearningHours(response?.[0]?.learning_hours);
        setXpEarned(response?.[0]?.xp_earned);
        setBadgesEarned(response?.[0]?.badges_earned);
        setGoalsCompleted(response?.[0]?.goals_completed);
      } catch (err) {
        Bugsnag.notify(err);
        setError(err);
      }
      setLoading(false);
    };
    // If we have an empty query string, just return
    if (!query) {
      return;
    }

    fetchData();
  }, [query, orgId, groupId, isDemo]);

  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4 place-items-stretch md:grid-cols-4 md:gap-6">
      <StatCardBox stat={learningHours} label="Learning Hours Completed" loading={loading} />
      <StatCardBox stat={xpEarned} label="XP Earned" loading={loading} />
      <StatCardBox stat={badgesEarned} label="Badges Earned" loading={loading} />
      <StatCardBox stat={goalsCompleted} label="Goals Completed" loading={loading} />
    </div>
  );
}

function OrganizationStatCards({ filters, orgId, groupId, isDemo = false, memberId = null }) {
  return (
    <div className="mb-8">
      <div className="mb-4">
        <h3 className="mb-3 text-lg font-bold">Overview</h3>
        <p className="mb-3">How is my team performing on key metrics?</p>
        <StatCards filters={filters} groupId={groupId} orgId={orgId} isDemo={isDemo} memberId={memberId} />
      </div>
    </div>
  );
}

export default OrganizationStatCards;
