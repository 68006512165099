import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import Title from '../Title/Title';
import Loading from '../Loading/Loading';
import Dropdown from '../Dropdown/Dropdown';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import { getRoleOptions } from '../../utils/teamsUtil';
import AddLink from '../AddLink/AddLink';
import OrganizationGroupCards from './OrganizationGroupCards';
import Checkbox from '../FormFields/Checkbox';
import Label from '../Label/Label';
import Tooltip from '../Tooltip/Tooltip';
import Container from '../Container/Container';
import { HELP_DESK_LINKS } from '../../constants';

export default observer(
  class MemberDashboardHeading extends React.Component {
    state = {
      selectedRole: null,
    };

    getLicenseTag = (license) => {
      return (
        <Tooltip content={license ? 'Content License Granted!' : 'No Content License'}>
          <Icon name="license" className={`${license ? 'text-green-600' : 'text-gray-600'} w-8 h-8 stroke-2 inline-block mr-2`} />
        </Tooltip>
      );
    };

    getRoleTag = (role) => {
      const roleMeta = FormatUtil.getTeamRoleMeta(role);
      roleMeta.tooltip += '. Click to learn more about user roles.';

      return (
        <Tooltip omitTabIndex html={roleMeta.tooltip}>
          <AddLink to={HELP_DESK_LINKS.ROLES_PERMS_AND_USER_ROLES} target="_blank">
            <Label className="p-1 text-xs rounded" color={roleMeta.color}>
              {FormatUtil.convertRoleName(role)}
            </Label>
          </AddLink>
        </Tooltip>
      );
    };

    changeRoleConfirm = ({ value }) => {
      if (value !== this.props.data.data.role) {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to change user roles for this team?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.props.changeUserRole(value);
          },
        });
      }
    };

    addGroupConfirm = ({ value }) => {
      this.props.commonStore.triggerConfirm({
        content: 'Are you sure you want to add user to selected group?',
        cancel: () => this.props.commonStore.resetConfirmState(),
        continue: () => {
          this.props.commonStore.resetConfirmState();
          this.props.addUserToGroup(value);
        },
      });
    };

    showRemoveConfirm = () => {
      this.props.commonStore.triggerConfirm({
        content: 'Are you sure you want to remove this user from the team? They will lose access to paid content and will not appear in reporting.',
        cancel: () => this.props.commonStore.resetConfirmState(),
        continue: () => {
          this.props.commonStore.resetConfirmState();
          this.props.removeUserFromTeam();
        },
      });
    };

    toggleUserLicenseConfirm = (e, { checked }) => {
      this.props.commonStore.triggerConfirm({
        content: 'Are you sure you want to change the license status for this user?',
        cancel: () => this.props.commonStore.resetConfirmState(),
        continue: () => {
          this.props.commonStore.resetConfirmState();
          this.props.changeLicense(checked);
        },
      });
    };

    getGroupDropdown = (groupList) => {
      if (groupList.loading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (groupList.error) {
        return (
          <Container>
            <StyledError error={groupList.error} />
          </Container>
        );
      }
      return (
        <Dropdown
          placeholder="Add to Group"
          isSearchable
          classes="text-left"
          selectOnBlur={false}
          options={groupList?.data?.data?.slice()}
          value=""
          onChange={this.addGroupConfirm}
        />
      );
    };

    getAdminFunctions = (groupList) => {
      const userLicense = this.props.data.data.license_status ? <span className="green">Granted</span> : 'None';
      return (
        <div className="print:hidden mt-36 max-w-lg admin-options">
          {this.props.canManageAdmins ? (
            <div>
              <div className="flex mt-2 md:justify-end md:mt-0">
                <Checkbox toggle onChange={this.toggleUserLicenseConfirm} checked={this.props.data.data.license_status} ariaLabelledBy="license-toggle" />
                <span id="license-toggle" className="license-toggle">
                  User License:{userLicense}
                </span>
              </div>
              <Dropdown
                placeholder="Change Role"
                options={getRoleOptions(false)}
                value={this.state.selectedRole}
                onChange={this.changeRoleConfirm}
                classes="change-role-dropdown text-left"
              />
            </div>
          ) : null}
          {this.props.canManageTeam ? this.getGroupDropdown(groupList) : null}
          {this.props.canManageAdmins ? (
            <button
              className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
              onClick={() => this.showRemoveConfirm()}
            >
              Remove User
            </button>
          ) : null}
        </div>
      );
    };

    render() {
      const { loading, error, data } = this.props.data;
      if (loading) {
        return (
          <Container>
            <Loading message="Loading..." wrapperClassName="py-8" />
          </Container>
        );
      }
      if (error) {
        return (
          <Container>
            <StyledError error={error} />
          </Container>
        );
      }
      const memberName = data.real_name ? data.real_name : data.name;
      return (
        <>
          <div className="pt-4 pb-12 border-b border-gray-400 header-container">
            <div className="md:grid md:grid-cols-12">
              <div className="md:col-span-9">
                <div className="grid gap-y-4 md:gap-y-0 header-content">
                  <div className="col-span-12 pt-16 pb-6 name-container">
                    <Title title={memberName} omitPadding breadcrumbs={this.props.breadcrumbs} />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="header-info">
                      {data.job_title ? <p className="text-gray-600">{data.job_title}</p> : null}
                      {data.email ? <p className="text-gray-600">{data.email}</p> : null}
                      {this.getLicenseTag(data.license_status)}
                      {this.getRoleTag(data.role)}
                      {data.member_status ? (
                        <p className="text-gray-600">
                          <span className="font-semibold text-black">Status:</span> {data.member_status}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    {data.last_login ? (
                      <p className="text-gray-600">
                        <span className="font-semibold text-black">Last Login:</span> {moment(data.last_login).format('M/D/YYYY')}
                      </p>
                    ) : null}
                    {data.created_at ? (
                      <p className="text-gray-600">
                        <span className="font-semibold text-black">Account Creation:</span> {FormatUtil.convertUtcToLocal(data.created_at).format('M/D/YYYY')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="md:col-span-3">{this.props.canManageTeam ? this.getAdminFunctions(this.props.groupList) : null}</div>
            </div>
          </div>
          <OrganizationGroupCards groups={data.groups} orgId={this.props.orgId} groupPermissions={this.props.groupPermissions} />
        </>
      );
    }
  }
);
