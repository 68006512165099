import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import withRouter from '../../components/Router/withRouter';
import AdminTable from '../../components/AdminTable/AdminTable';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';
import AddLink from '../../components/AddLink/AddLink';
import Segment from '../../components/Segment/Segment';
import FormatUtil from '../../utils/formatUtil';
import Input from '../../components/FormFields/Input';
import Label from '../../components/Label/Label';
import ChargebeeLabel from '../../components/Label/ChargebeeLabel';
import Container from '../../components/Container/Container';
import './admin.css';
import CybraryAdminLogo from './CybraryAdminLogo';

const CybraryAdminBrowseTeams = inject(
  'commonStore',
  'adminStore',
  'authStore',
  'userStore'
)(
  observer(
    class CybraryAdminBrowseTeams extends Component {
      componentDidMount() {
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);
        this.props.commonStore.hidePrimaryNav();
        this.props.adminStore.getAdminTeamTableData();
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultAdminTeamTableData();
        this.props.commonStore.showPrimaryNav();
      }

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push({
          href: '/admin',
          label: 'Admin',
        });
        crumbs.push({
          href: '/admin/teams',
          label: 'Browse Teams',
        });
        return crumbs;
      };

      // /**
      //  * Sets sort column and direction vars in store for service call query params
      //  * @param heading
      //  */
      tableSort = (heading) => {
        if (heading) {
          if (heading === this.props.adminStore.adminTeamTableData.queryParams.sortCol) {
            this.props.adminStore.adminTeamTableData.queryParams.sortDirection = this.props.adminStore.adminTeamTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            this.props.adminStore.adminTeamTableData.queryParams.sortCol = heading;
            this.props.adminStore.adminTeamTableData.queryParams.sortDirection = 'desc';
          }
          this.props.adminStore.adminTeamTableData.queryParams.activePg = 1;
          this.props.adminStore.getAdminTeamTableData();
        }
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  * @param pg
      //  */
      pagChangePg = (pg) => {
        this.props.adminStore.adminTeamTableData.queryParams.activePg = pg;
        this.props.adminStore.getAdminTeamTableData();
      };

      // /**
      //  * Sets search value in store for service call searchQuery
      //  * @param data
      //  */
      setSearch = (event, data) => {
        this.props.adminStore.adminTeamTableData.queryParams.searchQuery = data.value;
      };

      sendSearch = () => {
        // Reset some query params before getting data again
        this.props.adminStore.adminTeamTableData.queryParams.activePg = 1;
        this.props.adminStore.adminTeamTableData.queryParams.sortCol = 'name';
        this.props.adminStore.adminTeamTableData.queryParams.sortDirection = 'desc';
        this.props.adminStore.getAdminTeamTableData();
      };

      startTeamLarping = (data, headings) => {
        const orgIdIdx = FormatUtil.getColIndex(headings, 'id');
        const orgId = data[orgIdIdx].value;
        this.props.adminStore.startLarping(this.props.userStore, orgId, this.props.navigate);
      };

      redirectTo = (destination, data, headings) => {
        const orgIdIdx = FormatUtil.getColIndex(headings, 'id');
        const orgId = data[orgIdIdx].value;
        if (destination === 'auditLog') {
          return this.props.navigate(`/admin/view-team/${orgId}/logs`);
        }
        return this.props.navigate(`/admin/view-team/${orgId}`);
      };

      formatLicenses = (row, headings) => {
        const teamIdIdx = FormatUtil.getColIndex(headings, 'id');
        const teamId = row[teamIdIdx].value;
        const openSeatsIdx = FormatUtil.getColIndex(headings, 'open_seats');
        const openSeats = row[openSeatsIdx].value;
        const maxSeatsIdx = FormatUtil.getColIndex(headings, 'max_seats');
        const maxSeats = row[maxSeatsIdx].value;
        if (maxSeats) {
          return (
            <Table.Cell key={teamId}>
              {maxSeats - openSeats} used / {openSeats} available
            </Table.Cell>
          );
        }
        return <Table.Cell key={teamId} />;
      };

      formatTeamName = (data, row, headings) => {
        const teamName = data.value;
        const teamType = row[FormatUtil.getColIndex(headings, 'type')].value;
        const teamStatus = row[FormatUtil.getColIndex(headings, 'status')].value;
        const teamId = row[FormatUtil.getColIndex(headings, 'id')].value;
        const licenseSourceIndex = FormatUtil.getColIndex(headings, 'license_source');
        const licenseSource = row[licenseSourceIndex] ? row[licenseSourceIndex].value : null;
        const labelClasses = 'p-1 ml-1 rounded-sm text-xs';
        return (
          <>
            <AddLink style={{ marginRight: '0.75rem' }} to={`/admin/view-team/${teamId}`}>
              {teamName}
            </AddLink>
            <Label className={labelClasses} basic color={teamStatus === 'Active' ? 'green' : 'red'}>
              {teamStatus}
            </Label>
            {teamType ? (
              <Label className={labelClasses} basic>
                {teamType}
              </Label>
            ) : null}
            {licenseSource === 'chargebee' && <ChargebeeLabel basic className="ml-1" />}
          </>
        );
      };

      render() {
        const { loading } = this.props.adminStore;
        const { denyAccess } = this.props.adminStore;
        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (loading) {
          return (
            <Container>
              <CybraryAdminLogo />
              <Loading message="Loading..." />
            </Container>
          );
        }
        return (
          <Container fluid className="admin">
            <CybraryAdminLogo />
            <Segment className="border-none">
              <Breadcrumbs crumbs={this.getBreadCrumbs()} />
              <Header as="h1">Browse Teams</Header>
              <div className="grid grid-cols-12 admin-table-grid">
                <div className="col-span-4">
                  <AddLink
                    to="/admin/add-team"
                    className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
                  >
                    <Icon name="plus-circle" className="inline-block mr-2 mb-1 w-4 h-4" />
                    Add Team
                  </AddLink>
                </div>
                <div className="col-span-4">
                  <Icon name="user-group" className="inline-block mr-2 w-6 h-6" />
                  Total Teams: {this.props.adminStore.adminTeamTableData.recordsCount}
                </div>
                <div className="col-span-4 text-right">
                  <form className="relative" onSubmit={(e) => e.preventDefault()}>
                    <Input
                      placeholder="Search"
                      onChange={this.setSearch}
                      className="py-2 px-4 w-full max-w-lg text-sm text-black rounded-sm border border-gray-400"
                      value={this.props.adminStore.adminTeamTableData?.queryParams?.searchQuery}
                    />
                    <button onClick={this.sendSearch} className="absolute top-3 right-2">
                      <Icon name="search" className="w-4 h-4" />
                    </button>
                  </form>
                </div>
              </div>
              <AdminTable
                /* Table Data */
                headings={this.props.adminStore.adminTeamTableData.tableHeadings}
                data={this.props.adminStore.adminTeamTableData.tableData}
                tableLoading={this.props.adminStore.adminTeamTableData.loading}
                tableError={this.props.adminStore.adminTeamTableData.error}
                displayCheckBox={false}
                headerClickFunc={this.tableSort}
                sortCol={this.props.adminStore.adminTeamTableData.queryParams.sortCol}
                sortDirection={this.props.adminStore.adminTeamTableData.queryParams.sortDirection}
                /* Pagination options */
                pagNumPgs={this.props.adminStore.adminTeamTableData.pagPagesCount}
                pagChangePg={this.pagChangePg}
                pagActivePg={this.props.adminStore.adminTeamTableData.queryParams.activePg}
                addCustomCol={[
                  {
                    method: this.formatLicenses,
                    position: 7,
                    header: 'Licenses',
                  },
                ]}
                formatColumns={[
                  {
                    method: this.formatTeamName,
                    colIdx: FormatUtil.getColIndex(this.props.adminStore.adminTeamTableData.tableHeadings, 'name'),
                  },
                ]}
                ignoredCols={[
                  FormatUtil.getColIndex(this.props.adminStore.adminTeamTableData.tableHeadings, 'max_seats'),
                  FormatUtil.getColIndex(this.props.adminStore.adminTeamTableData.tableHeadings, 'open_seats'),
                  FormatUtil.getColIndex(this.props.adminStore.adminTeamTableData.tableHeadings, 'type'),
                  FormatUtil.getColIndex(this.props.adminStore.adminTeamTableData.tableHeadings, 'status'),
                ]}
                headerMappings={{ 'End Date': 'Contract End Date' }}
                rowOptions={[
                  {
                    text: 'Larp as Owner',
                    icon: 'paper plane outline',
                    action: this.startTeamLarping,
                  },
                  {
                    text: 'View Team',
                    action: (data, headings) => this.redirectTo('viewTeam', data, headings),
                  },
                  {
                    text: 'View Change Log',
                    icon: 'clipboard',
                    action: (data, headings) => this.redirectTo('auditLog', data, headings),
                  },
                ]}
              />
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(CybraryAdminBrowseTeams);
