import React from 'react';

const CHECKOUT_ANNUAL_SAVINGS_PROMO = {
  // Enable or disable the promo
  isEnabled: true,

  // Show the promo on specific pages. Uses regex matching on the current pathname
  isShownOnPages: ['/upgrade/checkout', 'upgrade/checkout/monthly'],

  // Show the promo based on the user's role/state
  isShownTeamLearner: false,
  isShownTeamAdmin: false,
  isShownTeamOwner: false,
  isShownCipFree: true,
  isShownCipMonthly: true,
  isShownCipAnnual: false,
  isShownCipBiennial: false,
  isShownCipEduGovMilFree: false,
  isShownCipEduGovMilMonthly: false,
  isShownCipEduGovMilAnnual: false,
  isShownCipEduGovMilBiennial: false,

  // Set Snowplow tracking labels
  spLabel: 'upgradeAnnualSubscriptionSavingsPromo',
  spCategory: 'CIPUpgradeBanner',

  // Configure the attributes of the banner
  title: '',
  body: <b>Save $120/year when you subscribe Annually!</b>,
  ctaButtonText: '',
  iconName: 'cost-saving',
  iconClassName: 'w-10 h-10',
  bannerClassName: 'from-cyb-purple-500 to-cyb-blue-500 min-h-0',
  ctaButtonClassName: 'hidden',
};

export default CHECKOUT_ANNUAL_SAVINGS_PROMO;
