import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import Card from '../../components/Card/Card';
import Agents from '../../agents/agents';
import TimeUtil from '../../utils/timeUtil';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import LineChart from '../../components/Charts/LineChart';
import DEMO_ORG_RISK_DATA from './Demo/DEMO_ORG_RISK_DATA.json';
import DEMO_ORG_RISK_30_DAYS_DATA from './Demo/DEMO_ORG_RISK_30_DAYS_DATA.json';
import LoadingText from '../../components/Loading/LoadingText';
import If from '../../components/If/If';
import LockedContainer from '../../components/Container/LockedContainer';

function getTextColorFromExposure(exposure, midline) {
  return exposure < midline ? 'text-green-400' : 'text-red-500';
}

/**
 * The logic used to process the response data and determine yMax, line markers, and the formatted graph data.
 * @param {json} response - The initial data response provided by the server, JSON formatted array.
 * @param {string|null} query - The query sent to the server, can be empty
 * @returns {object} - Object containing yMax, lineMarkers, and formatted graphData array.
 */
function buildGraphData(response, query) {
  // Add our data from each response to newData
  let yMax = 'auto';
  let lineMarkers = null;
  const graphData = [];
  if (response?.length) {
    const queryParams = query ? queryString.parse(query) : {};
    // Only show the day in our label if we are zoomed into to an hour or day time interval
    const labelDateFormat = ['hour', 'day', 'week'].includes(queryParams.interval) ? 'MMM D, YYYY' : 'MMM YYYY';
    response.forEach((d, i) => {
      if (i === 0) {
        // First time through, set the line markers
        lineMarkers = [
          {
            axis: 'y',
            legend: `Regress: ${parseFloat(d.max_regression_hours)} hrs/mo (+${parseFloat(d.max_regression_risk_diff)}% risk impact)`,
            legendPosition: 'top-left',
            legendOrientation: 'horizontal',
            legendOffsetY: 12,
            legendOffsetX: 12,
            value: parseFloat(d.max_regression_hours),
            lineStyle: {
              stroke: '#e05449',
              strokeWidth: 1,
            },
          },
          {
            axis: 'y',
            legend: `Maintenance: ${parseFloat(d.maintenance_hours)} hrs/mo (+0% risk impact)`,
            legendPosition: 'top-left',
            legendOrientation: 'horizontal',
            legendOffsetY: 12,
            legendOffsetX: 12,
            value: parseFloat(d.maintenance_hours),
            lineStyle: {
              stroke: '#000000',
              strokeWidth: 1,
            },
          },
          {
            axis: 'y',
            legend: `Improve: ${parseFloat(d.max_improvement_hours)} hrs/mo (${parseFloat(d.max_improvement_risk_diff)}% risk impact)`,
            legendPosition: 'top-left',
            legendOrientation: 'horizontal',
            legendOffsetY: 12,
            legendOffsetX: 12,
            value: parseFloat(d.max_improvement_hours),
            lineStyle: {
              stroke: '#6fda80',
              strokeWidth: 1,
            },
          },
        ];
        // Get our new y maximum value, which should at LEAST be the top risk improvement line
        yMax = parseFloat(d.max_improvement_hours);
      }
      const { avg_monthly_hrs: avgMonthly, adj_exposure: adjustedExposure, adj_risk_per: adjustedRisk, default_exposure: midline, period } = d;
      const formattedPeriod = moment(period).format(labelDateFormat);
      const newGraphData = {
        x: formattedPeriod,
        y: parseFloat(avgMonthly),
        risk: parseFloat(adjustedRisk),
        exposure: parseFloat(adjustedExposure),
        midline: parseFloat(midline),
      };
      // If we ever cross the top of the y max value, we can let the graph handle the max by setting it to auto
      if (newGraphData.y >= yMax) {
        yMax = 'auto';
      }
      graphData.push(newGraphData);
    });
  }
  const data = [{ id: 'risk', data: graphData }];
  return { yMax, lineMarkers, data };
}

function RiskTopPortion({ orgId, isDemo = false }) {
  const [loading, setLoading] = useState(true);
  const [exposureRisk, setExposureRisk] = useState(0);
  const [breachExposure, setBreachExposure] = useState(0);
  const [businessValue, setBusinessValue] = useState(0);
  const [breachLikelihood, setBreachLikelihood] = useState(0);
  const [avgHours, setAvgHours] = useState(0);
  const [adjustedExposure, setAdjustedExposure] = useState(0);
  const [adjustedRisk, setAdjustedRisk] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let response;
        if (isDemo) {
          response = DEMO_ORG_RISK_30_DAYS_DATA;
        } else {
          // Fetch the last thirty days worth of data, always
          response = await Agents.reports.getReportData(orgId, 'company-risk-interval', '?dateRange="last 30 days"');
        }
        if (response?.length) {
          const d = response[0];
          setExposureRisk(parseFloat(d.value_at_risk));
          setBusinessValue(parseFloat(d.business_value));
          setBreachLikelihood(parseFloat(d.breach_likelihood));
          setBreachExposure(parseFloat(d.default_exposure));
          setAvgHours(parseFloat(d.avg_monthly_hrs));
          setAdjustedExposure(parseFloat(d.adj_exposure));
          setAdjustedRisk(parseFloat(d.adj_risk_per));
        }
      } catch (err) {
        // 422 just indicates a missing config - that message to the user is handled in the graph component
        if (err?.response?.status !== 422) {
          Bugsnag.notify(err);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [orgId, isDemo]);

  if (loading) {
    return (
      <div className="px-4">
        <LoadingText wrapperClassName="h-4" className="w-full h-full rounded" />
        <LoadingText wrapperClassName="h-4" className="w-full h-full rounded" />
        <LoadingText wrapperClassName="h-4" className="w-full h-full rounded" />
        <LoadingText wrapperClassName="h-4" className="w-full h-full rounded" />
      </div>
    );
  }

  const color = getTextColorFromExposure(adjustedExposure, breachExposure);

  const exposureDelta = adjustedExposure - breachExposure;

  return (
    <div className="flex align-top">
      <p className="px-4 pr-10 mb-3 w-3/5">
        How has my team&apos;s activity on Cybrary reduced my cyber risk? Contact your CSM to customize your impact report. Results based on company reported{` `}
        <span className="font-bold">${businessValue.toLocaleString()}</span> business value, <span className="font-bold">{exposureRisk}%</span> cyber exposure risk,{' '}
        <span className="font-bold">{breachLikelihood}%</span> breach likelihood, and a calculated <span className="font-bold">${breachExposure.toLocaleString()}</span> breach
        exposure.
      </p>
      <div className="flex-1 pr-4 pl-20">
        <p className="mb-0 font-bold">Team Average: {avgHours.toFixed(1)} hours / month</p>
        <p className="mb-0 text-3xl font-bold">
          <span className={color}>${exposureDelta.toLocaleString()}</span>{' '}
          <span className="font-normal">
            ({adjustedRisk > 0 ? '+' : ''}
            {adjustedRisk.toFixed(1)}%)
          </span>
        </p>
        <p className="mb-0 font-bold">Risk Impact</p>
      </div>
    </div>
  );
}

function RiskTooltip(props) {
  const {
    point: { id, data },
  } = props;

  if (data.marker) {
    return null;
  }

  const { y: hours, exposure, midline, risk } = data;

  const color = getTextColorFromExposure(exposure, midline);
  const exposureDelta = exposure - midline;

  return (
    <div
      key={id}
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '10px 10px',
      }}
    >
      <p className="mb-1 text-base text-center">
        Adjusted risk exposure
        <br />
        at {hours.toFixed(2)} hrs/mo.
      </p>
      <p className="text-2xl font-semibold text-center">
        <span className={color}>${exposureDelta.toLocaleString() || 0}</span>{' '}
        <span className="font-normal">
          ({risk > 0 ? '+' : ''}
          {risk.toFixed(1)}%)
        </span>
      </p>
    </div>
  );
}

// Provide a blurred out preview view when we don't have anything set on the backend, or we don't have enough data.
function BlurPreview({ axisLeftFormatter, message, id, hideTop = false }) {
  const { yMax, data, lineMarkers } = buildGraphData(DEMO_ORG_RISK_DATA, null);
  return (
    <LockedContainer isFullWidth id={id} msg={message} isLocked showLock={false}>
      <div>
        <If condition={!hideTop}>
          <RiskTopPortion orgId={null} isDemo />
        </If>
        <div className="relative">
          <LineChart data={data} tooltip={RiskTooltip} axisLeftFormatter={axisLeftFormatter} markers={lineMarkers} yMax={yMax} />
        </div>
      </div>
    </LockedContainer>
  );
}

function RiskImpactGraph({ interval, orgId, groupId, filters, isDemo = false }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [missingConfig, setMissingConfig] = useState(false);
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);
  const [lineMarkers, setLineMarkers] = useState(null);
  const [yMax, setYMax] = useState('auto');

  useEffect(() => {
    // Build our query string, which will be used when making the request
    const queryParams = filters
      ? {
          startDate: moment(filters.startDate).format('YYYY-MM-DD 00:00:00'),
          endDate: moment(filters.endDate).format('YYYY-MM-DD 23:59:59'),
        }
      : {};
    if (interval) {
      queryParams.interval = interval === 'time' ? TimeUtil.getDateRangeInterval(filters.startDate, filters.endDate) : interval;
    }
    setQuery(`?${queryString.stringify(queryParams)}`);
  }, [filters, interval]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        let response;
        if (isDemo) {
          response = DEMO_ORG_RISK_DATA;
        } else {
          response = await Agents.reports.getReportData(groupId || orgId, 'company-risk-interval', query);
        }
        // Process our response to get our graph data
        const processedData = buildGraphData(response, query);
        setLineMarkers(processedData.lineMarkers);
        setYMax(processedData.yMax);
        setData(processedData.data);
      } catch (err) {
        if (err?.response?.status === 422) {
          setMissingConfig(true);
          return;
        }
        Bugsnag.notify(err);
        setError(err);
      }
      setLoading(false);
    };
    // If we have an empty query string, just return
    if (!query) {
      return;
    }

    fetchData();
  }, [query, orgId, groupId, isDemo]);

  const axisLeftFormatter = (value) => `${value} hrs`;

  // If we have no config set, this variable will be true, in which case we show the missing config message
  if (missingConfig) {
    return (
      <BlurPreview
        axisLeftFormatter={axisLeftFormatter}
        id="missing-config"
        message="Contact your Customer Success Manager to customize this widget and better manage your company's cyber risk!"
      />
    );
  }

  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  if (loading || !data) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  // If we have no data, or no graph data, or not at least 2 pieces of graph data with values above 0, display the not enough data message
  const hasInsufficientData = !data?.length || !data[0]?.data?.length || data[0].data.filter(({ y }) => y > 0).length < 2;

  return (
    <div>
      <RiskTopPortion orgId={orgId} isDemo={isDemo} />
      <div className="relative">
        <If condition={hasInsufficientData}>
          <BlurPreview hideTop axisLeftFormatter={axisLeftFormatter} id="not-enough-data" message="Insufficient data to show trending." />
        </If>
        <If condition={!hasInsufficientData && !!data?.length}>
          <LineChart data={data} tooltip={RiskTooltip} axisLeftFormatter={axisLeftFormatter} markers={lineMarkers} yMax={yMax} />
        </If>
      </div>
    </div>
  );
}

function RiskImpact({ filters, orgId, groupId, isDemo = false }) {
  return (
    <div className="mb-8">
      <div className="mb-4 learning-hours">
        <Card className="z-50 p-0 my-0">
          <div className="flex z-50">
            <div className="flex-1 px-4 pt-4">
              <h3 className="mb-3 text-lg font-bold">Cyber Risk Impact</h3>
            </div>
          </div>
          <RiskImpactGraph filters={filters} interval="time" orgId={orgId} groupId={groupId} isDemo={isDemo} />
        </Card>
      </div>
    </div>
  );
}

export default RiskImpact;
