import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Userpilot } from 'userpilot';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import AddLink from '../../components/AddLink/AddLink';
import OrganizationDashboardHeading from '../../components/Enterprise/OrganizationDashboardHeading';
import DateRangeFilter from '../../components/Enterprise/DateRangeFilter';
import Leaderboard from '../../components/Analytics/Leaderboard/Leaderboard';
import FeaturesSummary from '../../components/Analytics/FeaturesSummary';
import GroupSelect from '../../components/Enterprise/GroupSelect';
import FormatUtil from '../../utils/formatUtil';
import Container from '../../components/Container/Container';
import Sticky from '../../components/Sticky/Sticky';
import If from '../../components/If/If';
import PrintToPdfButton from '../../components/PrintToPdfButton/PrintToPdfButton';
import './enterprise.css';
import '../../components/Analytics/analytics.css';
import { USERPILOT_EVENTS } from '../../constants';
import OrganizationTrainingProgress from './OrganizationTrainingProgress';
import RiskImpact from './RiskImpact';
import OrganizationStatCards from './OrganizationStatCards';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import ActionUtil from '../../utils/actionsUtil';

const formatScore = (col) => {
  return col.value ? `${col.value}%` : '-';
};

const formatNumber = (col) => {
  return col.value ? FormatUtil.formatNumbers(col.value) : 0;
};

const formatScoreFloat = (col) => {
  const parsedValue = col.value ? Math.round(parseFloat(col.value * 100)) : null;
  return parsedValue ? `${parsedValue}%` : '-';
};

const OrganizationDashboardLeaderboards = inject('enterpriseStore')(
  observer(({ enterpriseStore, groupId }) => {
    const { orgDashboardData } = enterpriseStore;
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';

    const addLeaderboardLink = (col, row, headings, source) => {
      let href = null;
      switch (source) {
        case 'member':
          href = `/enterprise/${orgId}/organization/member/${row[FormatUtil.getColIndex(headings, 'id')].value}/dashboard/`;
          break;
        case 'content':
          href = `/browse/${row[FormatUtil.getColIndex(headings, 'content_description_permalink')].value}`;
          break;
        default:
          href = null;
      }
      if (isDemo) {
        href = null;
      }
      return (
        <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={href} key={col.value}>
          {col.value}
        </AddLink>
      );
    };

    const leaderboardFilterChange = (leaderboardName, value, callback) => {
      enterpriseStore.setDashboardLeaderboardFilter('orgDashboardData', leaderboardName, value);
      enterpriseStore.getDashboardLeaderboards('orgDashboardData', groupId || orgId, leaderboardName, null, callback);
    };

    const leaderboardSorterValueChange = (leaderboardName, value, queryParam, callback) => {
      enterpriseStore.setDashboardLeaderboardQueryParam('orgDashboardData', leaderboardName, queryParam, value);
      enterpriseStore.getDashboardLeaderboards('orgDashboardData', groupId || orgId, leaderboardName, null, callback);
    };

    if (Object.keys(orgDashboardData.leaderboards).length === 0) {
      return null;
    }

    return orgDashboardData?.config?.leaderboards?.map((item) => {
      const leaderData = orgDashboardData?.leaderboards?.[item.key];
      if (!leaderData?.config) {
        return null;
      }
      const ignoredCols = leaderData.data?.columns ? [FormatUtil.getColIndex(leaderData.data.columns, 'groups')] : [];
      const formatColumns = leaderData.data?.columns
        ? [
            {
              method: addLeaderboardLink,
              colIdx: FormatUtil.getColIndex(leaderData.data, item.key === 'member' ? 'name' : 'content_description_title'),
            },
            {
              method: formatScore,
              colIdx: FormatUtil.getColIndex(leaderData.data, 'average_score'),
            },
            {
              method: formatScoreFloat,
              colIdx: FormatUtil.getColIndex(leaderData.data, 'average_assessment_score'),
            },
            {
              method: formatNumber,
              colIdx: FormatUtil.getColIndex(leaderData.data, 'learning_hours'),
            },
            {
              method: formatNumber,
              colIdx: FormatUtil.getColIndex(leaderData.data, 'enrollments'),
            },
            {
              method: formatNumber,
              colIdx: FormatUtil.getColIndex(leaderData.data, 'completed'),
            },
            {
              method: formatNumber,
              colIdx: FormatUtil.getColIndex(leaderData.data, 'total_xp'),
            },
          ]
        : [];

      return (
        <div className="mb-4" key={item.key}>
          <ErrorBoundary>
            <Leaderboard
              name={item.key}
              loading={leaderData.loading}
              error={leaderData.error}
              data={leaderData.data}
              heading={leaderData.config.heading}
              filterValue={leaderData.queryParams.sortCol}
              orgId={orgId}
              filterChange={leaderboardFilterChange}
              href={`/enterprise/${orgId}${leaderData.config.href}`}
              formatColumns={formatColumns}
              ignoredCols={ignoredCols}
              filters={leaderData.config.filters.slice()}
              headingTooltip={item.key === 'hands-on' ? true : null}
              sorters={leaderData.config.sorters?.slice()}
              sorterValueChange={leaderboardSorterValueChange}
            />
          </ErrorBoundary>
        </div>
      );
    });
  })
);

const OrganizationDashboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore',
  'adminStore'
)(
  observer(({ enterpriseStore, commonStore, userStore, authStore, adminStore }) => {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';

    const { orgDashboardData } = enterpriseStore;
    const team = userStore.team || {};
    let isGroupScopeOnly = team && team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all';
    const isMemberRole = team && team?.role === 'team-member';
    let groupId = isGroupScopeOnly ? team.reportsTeamGroupId : null;

    const queryParams = queryString.parse(window.location.search);
    if (queryParams.group) {
      isGroupScopeOnly = true;
      groupId = queryParams.group * 1;
    }

    const teamCreatedAt = team.data && team.data.parent_team ? team.data.parent_team.created_at : team.created_at;

    const getFilterableContent = () => {
      if (isDemo) {
        return;
      }
      const leaderboardConfig = enterpriseStore.orgDashboardData.config.leaderboards;

      leaderboardConfig.forEach((item) => {
        enterpriseStore.getDashboardLeaderboards('orgDashboardData', groupId || orgId, item.key, item);
      });
    };

    const getDashboardData = async () => {
      const freeTeam = !!team && (!team.package_types || !team.package_types.length);
      if (!freeTeam || !!adminStore.larping.teamId) {
        if (!isGroupScopeOnly) {
          getFilterableContent();
        } else {
          enterpriseStore.getGroupMeta('orgDashboardData', groupId || orgId);
          getFilterableContent();
        }
      }
    };

    const applyDateFilter = (startDate, endDate, value) => {
      if (startDate) {
        enterpriseStore.setDashboardDateFilter('orgDashboardData', 'startDate', startDate);
      }
      if (endDate) {
        enterpriseStore.setDashboardDateFilter('orgDashboardData', 'endDate', endDate);
      }
      enterpriseStore.setDashboardDateFilter('orgDashboardData', 'range', value || null);
      getFilterableContent();
    };

    const applyGroupFilter = (newGroupId) => {
      // set new groupId in query params so its picked up by getDashboardData
      queryParams.group = newGroupId;
      navigate({
        search: queryString.stringify(queryParams),
      });
    };

    useEffect(() => {
      if (!isDemo) {
        getDashboardData();
        userStore.setPreferredTeamInit('', orgId);
      }
      authStore.fireAttributionEvent();
      ActionUtil.scrollToTop();

      // Set preferred group ID in store if user has group scope permissions
      if (team && team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all') {
        userStore.setPreferredGroup(orgId, groupId);
      }
    }, [orgId, groupId, userStore.team, userStore.user.id]);

    useEffect(() => {
      commonStore.setPageTitle(`Team Dashboard${isDemo ? ' Demo' : ''} | Cybrary`);
      if (isDemo) {
        userStore.enterDemo();
        enterpriseStore.enterDemo();
        Userpilot.track(USERPILOT_EVENTS.VIEWED_DEMO_TEAM_DASHBOARD);
      } else {
        Userpilot.track(USERPILOT_EVENTS.VIEWED_TEAM_DASHBOARD);
      }
      return () => {
        enterpriseStore.setDefaultOrgDashboardData();
        if (isDemo) {
          userStore.exitDemo();
          enterpriseStore.exitDemo();
        }
      };
    }, []);

    if (!team) {
      return null;
    }

    if (!!team && (!team.package_types || !team.package_types.length) && !adminStore.larping.teamId) {
      return <FeaturesSummary featureType="dashboard" />;
    }

    const filters = orgDashboardData?.filters ? { ...orgDashboardData.filters } : null;
    const name = isGroupScopeOnly ? orgDashboardData.groupMeta.data?.name : team?.name;
    const allGroupsOption = {
      text: `All Groups`,
      value: null,
    };
    const defaultGroupOption = !isGroupScopeOnly ? allGroupsOption : null;

    return (
      <div className="organization">
        <div className="mx-auto w-full lg:max-w-[1600px] organization-dashboard dashboard">
          <OrganizationDashboardHeading
            loading={isGroupScopeOnly ? orgDashboardData.groupMeta.loading : false}
            error={isGroupScopeOnly ? orgDashboardData.groupMeta.error : false}
            data={isGroupScopeOnly ? orgDashboardData.groupMeta.data : team}
            groupScope={isGroupScopeOnly}
            canManageAdmins={team && team.permissions && team.permissions.canManageAdmins}
            team={team}
          />
          <ErrorBoundary>
            <div className="filterable-content">
              <Sticky stickyClasses="static lg:sticky lg:border-0 lg:border-gray-400 z-[51]">
                <Container className="z-[51]">
                  <div className="py-2 md:flex md:justify-end md:items-center filter-container">
                    <p className="mr-2 mb-0 text-sm font-bold lg:ml-0 lg:text-right">Filters:</p>
                    <span className="mr-4 w-56">
                      <GroupSelect
                        team={team}
                        handleGroupSelect={applyGroupFilter}
                        selectedGroup={groupId}
                        groupFilter={(group) => group.permissions.reports}
                        placeholder="All Groups"
                        defaultOption={defaultGroupOption}
                        isCompact
                      />
                    </span>
                    <If condition={team}>
                      <DateRangeFilter
                        dateChange={applyDateFilter}
                        dateRange={enterpriseStore.orgDashboardData.filters.range}
                        allTimeStart={teamCreatedAt}
                        showGroupCreationFilter={orgDashboardData && isGroupScopeOnly}
                        startDate={enterpriseStore.orgDashboardData.filters.startDate}
                        endDate={enterpriseStore.orgDashboardData.filters.endDate}
                        rangeSelectionClasses="w-full mb-6 md:mb-0 md:w-48 lg:w-52 md:mr-4"
                        disabled={isDemo}
                        isCompact
                      />
                    </If>
                    <If condition={name}>
                      <div className="mt-5 mb-1 ml-0 md:my-0 md:ml-5">
                        <PrintToPdfButton
                          color="gray"
                          container=".dashboard"
                          className="shrink"
                          margin={[10, 40, 10, 40]}
                          filename={`${FormatUtil.slugify(name)}_dashboard.pdf`}
                          html2canvas={{ scale: 1, dpi: 300 }}
                          jsPDF={{ unit: 'px', format: [1920, 2380], orientation: 'portrait' }}
                        />
                      </div>
                    </If>
                  </div>
                </Container>
              </Sticky>
              <Container>
                <If condition={!!filters && !isMemberRole}>
                  <OrganizationStatCards filters={filters} groupId={groupId} orgId={orgId} isDemo={isDemo} />
                  <OrganizationTrainingProgress filters={filters} groupId={groupId} orgId={orgId} isDemo={isDemo} />
                  <RiskImpact filters={filters} groupId={groupId} orgId={orgId} isDemo={isDemo} />
                </If>
                <OrganizationDashboardLeaderboards groupId={groupId} />
              </Container>
            </div>
          </ErrorBoundary>
        </div>
      </div>
    );
  })
);

export default OrganizationDashboard;
