import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import CybAssessmentProvider from '../../providers/CybAssessmentProvider';
import ClabProvider from '../../providers/ClabProvider';
import ImmersiveTasksProvider from '../../providers/ImmersiveTasksProvider';
import { useImmersive } from '../../providers/ImmersiveProvider';
import ImmersiveRenderer from '../../components/Immersive/ImmersiveRenderer';

/**
 * This page controls the immersive when we have an enrollment and an activity.
 * We load the enrollment, then the activity, and display the Immersive UI.
 * Example url: /immersive/:enrollmentId/activity/:activityId
 */
const ImmersiveEnrollmentWithActivity = inject(
  'userStore',
  'authStore',
  'commonStore',
  'goalsStore'
)(
  observer(({ userStore, commonStore, authStore, onCompletePage = false }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { enrollment, loadingEnrollment, loadingActivity, loadEnrollment, activity, loadActivity, outline, menu, reset } = useImmersive();

    const { enrollmentId, activityId } = params;

    useEffect(() => {
      // Remove the app's main navigation by letting the common store know we are in the immersive ux
      commonStore.setIsInImmersive(true);
      return () => {
        reset();
        commonStore.setIsInImmersive(false);
      };
    }, []);

    useEffect(() => {
      if (!enrollmentId) {
        return;
      }

      loadEnrollment(1 * enrollmentId);
    }, [enrollmentId]);

    useEffect(() => {
      if (!enrollment || !activityId || loadingActivity) {
        return;
      }

      loadActivity(1 * activityId);
    }, [activityId, enrollment, loadingActivity]);

    if (loadingActivity || loadingEnrollment || !outline || !menu || !activity) {
      return <Loading wrapperClassName="py-16" />;
    }

    const { clabVersion } = activity;

    return (
      <ClabProvider version={clabVersion}>
        <CybAssessmentProvider>
          <ImmersiveTasksProvider>
            <ImmersiveRenderer
              userStore={userStore}
              commonStore={commonStore}
              authStore={authStore}
              navigate={navigate}
              enrollment={enrollment}
              activity={activity}
              outline={outline}
              menu={menu}
              onCompletePage={onCompletePage}
              clabVersion={clabVersion}
            />
          </ImmersiveTasksProvider>
        </CybAssessmentProvider>
      </ClabProvider>
    );
  })
);

export default ImmersiveEnrollmentWithActivity;
