import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { twMerge } from 'tailwind-merge';

/**
 * Dumb component for order summary row
 * Used in OrderSummaryContainer to display rows
 * @param {string} title - Row title
 * @param {string} value - Row value
 * @param {string} className - Class to apply to row
 * @param {string} isDiscount - is discount row
 * @returns {React.ReactNode} - Order summary row
 */
function OrderSummaryRow({ title, value, className, isDiscount }) {
  return (
    <tr className={twMerge(className, 'w-full')}>
      <td className="py-2 px-4">
        <Fade duration={300} className={twMerge(isDiscount && 'text-green-500 font-semibold')}>
          {title}
        </Fade>
      </td>
      <td className="py-2 px-4 text-right">
        <Fade duration={300} className={twMerge(isDiscount && 'text-green-500 font-semibold')}>
          {value}
        </Fade>
      </td>
    </tr>
  );
}

export default OrderSummaryRow;
