import React from 'react';
import LessonContent from './LessonContent';
import SuccessBanner from './SuccessBanner';
import PreviousNextButtons from './PreviousNextButtons';
import CompleteView from './CompleteView';
import UpgradeInterstitial from '../../pages/ImmersivePage/interstitials/UpgradeInterstitial';
import ModuleUpgradeInterstitial from '../../pages/ImmersivePage/interstitials/ModuleUpgradeInterstitial';
import ThreadedUpgradeInterstitial from '../../pages/ImmersivePage/interstitials/ThreadedUpgradeInterstitial';
import ClabContainer from './Content/ClabContainer';
import CybAssessment from './Content/CybAssessment';

function ImmersiveBody({
  title,
  contentDescriptionId,
  type,
  activityTitleComponent,
  generalFrame,
  activity,
  toggleSuccessBannerDisplay,
  gotoNextActivity,
  gotoFirstIncomplete,
  authorized,
  completeActivity,
  userStore,
  isLastIncomplete,
  enrollment,
  isPrimarySidebarOpen,
  isSuccessBannerOpen,
  gotoCompletePage,
  navigate,
  completedActivityId,
  onCompletePage,
  hasIncompleteItems,
  isItemLastActivity,
  onlyLegacyVideo,
  emptyChallenge,
  activeInterstitial,
  setActiveInterstitial,
  hasCertificate,
  hideTasks = false,
  maxWidth = 750,
  videoMaxWidth = '4xl',
  setIsPrimarySidebarOpen,
  isMobile,
  setDisableSidebar,
  completeAssessmentActivity,
  clabVersion = 'legacy',
}) {
  // activity.isClab lets us know if this is a clab activity
  const { previousActivity, nextActivity, activityContent, questions, complete: activityIsComplete, isClab, clabId, isCybAssessment, cybAssessmentId } = activity;
  const { hasCurriculumItems, title: enrollmentTitle } = enrollment;
  if (onCompletePage) {
    return (
      <CompleteView
        generalFrame={generalFrame}
        title={title}
        contentDescriptionId={contentDescriptionId}
        type={type}
        hasIncompleteItems={hasIncompleteItems}
        hasCurriculumItems={hasCurriculumItems}
        gotoNextActivity={gotoNextActivity}
        gotoFirstIncomplete={gotoFirstIncomplete}
        hasCertificate={hasCertificate}
      />
    );
  }

  // This will be set when we are looking at an assessment activity
  if (isCybAssessment) {
    return (
      <CybAssessment
        generalFrame={generalFrame}
        setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
        setDisableSidebar={setDisableSidebar}
        id={cybAssessmentId}
        activity={activity}
        completeActivity={completeAssessmentActivity}
      />
    );
  }

  // If this is a clab, just render the container...
  if (isClab) {
    return <ClabContainer activity={activity} clabId={clabId} generalFrame={generalFrame} isMobile={isMobile} clabVersion={clabVersion} />;
  }

  const closeInterstitial = () => {
    setActiveInterstitial(false);
    setIsPrimarySidebarOpen(!isMobile); // show sidebar when leaving an interstitial if on desktop
    setDisableSidebar(false); // enable sidebar when leaving an interstitial
  };

  return (
    <>
      <div className={`immersive-2023-lesson-frame overflow-hidden ${generalFrame}`}>
        {process.env.NODE_ENV === 'development' && (
          <button onClick={() => completeActivity()} className="fixed right-10 bottom-10 z-220 py-3 px-1.5 bg-cyb-green-500 hover:bg-cyb-green-700 rounded">
            Dev Complete Activity
          </button>
        )}
        {!activeInterstitial && (
          <LessonContent
            activityTitle={activityTitleComponent}
            content={activityContent}
            questions={questions}
            toggleSuccessBannerDisplay={toggleSuccessBannerDisplay}
            gotoNextActivity={gotoNextActivity}
            previous={previousActivity}
            next={nextActivity}
            gotoStart={gotoFirstIncomplete}
            activity={activity}
            isComplete={activityIsComplete}
            authorized={authorized}
            completeActivity={completeActivity}
            userStore={userStore}
            isLastIncomplete={isLastIncomplete}
            enrollmentTitle={enrollmentTitle}
            isPrimarySidebarOpen={isPrimarySidebarOpen}
            onlyLegacyVideo={onlyLegacyVideo}
            hideTasks={hideTasks}
            maxWidth={maxWidth}
            videoMaxWidth={videoMaxWidth}
          />
        )}

        {activeInterstitial === 'upgrade' && <UpgradeInterstitial smallVersion />}
        {activeInterstitial === 'endOfModuleUpgrade' && <ModuleUpgradeInterstitial handleClose={closeInterstitial} />}
        {activeInterstitial === 'threadedActivityUpgrade' && <ThreadedUpgradeInterstitial handleClose={closeInterstitial} />}

        <SuccessBanner
          isOpen={isSuccessBannerOpen}
          toggleSuccessBannerDisplay={toggleSuccessBannerDisplay}
          gotoNextActivity={gotoNextActivity}
          gotoCompletePage={gotoCompletePage}
          isLast={!(nextActivity && nextActivity.url)}
          isLastIncomplete={isLastIncomplete}
        />
      </div>

      <div className="flex fixed inset-x-0 bottom-0 items-center px-6 w-full h-16 bg-white border-t-xs border-gray-400 lg:hidden">
        <div className="mr-auto" />
        <PreviousNextButtons
          activity={activity}
          previous={previousActivity}
          next={nextActivity}
          navigate={navigate}
          completedActivityId={completedActivityId}
          isLast={!(nextActivity && nextActivity.url)}
          isItemLastActivity={isItemLastActivity}
          gotoCompletePage={gotoCompletePage}
          isPopup
          emptyChallenge={emptyChallenge}
          completeActivity={completeActivity}
          disableNext={activeInterstitial === 'upgrade'}
          setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
          isMobile={isMobile}
          setDisableSidebar={setDisableSidebar}
        />
      </div>
    </>
  );
}

export default ImmersiveBody;
