import React from 'react';
import Modal from '../../Modal/Modal';
import { useClab } from '../../../providers/ClabProvider';

export default function ClabExitModal({ showClabModal, toggleClabModal, navigate }) {
  const { endLab } = useClab();

  const handleExit = async () => {
    await endLab();
    navigate('/');
  };

  return (
    <Modal open={showClabModal} toggle={toggleClabModal} trigger={null} size="sm" paddingBottom="pb-0">
      <div className="p-4">
        <h3 className="mb-8 text-2xl font-bold text-gray-800">Exit?</h3>
        <p className="mb-8 text-gray-700">If you exit the lab, your lab session will end. All changes to your virtual machines will be lost.</p>
        <div className="flex justify-between">
          <button className="px-8 h-10 text-xs font-semibold bg-gray-300 hover:bg-gray-400 rounded-md" type="button" onClick={toggleClabModal}>
            Nevermind
          </button>
          <button className="px-8 h-10 text-xs font-semibold text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-md" type="button" onClick={handleExit}>
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}
