import React, { useMemo, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import { useToggle } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import Collapsible from '../../Collapsible/Collapsible';
import FormatUtil from '../../../utils/formatUtil';
import AddLink from '../../AddLink/AddLink';
import AdminTable from '../../AdminTable/AdminTable';
import EnrollmentUtil from '../../../utils/enrollmentUtil';
import Button from '../../Button/Button';
import Loading from '../../Loading/Loading';
import { SHARED_TOPIC_BUTTON_STYLES } from '../../../constants';
import CollapsibleTriggerIcon from '../../Collapsible/CollapsibleTriggerIcon';
import BaselineEmptyText from './BaselineEmptyText';
import If from '../../If/If';

/**
 * `BaselineOverviewTable` - collapsible data table representing an assessment path and it's courses.
 * Used in `Baseline` Page `BaselineOverview` component.
 * @param {Table} table - schema that includes assessment path id, name, assessments, and tableData.
 *
 * @see [BaselineOverview](src/components/Baseline/BaselineOverview.js) `userTrackTables` definition.
 *
 * @returns JSX.Element
 */
const BaselineOverviewTable = inject(
  'userStore',
  'enrollmentStore',
  'commonStore'
)(
  observer(({ userStore, enrollmentStore, commonStore, table, isCollapsible = true }) => {
    const orgId = userStore?.adminTeam?.id;

    const navigate = useNavigate();

    const [open, toggleOpen] = useToggle(true);

    const onClickRetake = useCallback(
      (item, topic) => {
        const startEnrollment = async () => EnrollmentUtil.handleStartEnroll(userStore, enrollmentStore, commonStore, navigate, item, { exclude_progress_before: 'now' });

        commonStore.triggerConfirm({
          content: `Are you sure you want to retake the ${topic} assessment?`,
          cancel: () => {
            commonStore.resetConfirmState();
          },
          continue: async () => {
            // The following is a 'hacky' way to show a loading spinner on the button while we enroll.
            // This allows visual feedback to the user that their request to retake the assessment was received and is being processed.

            // eslint-disable-next-line no-param-reassign
            commonStore.confirmState.confirmBtn = <Loading wrapperClassName="py-0" className="w-4 h-4 border-2" color="white" />;
            await startEnrollment();
            commonStore.resetConfirmState();
          },
        });
      },
      [commonStore.triggerConfirm, commonStore.resetConfirmState, navigate]
    );

    const onClickStart = useCallback(async (item) => EnrollmentUtil.handleStartEnroll(userStore, enrollmentStore, commonStore, navigate, item), [navigate]);

    const renderUserActionButton = useCallback(
      (assessmentIndex) => {
        const assessment = table.assessments[assessmentIndex];
        const { score, submitted, children: modules, 'is-free': is_free } = assessment;
        const { children: activities } = modules?.[0] || {};

        // construct the assessment payload to comply with the format expected by the enrollment handler (usually algolia result)
        const item = {
          content_type: {
            id: 46, // Cybrary assessment type id = 46
            name: assessment['content-type'],
            nice_name: assessment['content-type'],
          },
          id: assessment['content-id'],
          is_free,
        };

        let label = 'Retake';

        if (!userStore.checkPermissions(item) && !userStore.team) {
          label = 'Upgrade';
        }

        // if there is a submission & score (including 0) on the course assessment, show retake button
        if (submitted && score !== null && score !== undefined) {
          return (
            <Button
              className={twMerge(SHARED_TOPIC_BUTTON_STYLES, 'bg-gray-300 hover:bg-gray-400 text-black hover:text-black')}
              onClick={() => onClickRetake(item, assessment['content-name'])}
            >
              {label}
            </Button>
          );
        }

        // if there is no score on the course assessment, add a start/continue button to enter the assessment.
        // If there is at least one child submitted, but not all children submitted to determine if the assessment is in progress
        const inProgress = !submitted && activities?.some((child) => child.submitted);

        label = inProgress ? 'Continue' : 'Start';

        return (
          <Button className={SHARED_TOPIC_BUTTON_STYLES} onClick={() => onClickStart(item)}>
            {label}
          </Button>
        );
      },
      [table, userStore.checkPermissions, userStore.team, onClickRetake, onClickStart]
    );

    const renderColumn = useCallback(
      (row, fullRow, _, column) => {
        if (row.value !== undefined && column.showCol) {
          if (column.key === 'user_action') {
            return renderUserActionButton(row.value);
          }

          const percentage = Math.round(parseFloat(row.value * 100));

          if (column.key === 'user_score') {
            const learnerId = userStore?.user?.id;
            const learnerName = userStore?.user?.name || userStore?.user?.real_name;
            const toHref = `${window.location.pathname}/${orgId}/paths/assessments/${table.id}/${fullRow[0].value}/summary?learnerId=${learnerId}&learnerName=${learnerName}`;

            return (
              <AddLink className="text-cyb-pink-500 underline" to={toHref}>
                {percentage}%
              </AddLink>
            );
          }

          return `${percentage}%`;
        }

        return '';
      },
      [table, userStore?.user, orgId, renderUserActionButton, window.location.pathname]
    );

    const formatColumns = useMemo(() => {
      // table column value indexes that shoud be customly rendered (user_score, community_score, user_action).
      // safely hardcoded as the targeted columns were also manually created; see `src/components/Baseline/BaselineOverview.js - func generateTableData`
      const targetIndexes = [2, 3, 4];
      const cols = [];

      targetIndexes.forEach((validIndex) => {
        const entry = {
          method: (row, fullRow, headings) => renderColumn(row, fullRow, headings, headings[validIndex]),
          colIdx: validIndex,
        };

        cols.push(entry);
      });

      return cols;
    }, [table, renderColumn]);

    return (
      <div className="flex flex-col mb-4">
        <If condition={isCollapsible}>
          <button
            className={`flex flex-row justify-between items-center py-4 px-3 bg-gray-100 hover:bg-gray-200 border-1 ${
              open ? 'border-b-0' : ''
            } border-gray-500 hover:cursor-pointer`}
            onClick={toggleOpen}
          >
            <span className="text-sm font-bold">
              {table.name} ({table.tableData.tableData.length})
            </span>
            <CollapsibleTriggerIcon name={table.name} open={open} />
          </button>
        </If>
        <Collapsible
          triggerTagName="div"
          open={open}
          className="cursor-default"
          openedClassName="cursor-default"
          transitionTime={200}
          ariaLabel={table.name}
          collapsibleId={FormatUtil.lowerCaseHyphenText(table.name)}
        >
          <div className="flex">
            <AdminTable
              wrapperClassName="m-0 w-full"
              colClasses="whitespace-nowrap"
              headings={table.tableData.columns}
              data={table.tableData.tableData}
              formatColumns={formatColumns}
              noResultsMsg={<BaselineEmptyText orgId={orgId} />}
            />
          </div>
        </Collapsible>
      </div>
    );
  })
);

export default BaselineOverviewTable;
