import React, { useMemo } from 'react';
import { generateTableData } from '../../Baseline/shared';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import Container from '../../Container/Container';
import BaselineOverviewTable from '../../Baseline/modules/BaselineOverviewTable';

function AssessmentsTableWidget({ selectedRole }) {
  const selectedRoleTableData = useMemo(() => {
    if (!selectedRole) {
      return null;
    }

    return {
      id: selectedRole?.['content-id'],
      name: selectedRole?.['content-name'],
      assessments: selectedRole?.children,
      tableData: generateTableData(selectedRole),
    };
  }, [selectedRole]);

  return (
    <WidgetContainer className="flex flex-col pt-0" omitBorder>
      <Header as="h3" className="mb-3">
        Detail
      </Header>
      <If condition={!selectedRoleTableData}>
        <Container>
          <Loading message="Loading..." />
        </Container>
      </If>
      <If condition={Boolean(selectedRoleTableData)}>
        <div className="flex flex-col">
          <BaselineOverviewTable table={selectedRoleTableData} isCollapsible={false} />
        </div>
      </If>
    </WidgetContainer>
  );
}

export default AssessmentsTableWidget;
