import React from 'react';
import Alert from '../../Icons/Alert';
import Algolia from '../../Icons/Algolia';
import AmericanExpress from '../../Icons/AmericanExpress';
import ArrowNarrowUp from '../../Icons/ArrowNarrowUp';
import ArrowNarrowDown from '../../Icons/ArrowNarrowDown';
import ArrowRight from '../../Icons/ArrowRight';
import ArrowsUpDown from '../../Icons/ArrowsUpDown';
import Book from '../../Icons/Book';
import Bookmark from '../../Icons/Bookmark';
import Bullseye from '../../Icons/Bullseye';
import Calendar from '../../Icons/Calendar';
import Camera from '../../Icons/Camera';
import Certificate from '../../Icons/Certificate';
import Check from '../../Icons/Check';
import CheckmarkCircle from '../../Icons/CheckmarkCircle';
import ChevronDown from '../../Icons/ChevronDown';
import ChevronLeft from '../../Icons/ChevronLeft';
import ChevronRight from '../../Icons/ChevronRight';
import ChevronUp from '../../Icons/ChevronUp';
import ChevronDoubleLeft from '../../Icons/ChevronDoubleLeft';
import ChevronDoubleRight from '../../Icons/ChevronDoubleRight';
import ClipboardList from '../../Icons/ClipboardList';
import Clock from '../../Icons/Clock';
import CloseArrows from '../../Icons/CloseArrows';
import CloudLock from '../../Icons/CloudLock';
import Circle from '../../Icons/Circle';
import Community from '../../Icons/Community';
import CompletionCheck from '../../Icons/CompletionCheck';
import ContentAvailable from '../../Icons/ContentAvailable';
import Copy from '../../Icons/Copy';
import DinersClub from '../../Icons/DinersClub';
import Diamond from '../../Icons/Diamond';
import Discord from '../Navigation/Icons/DiscordIcon';
import Discover from '../../Icons/Discover';
import DotsHorizontal from '../../Icons/DotsHorizontal';
import DoubleChevronRight from '../../Icons/DoubleChevronRight';
import Download from '../../Icons/Download';
import Edit from '../../Icons/Edit';
import Elo from '../../Icons/Elo';
import Email from '../../Icons/Email';
import ExclamationCircle from '../../Icons/ExclamationCircle';
import ExclamationTriangle from '../../Icons/ExclamationTriangle';
import ExternalLink from '../../Icons/ExternalLink';
import Facebook from '../../Icons/Facebook';
import FaceFrown from '../../Icons/FaceFrown';
import Filter from '../../Icons/Filter';
import Flask from '../../Icons/Flask';
import GitHub from '../../Icons/GitHub';
import Globe from '../../Icons/Globe';
import Goal from '../../Icons/Goal';
import GoalFlag from '../../Icons/GoalFlag';
import GraduationCap from '../../Icons/GraduationCap';
import Grid from '../../Icons/Grid';
import Growth from '../../Icons/Growth';
import Handshake from '../../Icons/Handshake';
import HandWave from '../../Icons/HandWave';
import HelpIcon from '../../Icons/HelpIcon';
import Hacker from '../../Icons/Hacker';
import Hipercard from '../../Icons/Hipercard';
import Inbox from '../../Icons/Inbox';
import InformationCircle from '../../Icons/InformationCircle';
import Instagram from '../../Icons/Instagram';
import Keyboard from '../../Icons/Keyboard';
import License from '../../Icons/License';
import LineGraph from '../../Icons/LineGraph';
import Linkedin from '../../Icons/Linkedin';
import List from '../../Icons/List';
import Lock from '../../Icons/Lock';
import LogOut from '../../Icons/LogOut';
import Jcb from '../../Icons/Jcb';
import Maestro from '../../Icons/Maestro';
import Mastercard from '../../Icons/Mastercard';
import Mail from '../../Icons/Mail';
import Megaphone from '../../Icons/Megaphone';
import Mic from '../../Icons/Mic';
import Minus from '../../Icons/Minus';
import MinusCircle from '../../Icons/MinusCircle';
import Mountain from '../../Icons/Mountain';
import Newspaper from '../../Icons/Newspaper';
import Notebook from '../../Icons/Notebook';
import OnlineVideo from '../../Icons/OnlineVideo';
import PaperPlane from '../../Icons/PaperPlane';
import Path from '../../Icons/Path';
import Pause from '../../Icons/Pause';
import Pencil from '../../Icons/Pencil';
import PersonShield from '../../Icons/PersonShield';
import Play from '../../Icons/Play';
import Plus from '../../Icons/Plus';
import PlusCircle from '../../Icons/PlusCircle';
import QuestionCircle from '../../Icons/QuestionCircle';
import Refresh from '../../Icons/Refresh';
import Ribbon from '../../Icons/Ribbon';
import Ribbon2 from '../../Icons/Ribbon2';
import Road from '../../Icons/Road';
import Rocket from '../../Icons/Rocket';
import Rocket2 from '../../Icons/Rocket2';
import Search from '../../Icons/Search';
import SearchIcon from '../../Icons/SearchIcon';
import Selector from '../../Icons/Selector';
import Share from '../../Icons/Share';
import Shield from '../../Icons/Shield';
import Sitemap from '../../Icons/Sitemap';
import Spy from '../../Icons/Spy';
import Star from '../../Icons/Star';
import Sparkler from '../../Icons/Sparkler';
import Speedometer from '../../Icons/Speedometer';
import TeamsIcon from '../../Icons/TeamsIcon';
import Trash from '../../Icons/Trash';
import TrendingUp from '../../Icons/TrendingUp';
import TrendingDown from '../../Icons/TrendingDown';
import TrendingConstant from '../../Icons/TrendingConstant';
import Twitter from '../../Icons/Twitter';
import ThumbsDown from '../../Icons/ThumbsDown';
import ThumbsUp from '../../Icons/ThumbsUp';
import UnionPay from '../../Icons/UnionPay';
import Unlock from '../../Icons/Unlock';
import User from '../../Icons/User';
import UserGroup from '../../Icons/UserGroup';
import UserIcon from '../../Icons/UserIcon';
import UserMinus from '../../Icons/UserMinus';
import UserPlus from '../../Icons/UserPlus';
import UserWithStars from '../../Icons/UserWithStars';
import VisaCard from '../../Icons/VisaCard';
import Video from '../../Icons/Video';
import WorkingDev from '../../Icons/WorkingDev';
import Wrench from '../../Icons/Wrench';
import X from '../../Icons/X';
import XCircle from '../../Icons/XCircle';
import Trophy from '../../Icons/Trophy';
import LightningBolt from '../../Icons/LightningBolt';
import Fire from '../../Icons/Fire';
import Chat from '../../Icons/Chat';
import XP from '../../Icons/Xp';
import Expand from '../../Icons/Expand';
import Collapse from '../../Icons/Collapse';

import DashboardIcon from '../Navigation/Icons/DashboardIcon';
import BrowseIcon from '../Navigation/Icons/BrowseIcon';
import LearningIcon from '../Navigation/Icons/LearningIcon';
import Apple from '../Navigation/Icons/Apple';
import Android from '../Navigation/Icons/Android';
import SlackIcon from '../Navigation/Icons/SlackIcon';
import BellIcon from '../Navigation/Icons/BellIcon';
import Certification from '../Navigation/Icons/Certification';
import Terminal from '../Navigation/Icons/Terminal';
import CareerPathIcon from '../Navigation/Icons/CareerPathIcon';
import AchievementIcon from '../Navigation/Icons/AchievementIcon';
import ForumsIcon from '../Navigation/Icons/ForumsIcon';
import CostSaving from '../../Icons/CostSaving';

const ICON_MAP = {
  achievement: AchievementIcon,
  'achievement-icon': AchievementIcon,
  alert: Alert,
  algolia: Algolia,
  'american-express': AmericanExpress,
  android: Android,
  apple: Apple,
  'arrow-narrow-down': ArrowNarrowDown,
  'arrow-narrow-up': ArrowNarrowUp,
  'arrow-right': ArrowRight,
  'arrows-up-down': ArrowsUpDown,
  bell: BellIcon,
  'bell-icon': BellIcon,
  book: Book,
  bookmark: Bookmark,
  browse: BrowseIcon,
  'browse-icon': BrowseIcon,
  bullseye: Bullseye,
  calendar: Calendar,
  camera: Camera,
  career: CareerPathIcon,
  'career-path-icon': CareerPathIcon,
  certificate: Certificate,
  certification: Certification,
  chat: Chat,
  check: Check,
  'checkmark-circle': CheckmarkCircle,
  'chevron-double-left': ChevronDoubleLeft,
  'chevron-double-right': ChevronDoubleRight,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  circle: Circle,
  'clipboard-list': ClipboardList,
  clock: Clock,
  'close-arrows': CloseArrows,
  'cloud-lock': CloudLock,
  collapse: Collapse,
  community: Community,
  'completion-check': CompletionCheck,
  'content-available': ContentAvailable,
  'cost-saving': CostSaving,
  copy: Copy,
  dashboard: DashboardIcon,
  'dashboard-icon': DashboardIcon,
  diamond: Diamond,
  'diners-club': DinersClub,
  discord: Discord,
  discover: Discover,
  'dots-horizontal': DotsHorizontal,
  'double-chevron-right': DoubleChevronRight,
  download: Download,
  edit: Edit,
  elo: Elo,
  email: Email,
  expand: Expand,
  'exclamation-circle': ExclamationCircle,
  'exclamation-triangle': ExclamationTriangle,
  'external-link': ExternalLink,
  'face-frown': FaceFrown,
  facebook: Facebook,
  filter: Filter,
  fire: Fire,
  flask: Flask,
  forums: ForumsIcon,
  'forums-icon': ForumsIcon,
  'git-hub': GitHub,
  globe: Globe,
  goal: Goal,
  'goal-flag': GoalFlag,
  'graduation-cap': GraduationCap,
  grid: Grid,
  growth: Growth,
  hacker: Hacker,
  'hand-wave': HandWave,
  handshake: Handshake,
  help: HelpIcon,
  'help-icon': HelpIcon,
  hipercard: Hipercard,
  inbox: Inbox,
  'information-circle': InformationCircle,
  instagram: Instagram,
  jcb: Jcb,
  keyboard: Keyboard,
  learning: LearningIcon,
  'learning-icon': LearningIcon,
  license: License,
  'lightning-bolt': LightningBolt,
  'line-graph': LineGraph,
  linkedin: Linkedin,
  list: List,
  lock: Lock,
  'log-out': LogOut,
  maestro: Maestro,
  mail: Mail,
  mastercard: Mastercard,
  megaphone: Megaphone,
  mic: Mic,
  minus: Minus,
  'minus-circle': MinusCircle,
  mountain: Mountain,
  newspaper: Newspaper,
  notebook: Notebook,
  'online-video': OnlineVideo,
  'paper-plane': PaperPlane,
  path: Path,
  pause: Pause,
  pencil: Pencil,
  'pencil-alt': Edit,
  'person-shield': PersonShield,
  play: Play,
  plus: Plus,
  'plus-circle': PlusCircle,
  'question-circle': QuestionCircle,
  refresh: Refresh,
  ribbon: Ribbon,
  'ribbon-2': Ribbon2,
  road: Road,
  rocket: Rocket,
  'rocket-2': Rocket2,
  search: Search,
  'search-icon': SearchIcon,
  selector: Selector,
  share: Share,
  shield: Shield,
  sitemap: Sitemap,
  slack: SlackIcon,
  'slack-icon': SlackIcon,
  sparkler: Sparkler,
  speedometer: Speedometer,
  spy: Spy,
  star: Star,
  teams: TeamsIcon,
  'teams-icon': TeamsIcon,
  terminal: Terminal,
  'thumbs-down': ThumbsDown,
  'thumbs-up': ThumbsUp,
  trash: Trash,
  'trending-constant': TrendingConstant,
  'trending-down': TrendingDown,
  'trending-up': TrendingUp,
  trophy: Trophy,
  twitter: Twitter,
  unionpay: UnionPay,
  unlock: Unlock,
  user: User,
  'user-group': UserGroup,
  'user-icon': UserIcon,
  'user-minus': UserMinus,
  'user-plus': UserPlus,
  'user-with-stars': UserWithStars,
  video: Video,
  visa: VisaCard,
  'working-dev': WorkingDev,
  wrench: Wrench,
  x: X,
  'x-circle': XCircle,
  xp: XP,
};

/**
 * Icon component maps a name to an icon component and renders it with the appropriate props
 * @param {string} name - The name of the icon to render
 * @param {boolean} active - Whether the icon is active
 * @param {string} className - Additional classes to apply to the icon
 * @param {object} accessibilityProps - Additional props to pass to the icon
 * @param {string} id - The id of the icon
 * @returns
 */
function Icon({ name, active, className, accessibilityProps, id }) {
  if (!name) {
    return null;
  }
  const MappedIconComponent = ICON_MAP[name];
  if (!MappedIconComponent) {
    return null;
  }
  return <MappedIconComponent active={active} classes={className} accessibilityProps={accessibilityProps} id={id} />;
}

export default Icon;

export const ICON_NAMES = Object.keys(ICON_MAP);
