/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import LoadingOrError from '../Loading/LoadingOrError';
import TerminalContainer from '../Terminal/TerminalContainer';
import GlobalTimer from './global-timer';
import ExitModal from './exit-modal';
import ConnectionError from './connection-error';
import useNetwork from '../../hooks/useNetwork';
import BuildingLab from './building-lab';
import { useClab } from '../../../../providers/ClabProvider';
import BugsnagUtil from '../../../../utils/bugsnagUtil';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';

export default function NewLearnerView2() {
  // Load query string params
  const [simpleView] = useState(true);
  const [connectionError, setConnectionError] = useState('');
  const [showConnectionErrorModal, setShowConnectionErrorModal] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const { isOnline } = useNetwork();
  const { lab, loading, error, endLab, resources, activeResource, token, connect } = useClab();

  const { status, expires } = lab || {};
  const labRunning = status === 'running';
  const labStopping = status === 'stopping';
  const labStopped = status === 'stopped';

  useEffect(() => {
    if ((!isOnline || connectionError) && !showConnectionErrorModal) {
      setShowConnectionErrorModal(true);
    }
  }, [connectionError, isOnline]);

  // Connect to the first resource after initially reaching the lab running state
  useEffect(() => {
    // If the lab isn't running, just move on
    if (!labRunning) {
      return;
    }
    // The lab is running, but if we have already started this lab, move on
    if (hasStarted) {
      return;
    }
    // This is the first time that the lab has started, and there's no active resource, let's connect to the first resource
    if (!activeResource && resources?.length) {
      connect(resources[0]);
      setHasStarted(true);
    }
  }, [labRunning, activeResource, hasStarted, resources]);

  // Handle exiting a session
  const handleExit = async () => {
    setShowExitModal(false);
    await endLab();
  };

  const guacConnectionErrorHandler = (msg = '') => {
    setConnectionError(msg);
    BugsnagUtil.notifyWithNamedMetadata(new Error(`GUAC ERROR: ${msg}`), 'clab_guac_error', { lab });
  };

  if (labStopping || labStopped) {
    return (
      <div className="items-center w-full h-screen text-gray-100 bg-neutral-900">
        <LoadingOrError
          error
          errorText="Your lab session has expired. Click Reconnect to refresh the page and start a new lab session."
          spinnerClass="h-14 w-14 text-gray-100"
          textClass="text-center"
          showReconnect
        />
      </div>
    );
  }

  if (loading || !resources) {
    return (
      <div className="items-center w-full h-screen text-gray-100 bg-neutral-900">
        <LoadingOrError loading={loading} error={!!error} errorText={error || ''} spinnerClass="h-14 w-14 text-gray-100" textClass="text-center" />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="w-full">
        <div className="hidden relative flex-col w-full md:flex md:fixed md:flex-row">
          <div className="grid relative flex-1 grid-cols-1 gap-1 h-screen bg-neutral-900">
            {labRunning && !error && !showConnectionErrorModal && !!resources?.length && (
              <TerminalContainer resources={resources} errorHandler={guacConnectionErrorHandler} activeResource={activeResource} token={token} />
            )}
            {!labRunning && !error && !showConnectionErrorModal && <BuildingLab />}
            {!!error && !showConnectionErrorModal && <LoadingOrError loading={false} error={!!error} errorText={error || ''} textClass="text-center text-gray-100" showReconnect />}
            {showConnectionErrorModal && <ConnectionError msg={connectionError} />}
          </div>
        </div>
      </div>
      {!!expires && !!lab && <GlobalTimer expires={expires} onEnd={handleExit} />}
      <ExitModal simple={simpleView} open={showExitModal} close={() => setShowExitModal(false)} handleExit={handleExit} />
    </ErrorBoundary>
  );
}
