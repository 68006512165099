import React from 'react';
import Icon from '../../Icon/Icon';

const CYBER_WEEK_PROMO = {
  // Configure CTA Link
  targetUrl: `/upgrade/checkout?discountId=${process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_CYBER_WEEK_PROMO}&plan=annual&lockPlan=1&cybsource=appbanner`,

  // Configure the attributes of the banner
  title: <span className="text-xl">Unlock Cyber Week Savings!</span>,
  body: (
    <>
      <span className="group-hover:border-b-2">
        Save <span className="font-bold">50%</span> on <span className="font-bold">Insider Pro Annual</span>
      </span>{' '}
      when you upgrade today!
    </>
  ),
  footer: (
    <span className="flex mt-1">
      Valid until December 1st. <Icon name="growth" className="mx-2 w-4 h-4 fill-white" /> Hurry, this offer ends soon!
    </span>
  ),

  // Enable or disable the promo
  isEnabled: true,

  // Set the priority of the promo. Higher number = shown first.
  priority: 1,

  // Set the start and expiration date of the promo
  endDate: '2024-12-02T00:00:00-05:00',

  // Show the promo based on the user's role/state
  isShownCipFree: true,

  // Set Snowplow tracking labels
  spLabel: 'novCyberWeekPromo',
  spCategory: 'CIPUpgradeBanner',

  iconName: 'unlock', // @see https://app.blackwaterbay.cybrary.it/icons for available icons
  ctaButtonText: 'Upgrade',

  bannerClassName: 'from-cyb-purple-500 to-cyb-purple-500',
};

export default CYBER_WEEK_PROMO;
