import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import queryString from 'query-string';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Table from '../../components/Table/Table';
import ExpandingContentTable from '../../components/Enterprise/ExpandingContentTable';
import CheckAndDisplayCerts from '../../components/Enterprise/CheckAndDisplayCerts';
import PostAssessmentSurvey from '../../components/Enrollments/PostAssessmentSurvey';
import ProgressLabel from '../../components/Enterprise/ProgressLabel';
import WorkroleModal from '../../components/Analytics/WorkroleModal';
import ArchivedLabel from '../../components/ContentUpdates/ArchivedLabel';
import MyGoals from '../../components/Goals/MyGoals';
import EnrollmentTransformer from '../../transformers/enrollmentTransformer';
import ContentUpdateBanner from '../../components/ContentUpdates/ContentUpdateBanner';
import AdminTable from '../../components/AdminTable/AdminTable';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../../components/AddLink/AddLink';
import TeamsIcon from '../../Icons/TeamsIcon';
import Title from '../../components/Title/Title';
import BetaLabel from '../../components/Label/BetaLabel';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import CertificateModalContent from '../Certificates/CertificateModalContent';
import Modal from '../../components/Modal/Modal';
import Label from '../../components/Label/Label';
import FeedbackModal from '../../components/Feedback/FeedbackModal';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import AssessmentSummary from '../../components/Assessments/AssessmentSummary';
import If from '../../components/If/If';
import { HELP_DESK_LINKS } from '../../constants';

// Return the team from the teams object, first checking for team id, next for group id.
function findTeamOrGroup(teams, id) {
  if (teams && Object.keys(teams).length) {
    if (teams[id]) {
      return teams[id];
    }
    // No team found, look for groups
    const keys = Object.keys(teams);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const team = teams[key];
      const { user_groups: groups } = team;
      if (groups && groups.length) {
        for (let k = 0; k < groups.length; k++) {
          const group = groups[k];
          if (group.id === id) {
            return team;
          }
        }
      }
    }
  }
  // Return a sensible default when we can't find the team
  return {
    name: 'My Assignments',
  };
}

// Organize assignments by team (if there are any), and return the organized assignments as well as a
// boolean indicating whether there are any assignments
function handleAssignments(enrollmentList, userTeams) {
  const groups = {};
  let hasAssignments = false;
  if (enrollmentList && enrollmentList.assignments && enrollmentList.assignments.data && enrollmentList.assignments.data.length) {
    hasAssignments = true;
    enrollmentList.assignments.data.forEach((item) => {
      if (!groups[item.team_id]) {
        groups[item.team_id] = {
          team: findTeamOrGroup(userTeams, item.team_id),
          data: [],
          error: enrollmentList.assignments.error,
          filter: enrollmentList.assignments.filter,
          loading: enrollmentList.assignments.loading,
        };
      }
      groups[item.team_id].data.push(item);
    });
  }
  return [hasAssignments, groups];
}

function LearningAltView({ view, section, status }) {
  if (view === 'empty') {
    return <NoEnrollmentsMessage section={section} status={status} />;
  }
  return <Loading message="Loading..." />;
}

function NoEnrollmentsMessage({ section, status }) {
  const content = section === 'all' ? 'items' : section.split('-').join(' ');
  const text = status !== 'completed' ? `${content} in progress` : `completed ${content}`;
  return (
    <EmptyContent>
      <p className="text-gray-600">You currently have no {text}.</p>
      <p className="mb-8 text-gray-600">Go ahead and browse our catalog to get started!</p>
      <AddLink to="/browse/refined">
        <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm">Browse Catalog</button>
      </AddLink>
    </EmptyContent>
  );
}

function RecentlyCompletedActivities({ loading, error, data, getWorkroleIcons, formatDate, formatTitleWithType }) {
  return (
    <div className="pb-8">
      <div className="py-4">
        <h2 className="mr-2 text-2xl font-black">Recently Completed Hands-On Learning</h2>
      </div>
      <div>
        <AdminTable
          headings={data.columns}
          data={data.tableData}
          tableLoading={loading}
          tableError={error}
          ignoredCols={[
            FormatUtil.getColIndex(data.columns, 'content_description_type'),
            FormatUtil.getColIndex(data.columns, 'content_description_archived_at'),
            FormatUtil.getColIndex(data.columns, 'learning_seconds_completed'),
            FormatUtil.getColIndex(data.columns, 'created_at'),
            FormatUtil.getColIndex(data.columns, 'progress'),
          ]}
          addCustomCol={[
            {
              method: getWorkroleIcons,
              position: 7,
              header: 'Assessment Results',
              omitHeading: true,
            },
          ]}
          formatColumns={[
            {
              method: formatDate,
              colIdx: FormatUtil.getColIndex(data.columns, 'completed_at'),
            },
            {
              method: (col, row, tableHeadings) => formatTitleWithType(null, col, row, tableHeadings, null, true),
              colIdx: FormatUtil.getColIndex(data.columns, 'content_description_title'),
            },
          ]}
        />
      </div>
    </div>
  );
}

/**
 * Gets the state for a single list
 * @param {enrollmentList} list enrollmentList from the enrollmentStore
 * @returns {string} 'loading', 'empty', or null if the data is populated and ready to be displayed
 */
function getListState(list) {
  if (list.loading) {
    return 'loading';
  }
  // If the list is missing data and we have no errors, show empty state
  if (list.data && !list.data.length && !list.error) {
    return 'empty';
  }
  // Return null to indicate that the list is loaded and not empty (No alt view state needed)
  return null;
}

// handles an array of lists and checks that all are loaded and not empty, returning the appropriate state
function getListsState(lists) {
  // Get the state for each list
  const states = lists.map((list) => getListState(list));
  // If any of the lists are loading, start there
  if (states.includes('loading')) {
    return 'loading';
  }
  // If all of the lists are empty, show the empty state
  if (states.every((state) => state === 'empty')) {
    return 'empty';
  }
  // Return null to indicate that the lists are all loaded and at least one is not empty (No alt view state needed)
  return null;
}

const EnrollmentsDashboard = inject(
  'commonStore',
  'enrollmentStore',
  'userStore',
  'certificateStore',
  'authStore',
  'notificationsStore',
  'dashboardStore',
  'feedbackStore'
)(
  observer(
    class EnrollmentsDashboard extends Component {
      state = {
        status: null,
        section: null,
        certModalOpen: false,
        selectedCert: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('My Learning | Cybrary');
        this.checkUpdateView();
        // Get user notifications (if not retrieved already)
        this.props.notificationsStore.getUserNotifications(this.props.userStore);
      }

      componentDidUpdate() {
        this.checkUpdateView();
      }

      componentWillUnmount() {
        // Ensure that any errors or loading states are cleared out.
        this.props.enrollmentStore.setDefaultEnrollmentList();
        this.props.enrollmentStore.setDefaultRecentlyComplete();
        this.props.certificateStore.reset();
        this.props.dashboardStore.resetCurrentEnrollment();
      }

      checkUpdateView = () => {
        const status = this.getViewStatus();
        const section = this.getViewSection();
        const newState = {
          ...this.state,
          status,
          section,
        };

        if (this.state.status !== newState.status || this.state.section !== newState.section) {
          if (section !== 'goals' && status !== 'results') {
            this.props.certificateStore.getCertificates();
            this.props.dashboardStore.getCurrentEnrollment();
            this.setState(newState, () => {
              this.getEnrollmentLists();
              if (status === 'completed' && section === 'all') {
                this.props.enrollmentStore.getRecentlyComplete();
              }
            });
          } else {
            this.setState(newState);
          }
          this.setNewPageTitle(section, status);
        }
      };

      setNewPageTitle = (section, status) => {
        const sectionIsAll = section === 'all';
        const statusIsCompleted = status === 'completed';
        const statusIsExpired = status === 'expired';

        // if section and section is not 'all', label section (i.e. Virtual Labs), else if section is all or null default to 'My Learning'
        let pageSection = !sectionIsAll ? FormatUtil.replaceHyphensWithSpace(section) : 'My Learning';

        // if status is incomplete return default "In Progress"
        let pageStatus = 'In Progress';
        // If section is all and status is not completed and not expired return blank status
        if (sectionIsAll && !statusIsCompleted && !statusIsExpired) {
          pageStatus = '';
        } else if (section === 'assessments' && status === 'results') {
          // passes first check, and is not incomplete, capitalize status and return that
          pageStatus = 'Assessment';
          pageSection = 'Summary';
        } else if (status !== 'incomplete') {
          // passes first check, and is not incomplete, capitalize status and return that
          pageStatus = FormatUtil.ucwords(status);
        }

        return this.props.commonStore.setPageTitle(`${pageStatus} ${pageSection} | Cybrary`);
      };

      getViewStatus = () => {
        const { section, status } = this.props.match.params;

        if (section === 'completed' || status === 'completed') {
          return 'completed';
        }
        if (section === 'expired' || status === 'expired') {
          return 'expired';
        }
        if (section === 'upcoming' || status === 'upcoming') {
          return 'upcoming';
        }
        if (section === 'results' || status === 'results') {
          return 'results';
        }
        return 'incomplete';
      };

      getViewSection = () => {
        const { section } = this.props.match.params;
        if (!section || section === 'completed') {
          return 'all';
        }
        return section;
      };

      // Load enrollment lists into store based on section and status
      getEnrollmentLists = () => {
        const { section, status } = this.state;
        const { enrollmentStore } = this.props;
        switch (section) {
          case 'all':
            enrollmentStore.getEnrollmentList('collections', status);
            enrollmentStore.getEnrollmentList('assignments', status);
            enrollmentStore.getEnrollmentList('career-paths', status);
            enrollmentStore.getEnrollmentList('curriculum', status);
            enrollmentStore.getEnrollmentList('courses', status);
            enrollmentStore.getEnrollmentList('virtual-labs', status);
            enrollmentStore.getEnrollmentList('cybrary-assessments', status);
            enrollmentStore.getEnrollmentList('assessments', status);
            enrollmentStore.getEnrollmentList('practice-tests', status);
            enrollmentStore.getEnrollmentList('challenges', status);
            break;
          case 'assessments':
            enrollmentStore.getEnrollmentList('cybrary-assessments', status);
            enrollmentStore.getEnrollmentList('assessments', status);
            break;
          default:
            enrollmentStore.getEnrollmentList(section, status);
            break;
        }
      };

      handleLaunching = (e, enrollmentId, contentId, topLevelEnrollment, isActivity, type) => {
        e.stopPropagation();
        if (!enrollmentId) {
          const isPathContent = !!topLevelEnrollment && topLevelEnrollment.content_description_type && EnrollmentUtil.isCollection(topLevelEnrollment.content_description_type);
          // IF this is an assignment or career path, we want to use the content_description_id for the top level (assignment) rather than an item in it
          const contentDescriptionId = isPathContent && topLevelEnrollment.content_description_id ? topLevelEnrollment.content_description_id : contentId;
          this.props.enrollmentStore
            .startEnrollment(contentDescriptionId, null, type)
            .then(
              action('fetchSuccess', (response) => {
                const enrollment = EnrollmentTransformer.transformEnrollmentData([response.enrollment])[0];
                this.launchItem(enrollment, contentId);
              })
            )
            .catch(
              action('fetchError', (error) => {
                Bugsnag.notify(error);
              })
            );
        } else if (isActivity) {
          // If overall enrollment isActivity, need to hit activity specific enrollment route
          this.props.enrollmentStore
            .getEnrollmentActivity(enrollmentId)
            .then(
              action('fetchSuccess', (response) => {
                const enrollment = EnrollmentTransformer.transformEnrollmentData([response])[0];
                this.launchItem(enrollment, contentId);
              })
            )
            .catch(
              action('fetchError', (error) => {
                Bugsnag.notify(error);
              })
            );
        } else {
          this.props.enrollmentStore
            .getEnrollmentById(enrollmentId)
            .then(
              action('fetchSuccess', (response) => {
                const enrollment = EnrollmentTransformer.transformEnrollmentData([response])[0];
                this.launchItem(enrollment, contentId);
              })
            )
            .catch(
              action('fetchError', (error) => {
                Bugsnag.notify(error);
              })
            );
        }
        return null;
      };

      launchCurriculumItem = (url, contentId, curriculumItem) => {
        const { learning_modules: itemModules } = curriculumItem;
        let itemId = curriculumItem.content_description_id || null;
        if (!itemId && curriculumItem.content_description && curriculumItem.content_description.id) {
          itemId = curriculumItem.content_description.id;
        }
        if (!itemModules && itemId) {
          // A curriculum item with an enrollment activity inside of it
          return this.props.navigate(`${url}/item/enrollment/activity/${itemId}`);
        }
        // Ensure the content clicked is not a module, and
        const clickedModule = itemModules.find((item) => item.id === contentId);
        if (itemId && !clickedModule && itemId !== contentId) {
          return this.props.navigate(`${url}/item/${itemId}/activity/${contentId}`);
        }
        if (itemId && clickedModule && clickedModule.activities) {
          // Go to the first activity in the module
          return this.props.navigate(`${url}/item/${itemId}/activity/${clickedModule.activities[0].id}`);
        }
        if (itemId) {
          return this.props.navigate(`${url}/item/${itemId}`);
        }
        return this.props.navigate(url);
      };

      launchItem = (enrollment, contentId) => {
        const curriculumItem = this.props.enrollmentStore.findCurriculumItemByContentId(enrollment, contentId);
        const hasCurriculumItems = !!enrollment.curriculumItems;

        const { learning_modules: learningModules } = enrollment;
        if (!hasCurriculumItems && (!learningModules || !learningModules.length)) {
          // This is an enrollment activity, just launch it!
          return this.props.navigate(`/immersive/enrollment/activity/${enrollment.id}`);
        }
        const url = `/immersive/${enrollment.id}`;
        if (curriculumItem) {
          return this.launchCurriculumItem(url, contentId, curriculumItem);
        }
        if (learningModules) {
          const clickedModule = learningModules.find((item) => item.id === contentId);
          if (clickedModule && clickedModule.activities) {
            // Go to the first activity in the module
            return this.props.navigate(`${url}/activity/${clickedModule.activities[0].id}`);
          }
        }
        const { _raw: rawEnrollment } = enrollment;
        const { content_description_id: enrollmentContentDescriptionId } = rawEnrollment || {};
        if (!enrollmentContentDescriptionId || contentId === enrollmentContentDescriptionId) {
          // Just launch straight to the enrollment
          return this.props.navigate(url);
        }
        // If we are still here, we are launching into a regular enrollment and it's content
        return this.props.navigate(`${url}/activity/${contentId}`);
      };

      formatTitleWithType = (enrollmentId, col, row, headings, topLevelEnrollment, omitLaunchLink) => {
        const colArr = col.value.split(' ');
        const lastWord = colArr[colArr.length - 1];
        colArr.pop();
        const contentId = row[FormatUtil.getColIndex(headings, 'content_description_id')].value;
        const type = row[FormatUtil.getColIndex(headings, 'content_description_type')].value;
        const vendor = row[FormatUtil.getColIndex(headings, 'vendor')].value;
        const archivedIdx = FormatUtil.getColIndex(headings, 'content_description_archived_at');
        const isArchived = !!archivedIdx && archivedIdx > -1 ? !!row[archivedIdx].value : false;
        const preventLaunch = !!isArchived || !!omitLaunchLink;
        const formatType = FormatUtil.formatContentType(type);
        // If there is not a top level this is likely an Additional Learning item (this is an activity),
        // or if for some reason it is a top level and is an activity, need to hit different route
        const isActivity = !topLevelEnrollment && !!row[FormatUtil.getColIndex(headings, 'is_activity')].value;
        const tags = FormatUtil.getColIndex(headings, 'tags') > -1 ? row[FormatUtil.getColIndex(headings, 'tags')].value : null;

        // If no enrollmentId passed up (likely isn't a child/nested item), get it from the row data
        const enrollId = enrollmentId || row[FormatUtil.getColIndex(headings, 'id')].value;
        return (
          <button
            style={{ maxWidth: '400px' }}
            className={`${!preventLaunch ? 'cursor-pointer underline text-black hover:underline hover:text-black hover:font-semibold text-left' : 'text-left'}`}
            onClick={(e) => (!preventLaunch ? this.handleLaunching(e, enrollId, contentId, topLevelEnrollment, isActivity, type) : null)}
          >
            {colArr.join(' ')}{' '}
            <span className="whitespace-nowrap">
              {lastWord}
              {vendor ? (
                <Label className="ml-2 text-xs font-normal leading-none rounded-sm border-xs" color="gray" basic>
                  {vendor}
                </Label>
              ) : null}
              <Label className="ml-2 text-xs font-normal leading-none rounded-sm border-xs" color="gray" basic>
                {formatType}
              </Label>
              {isArchived ? <ArchivedLabel className="ml-2" /> : null}
              {tags && tags.indexOf('Beta') !== -1 ? <BetaLabel className="ml-2" /> : null}
            </span>
          </button>
        );
      };

      formatDate = (col) => {
        return col.value ? moment.utc(col.value).format('M/DD/YYYY') : '-';
      };

      getProgressLabel = (col, row, headings) => {
        return <ProgressLabel showProgressBar progressBarHeight="h-1" progressNum={col.value} completionDate={row[FormatUtil.getColIndex(headings, 'completed_at')].value} />;
      };

      enrollmentExpand = (enrollment, list, listview) => {
        return this.props.enrollmentStore.getEnrollmentDetails(enrollment.enrollment_id, enrollment.content_description_id, list, listview);
      };

      toggleCertModal = (open, cert = null) => {
        this.setState({
          certModalOpen: open,
          selectedCert: cert,
        });
      };

      checkCerts = (data, headings, courseData, buttonFormat) => {
        const certData = this.props.certificateStore.certificates;
        if (!!data && !!headings) {
          const activityIdIdx = FormatUtil.getColIndex(headings, 'content_description_id');
          const activityId = activityIdIdx > -1 ? data[activityIdIdx].value : null;
          const enrollmentIdIdx = FormatUtil.getColIndex(headings, 'id');
          const enrollmentId = enrollmentIdIdx > -1 ? data[enrollmentIdIdx].value : null;
          const completionDateIdx = FormatUtil.getColIndex(headings, 'completed_at');
          const completionDate = completionDateIdx > -1 ? data[completionDateIdx].value : null;
          const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
          const type = typeIdx > -1 ? data[typeIdx].value : null;
          if (!activityId || !enrollmentId || !completionDate || !type) {
            return <Table.Cell key={activityId} />;
          }
          return (
            <Table.Cell key={activityId} className="text-center">
              <CheckAndDisplayCerts activityId={activityId} enrollmentId={enrollmentId} completionDate={completionDate} type={type} certData={certData} />
            </Table.Cell>
          );
        }

        if (courseData) {
          const activityId = `${courseData.content_description_id}`;
          const enrollmentId = `${courseData.id}`;
          const completionDate = courseData.completed_at;
          const type = courseData.content_description_type;

          return (
            <React.Fragment key={activityId}>
              <CheckAndDisplayCerts
                activityId={activityId}
                enrollmentId={enrollmentId}
                completionDate={completionDate}
                type={type}
                certData={certData}
                buttonFormat={buttonFormat}
                certModalOpen={this.state.certModalOpen}
                onClick={this.toggleCertModal}
                fullWidth
              />
            </React.Fragment>
          );
        }
        return null;
      };

      getWorkroleData = (enrollmentId, scoreId) => {
        this.props.enrollmentStore.getWorkroleData(enrollmentId, scoreId);
      };

      getColIndex = (headings, col) => {
        return FormatUtil.getColIndex(headings, col);
      };

      getEnrollmentIdIdx = (parentContentType, headings, col) => {
        return ['iMocha Test', 'Interview Mocha Test'].indexOf(parentContentType) !== -1 ? -1 : FormatUtil.getColIndex(headings, col);
      };

      getScoreIdIdx = (parentContentType, headings, col) => {
        return ['iMocha Test', 'Interview Mocha Test'].indexOf(parentContentType) !== -1 ? FormatUtil.getColIndex(headings, col) : -1;
      };

      getRowValue = (row, idx) => {
        return idx > -1 ? row[idx].value : null;
      };

      getWorkroleIcons = (row, headings, rowIdx, parentContentType) => {
        const { workroleData } = this.props.enrollmentStore;
        // Two different table types run through this method, both with an ID column, but different ID types (enrollment ID vs ScoreId)
        // If the parentContentType is Interview Mocha Test, we have a scoreID, NOT an enrollment ID
        const enrollmentIdIdx = this.getEnrollmentIdIdx(parentContentType, headings, 'id');
        const scoreIdIdx = this.getScoreIdIdx(parentContentType, headings, 'id');
        const enrollmentId = this.getRowValue(row, enrollmentIdIdx);
        const scoreId = this.getRowValue(row, scoreIdIdx);
        const typeIdx = this.getColIndex(headings, 'content_description_type');
        const type = this.getRowValue(row, typeIdx);
        const completedAtIdx = this.getColIndex(headings, 'completed_at');
        const completedAt = this.getRowValue(row, completedAtIdx);
        const collections = ['Curriculum', 'Career Path', 'Collection'];
        const isParentCollection = collections.indexOf(parentContentType) > -1;
        if ((['iMocha Test', 'Interview Mocha Test'].indexOf(type) !== -1 && !!completedAt && !isParentCollection && !!enrollmentId) || !!scoreId) {
          return (
            <WorkroleModal
              key={`workrole-${rowIdx}`}
              scoreId={scoreId}
              enrollmentId={enrollmentId}
              loading={workroleData.loading}
              error={workroleData.error}
              data={workroleData.data}
              getWorkroleData={this.getWorkroleData}
              showLevelUpMsg
            />
          );
        }
        return <Table.Cell key={`workrole-${rowIdx}`} />;
      };

      /**
       * Unenroll from content
       */
      unenroll = (contentDescriptionId, type, tableKey) => {
        const { enrollmentStore, commonStore } = this.props;
        enrollmentStore
          .unenrollFromEnrollment(contentDescriptionId)
          .then(() => {
            commonStore.triggerToast('success', {
              content: `Successfully unenrolled from the ${type}`,
            });
            enrollmentStore.getEnrollmentList(tableKey);
          })
          .catch((error) => {
            Bugsnag.notify(error);
            commonStore.triggerToast('error', {
              errorCode: error,
            });
          });
      };

      switchToNewContentVersion = (oldContentDescriptionID, newContentDescriptionID, newContentType) => {
        return this.props.enrollmentStore
          .unenrollFromEnrollment(oldContentDescriptionID)
          .then(() => {
            this.props.enrollmentStore
              .startEnrollment(newContentDescriptionID, null, newContentType)
              .then(
                action('fetchSuccess', () => {
                  // Refresh user notifications
                  this.props.notificationsStore.getUserNotifications(this.props.userStore);
                  this.getEnrollmentLists();
                  this.props.commonStore.triggerToast('success', {
                    content: `Successfully updated to the newest version`,
                  });
                })
              )
              .catch(
                action('fetchError', (error) => {
                  Bugsnag.notify(error);
                  this.props.commonStore.triggerToast('error', {
                    content: 'Something went wrong. Unable to enroll in new content.',
                  });
                })
              );
          })
          .catch((error) => {
            Bugsnag.notify(error);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Unable to unenroll from old content',
            });
          });
      };

      unenrollConfirm = (contentDescriptionId, type, tableKey) => {
        this.props.commonStore.triggerConfirm({
          content: `Are you sure you want to unenroll? If you click Continue, this item will no longer appear in your enrollments.`,
          cancel: () => this.props.commonStore.resetConfirmState(),
          confirmBtn: 'Continue',
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.unenroll(contentDescriptionId, type, tableKey);
          },
        });
      };

      retake = (contentId, type) => {
        this.props.enrollmentStore
          .startEnrollment(
            contentId,
            {
              exclude_progress_before: 'now',
            },
            type
          )
          .then(
            action('fetchSuccess', (response) => {
              const enrollment = EnrollmentTransformer.transformEnrollmentData([response.enrollment])[0];
              this.launchItem(enrollment, contentId);
            })
          )
          .catch(
            action('fetchError', (error) => {
              Bugsnag.notify(error);
            })
          );
      };

      retakeConfirm = (contentId, type) => {
        this.props.commonStore.triggerConfirm({
          content: `Are you sure you want to retake this item? If you click Continue, you will create a new attempt that has no progress associated with it. Any previous
            completions of this item and certificates of completion (if applicable) will be unaffected.`,
          cancel: () => this.props.commonStore.resetConfirmState(),
          confirmBtn: 'Continue',
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.retake(contentId, type);
          },
        });
      };

      // Determines if we have a section ready to display yet, or if all visible sections are empty
      getAltViewState = (section, status, enrollmentList) => {
        if (status === 'results') {
          return null;
        }

        const allSections = Object.keys(enrollmentList).map((list) => {
          return enrollmentList[list];
        });
        const cybraryAssessmentList = enrollmentList['cybrary-assessments'];
        const assessmentList = enrollmentList.assessments;
        switch (section) {
          case 'all':
            return getListsState(allSections);
          case 'assessments':
          case 'cybrary-assessments':
            // Assessments consist of two sections, so we need to check both
            return getListsState([cybraryAssessmentList, assessmentList]);
          default:
            // If this is scoped to one section, just see if that section has any items or not
            return getListState(enrollmentList[section]);
        }
      };

      getLabsOrTestsExpandingTable = (key, title, certData, status, enrollmentList, openFeedbackModal) => {
        return (
          <ExpandingContentTable
            iaView
            tableKey={key}
            heading={title}
            headingTooltip
            hideFilters
            launchItem={this.handleLaunching}
            hideCurriculaDetails
            data={enrollmentList[key]}
            progressFormat={this.getProgressLabel}
            titleTagFormat={this.formatTitleWithType}
            learningHoursCalc={FormatUtil.convertSecondsToHours}
            omitIfEmpty
            expandFunction={this.enrollmentExpand}
            openFeedbackModal={openFeedbackModal}
            formatDate={this.formatDate}
            checkCerts={this.checkCerts}
            certData={certData}
            unenrollFunction={this.unenrollConfirm}
            retakeFunction={this.retakeConfirm}
            queryParams={queryString.parse(window.location.search)}
            status={status}
            getWorkroleIcons={this.getWorkroleIcons}
          />
        );
      };

      openFeedbackModal = (contentDescriptionId, type, title) => {
        this.props.feedbackStore.getFeedbackForm(type);
        this.props.feedbackStore.setContentDescriptionId(contentDescriptionId);
        this.props.feedbackStore.setContentType(type);
        this.props.feedbackStore.setTitle(title);
        this.props.feedbackStore.openFeedbackModal();
      };

      render() {
        const { section, status } = this.state;
        if (!section) {
          return null;
        }

        if (section === 'goals') {
          return <MyGoals view={status} />;
        }
        const store = this.props.enrollmentStore;
        const { enrollmentList, recentlyComplete } = store;
        const certificates = this.props.certificateStore;
        const { userTeams, isFree } = this.props.userStore;
        const openFeedbackModal = !isFree ? this.openFeedbackModal : null;
        const showContentUpdate = this.props.notificationsStore.contentNotifications['My Enrollments'] && section === 'all';
        const altViewState = this.getAltViewState(section, status, enrollmentList);
        if (altViewState) {
          return (
            <Container size="lg" className="min-h-3/4">
              <If condition={status !== 'results'}>
                <Title title="My Learning" />
              </If>
              <LearningAltView view={altViewState} section={section} status={status} />
            </Container>
          );
        }

        // group assignments by team id
        const showAssignments = section === 'all' || section === 'assignments';
        const [hasAssignments, assignmentGroups] = handleAssignments(enrollmentList, userTeams);

        const certData = {
          loading: certificates.isCertificateLoading,
          error: certificates.certificateError,
          data: certificates.certificateStatuses,
        };

        const queryParams = queryString.parse(window?.location?.search) || {};

        // Combine a bunch of common content table props to reduce duplicates
        const commonContentTableProps = {
          iaView: true,
          headingToolTip: true,
          hideFilters: true,
          launchItem: this.handleLaunching,
          progressFormat: this.getProgressLabel,
          titleTagFormat: this.formatTitleWithType,
          learningHoursCalc: FormatUtil.convertSecondsToHours,
          expandFunction: this.enrollmentExpand,
          omitIfEmpty: true,
          formatDate: this.formatDate,
          checkCerts: this.checkCerts,
          certData,
          getWorkroleIcons: this.getWorkroleIcons,
          queryParams,
          showContentUpdates: true,
          status,
        };
        return (
          <>
            <Container size="lg">
              <If condition={status !== 'results'}>
                <div className="flex flex-wrap items-center px-2 md:px-0">
                  <div className="w-full md:w-2/3">
                    <Title title="My Learning" />
                  </div>
                  <div className="-mt-4 mb-4 w-full md:my-0 md:w-1/3 md:text-right">
                    <AddLink target="_blank" className="text-sm text-black hover:text-cyb-pink-500 underline hover:underline" to={HELP_DESK_LINKS.VENDOR_REPORTING_PROGRESS}>
                      When will my progress update?
                    </AddLink>
                  </div>
                </div>
              </If>
            </Container>
            <Container className="enrollments" size="lg">
              <If condition={showContentUpdate}>
                <div className="my-4">
                  <ContentUpdateBanner
                    notificationId="notification_enrollments-updates"
                    page="My Enrollments"
                    content={
                      'The Cybrary team has updated some of the items you are enrolled in. ' +
                      'Click on the blue flag to see changes. Be sure to check completed items as well for updates!'
                    }
                    cta="Ok, Got it"
                    notificationsCount={this.props.notificationsStore.contentNotifications['My Enrollments']}
                  />
                </div>
              </If>
              <If condition={status === 'completed' && section === 'all'}>
                <RecentlyCompletedActivities
                  loading={recentlyComplete.loading}
                  error={recentlyComplete.error}
                  data={recentlyComplete.data}
                  getWorkroleIcons={this.getWorkroleIcons}
                  formatDate={this.formatDate}
                  formatTitleWithType={this.formatTitleWithType}
                />
              </If>
              <If condition={section === 'all' || section === 'career-paths'}>
                <ExpandingContentTable
                  {...commonContentTableProps}
                  tableKey="career-paths"
                  heading="My Career Paths"
                  hideCurriculaDetails
                  data={enrollmentList['career-paths']}
                  openFeedbackModal={openFeedbackModal}
                  unenrollFunction={this.unenrollConfirm}
                  retakeFunction={this.retakeConfirm}
                  getAllScores={(contentDescriptionId) => this.props.enrollmentStore.getAllScores(contentDescriptionId, { format: 'table' })}
                  switchToNewContentVersion={this.switchToNewContentVersion}
                />
              </If>

              <If condition={section === 'all' || section === 'collections'}>
                <ExpandingContentTable
                  {...commonContentTableProps}
                  tableKey="collections"
                  heading="My Collections"
                  hideCurriculaDetails
                  data={enrollmentList.collections}
                  openFeedbackModal={openFeedbackModal}
                  unenrollFunction={this.unenrollConfirm}
                  retakeFunction={this.retakeConfirm}
                  getAllScores={(contentDescriptionId) => this.props.enrollmentStore.getAllScores(contentDescriptionId, { format: 'table' })}
                  switchToNewContentVersion={this.switchToNewContentVersion}
                />
              </If>

              <If condition={section === 'all' || section === 'courses'}>
                <ExpandingContentTable
                  {...commonContentTableProps}
                  tableKey="courses"
                  heading="My Courses"
                  hideCurriculaDetails
                  data={enrollmentList.courses}
                  unenrollFunction={this.unenrollConfirm}
                  openFeedbackModal={openFeedbackModal}
                  retakeFunction={this.retakeConfirm}
                  switchToNewContentVersion={this.switchToNewContentVersion}
                />
              </If>
              <If condition={section === 'all' || section === 'curriculum'}>
                <ExpandingContentTable
                  {...commonContentTableProps}
                  tableKey="curriculum"
                  heading="My Custom Paths"
                  hideCurriculaDetails
                  data={enrollmentList.curriculum}
                  unenrollFunction={this.unenrollConfirm}
                  openFeedbackModal={openFeedbackModal}
                  retakeFunction={this.retakeConfirm}
                  switchToNewContentVersion={this.switchToNewContentVersion}
                />
              </If>
              <If condition={section === 'all' || section === 'virtual-labs'}>
                {this.getLabsOrTestsExpandingTable('virtual-labs', 'Virtual Labs', certData, status, enrollmentList, openFeedbackModal)}
              </If>
              <If condition={section === 'all' || (section === 'assessments' && status !== 'results')}>
                {this.getLabsOrTestsExpandingTable('cybrary-assessments', 'Capability Assessments', certData, status, enrollmentList, openFeedbackModal)}
                {this.getLabsOrTestsExpandingTable('assessments', 'Third-Party Assessments', certData, status, enrollmentList, openFeedbackModal)}
              </If>
              <If condition={section === 'all' || section === 'practice-tests'}>
                {this.getLabsOrTestsExpandingTable('practice-tests', 'Practice Tests', certData, status, enrollmentList, openFeedbackModal)}
              </If>
              <If condition={section === 'assessments' && status === 'results'}>
                <AssessmentSummary />
              </If>
              <If condition={Boolean(showAssignments && hasAssignments)}>
                {Object.keys(assignmentGroups).map((key) => {
                  const assignmentGroup = assignmentGroups[key];
                  return (
                    <ExpandingContentTable
                      {...commonContentTableProps}
                      key={`assignment_${key}`}
                      tableKey="assignments"
                      heading={
                        <>
                          {assignmentGroup.team.logo_url ? (
                            <img className="inline-block -mt-1 mr-4 w-8 h-8 rounded" src={assignmentGroup.team.logo_url} alt={assignmentGroup.team.name} />
                          ) : (
                            <TeamsIcon filled inverted classes="mr-4 w-8 h-8 rounded -mt-1 bg-black inline-block" id="-my-learning" />
                          )}
                          {assignmentGroup.team.name}
                        </>
                      }
                      omitCurriculaLink
                      data={assignmentGroup}
                      getAllScores={(contentDescriptionId) => this.props.enrollmentStore.getAllScores(contentDescriptionId, { format: 'table' })}
                    />
                  );
                })}
              </If>
              <PostAssessmentSurvey />
              <Modal open={this.state.certModalOpen} toggle={() => this.toggleCertModal(!this.state.certModalOpen)} ariaLabelledBy="cert-title">
                <CertificateModalContent cert={this.state.selectedCert} />
              </Modal>
            </Container>
            <FeedbackModal />
          </>
        );
      }
    }
  )
);

export default withRouter(EnrollmentsDashboard);
