import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Fade } from 'react-awesome-reveal';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import AddLink from '../AddLink/AddLink';
import ShareCertificate from '../../pages/Certificates/ShareCertificate';
import Modal from '../Modal/Modal';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import Icon from '../Icon/Icon';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import If from '../If/If';
import Header from '../Header/Header';
import { useImmersive } from '../../providers/ImmersiveProvider';
import ProgressBar from '../ProgressBar/ProgressBar';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function CertificateImage() {
  return (
    <img
      src="https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/5264b28124e7ec8087d82c771a3f84bb/Cybrary-Cert.png?h=200"
      alt=""
      className="inline-block mx-4 mb-3 w-auto max-h-64"
    />
  );
}

function OverviewCardComponent({ xpEarned, topic, userStore }) {
  const { userProfileStats, xpCurrentLevelProgress } = userStore;
  const { xpProgressPercent, xpNeededToLevelUp } = xpCurrentLevelProgress;

  const { level } = userProfileStats || {};
  return (
    <div className="flex flex-col justify-between p-4 pl-6 mt-2 mb-4 rounded-md border border-black lg:w-120">
      <div className="flex justify-between items-center mb-1">
        <Header as="h2" className="mb-1 font-extrabold">
          Overview
        </Header>
        <Icon name="star" className="p-1.5 -mt-2 -mr-1 w-8 h-8 rounded-full border-2 border-black" />
      </div>
      <div className="flex flex-col items-start">
        <p className="mb-1">
          You earned <span className="font-bold">{xpEarned} XP</span> in <span className="font-bold">{topic}</span>.
        </p>
        <If condition={xpNeededToLevelUp && level}>
          <p>
            You need <span className="font-bold">{xpNeededToLevelUp} XP</span> to reach <span className="font-bold">Level {level + 1}</span>.
          </p>
          <ProgressBar progress={xpProgressPercent} wrapperClassName="w-full" />
        </If>
        <If condition={!xpNeededToLevelUp || !level}>
          <p>Keep learning to earn XP and level up!</p>
        </If>
      </div>
    </div>
  );
}

const OverviewCard = inject('userStore')(observer(OverviewCardComponent));

function ProgramOverviewCardComponent() {
  const immersiveData = useImmersive();

  const { currentProgramEnrollment, isLoadingCurrentProgram, currentProgramError, getCurrentProgramEnrollment } = useCareerPrograms();
  // Find the descendant enrollment that matches the current enrollment id
  const programDescendantEnrollment = useMemo(() => {
    return currentProgramEnrollment?.enrollments?.find((enrollment) => enrollment.id === immersiveData?.enrollment?.id);
  }, [currentProgramEnrollment, immersiveData?.item?.content_description_id]);

  const programXpEarned = currentProgramEnrollment?.experience_points_earned || 0;
  const programXpTotal = currentProgramEnrollment?.experience_points_total || 0;
  const programXpProgressPercent = Math.min(Math.round((programXpEarned / programXpTotal) * 100), 100);

  const programTitle = currentProgramEnrollment?.content_description?.title || 'this program';
  const programPermalink = currentProgramEnrollment?.content_description?.permalink;
  /**
   * Re-fetch the current program if we don't have it yet
   * This ensures we still have what we need for this display
   * when the career programs ctx is reset elsewhere in the app
   */
  useEffect(() => {
    if (!isLoadingCurrentProgram) {
      getCurrentProgramEnrollment();
    }
  }, []);

  if (currentProgramError || !programDescendantEnrollment) {
    return null;
  }

  return (
    <div className="flex flex-col justify-between p-4 pl-6 mt-2 mb-4 rounded-md border border-black lg:w-120">
      <div className="flex justify-between items-center mb-1">
        <AddLink to={`/browse/${programPermalink}`} className="hover:underline" title="View Path">
          <Header as="h2" className="mb-1 font-extrabold">
            Path
          </Header>
        </AddLink>
        <Icon name="path" />
      </div>
      <p className="text-left">
        You are <span className="font-bold">{programXpProgressPercent || 0}%</span> of the way through{' '}
        <span className="font-bold">
          <If condition={programPermalink}>
            <AddLink to={`/browse/${programPermalink}`} className="hover:underline" title="View Path">
              {programTitle}
            </AddLink>
          </If>
          <If condition={!programPermalink}>{programTitle}</If>
        </span>
        .
      </p>
      <ProgressBar progress={programXpProgressPercent} wrapperClassName="w-full" progressClassName="bg-cyb-purple-500" />
    </div>
  );
}

const ProgramOverviewCard = inject('userStore')(observer(ProgramOverviewCardComponent));

const CompleteView = inject('userStore')(
  observer(({ generalFrame, title, contentDescriptionId, type, hasCurriculumItems, gotoNextActivity, gotoFirstIncomplete, userStore, hasCertificate }) => {
    const [certificateModalOpen, setCertificateModalOpen] = useState(false);
    const canClaimCertificate = ['Course', 'Career Path', 'MicroCourse', 'Virtual Lab'].indexOf(type) !== -1;
    const isFreeUser = !userStore?.user?.is_paid;

    const immersiveData = useImmersive();
    const course = immersiveData?.item || immersiveData?.enrollment?.contentDescription;
    const incompleteOutline = immersiveData?.outline?.incompleteOutline;
    const hasIncompleteItems = incompleteOutline?.length > 0;

    const [certificate, setCertificate] = useState({ data: null, error: null, loading: !hasIncompleteItems });

    // Ensure the sidebar is closed when on this page
    useEffect(() => {
      if (immersiveData?.isPrimarySidebarOpen) {
        immersiveData.setIsPrimarySidebarOpen(false);
      }
    }, [immersiveData?.isPrimarySidebarOpen]);

    useEffect(() => {
      async function claimCertificate() {
        // wait for 2s, give some time for the certificate to be created
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        try {
          const certificateClaim = await Agents.certificate.getByContentDescriptionId(contentDescriptionId);
          setCertificate({ data: certificateClaim, error: null, loading: false });
        } catch (err) {
          setCertificate({ data: null, error: err, loading: false });
        }
      }

      // If this course is complete AND it's in a path with other content AND the cert of completion is turned off (via contentful), just go to next activity
      if (!hasIncompleteItems && hasCurriculumItems && !hasCertificate) {
        gotoNextActivity();
      } else if (!hasIncompleteItems && canClaimCertificate) {
        claimCertificate();
      } else {
        setCertificate({ data: null, error: null, loading: false });
      }
    }, []);

    const descendantContentEnrollment = useMemo(
      () =>
        immersiveData?.enrollment?.descendant_enrollments?.find(
          (descendant_enrollment) => descendant_enrollment.content_description_id === immersiveData?.item?.content_description_id
        ),
      [immersiveData?.enrollment?.descendant_enrollments, immersiveData?.item?.content_description_id]
    );

    const xpEarned = useMemo(() => {
      // If course level enrollment, use the xp earned from the enrollment
      if (!immersiveData?.enrollment?.hasCurriculumItems) {
        return immersiveData?.enrollment?.experience_points_earned;
      }
      // If curriculum/collection level enrollment, use the xp earned for the descendant content enrollment given the current item id
      return descendantContentEnrollment?.experience_points_earned || 0;
    }, [immersiveData, descendantContentEnrollment]);

    const termsInfo = immersiveData?.item ? immersiveData?.item?.contentDescription?.terms_info : immersiveData?.enrollment?.contentDescription?.terms_info;
    const topic = termsInfo?.find((terms_info) => terms_info.startsWith('Topics|'))?.split('|')[1];

    if (certificate.loading) {
      return (
        <div className="flex justify-center items-center w-full">
          <Loading className="mt-24 mb-4 w-10 h-10 border-4" message="Loading..." />
        </div>
      );
    }

    return (
      <>
        <div className={`immersive-complete-frame ${generalFrame}`}>
          <div className="overflow-y-scroll w-full h-full scrollbar">
            <div className="flex flex-col items-center px-5 pb-24 mx-auto max-w-5xl text-center">
              <If condition={hasIncompleteItems}>
                <IncompleteCourse gotoFirstIncomplete={gotoFirstIncomplete} gotoNextActivity={gotoNextActivity} hasCurriculumItems={hasCurriculumItems} isFreeUser={isFreeUser} />
              </If>
              <If condition={!hasIncompleteItems}>
                <Fade triggerOnce cascade damping={0.2}>
                  <Header as="h2" className="my-4 font-black">
                    Congratulations!
                  </Header>
                  {/** Sub header */}
                  <If condition={course?.title}>
                    <p className="pb-4 text-lg">
                      You just completed <span className="text-xl font-bold">{course.title}</span>.
                    </p>
                  </If>
                  <If condition={!course?.title}>
                    <p className="pb-4 text-lg">You just completed this course!</p>
                  </If>
                  <If condition={immersiveData?.nextItem?.content_description?.title}>
                    <AddLink onClick={() => gotoNextActivity()} className="group mb-4 text-lg" title="Continue Learning">
                      Keep going! Your next activity is <span className="text-xl font-bold group-hover:underline">{immersiveData?.nextItem?.content_description?.title}</span>.
                    </AddLink>
                  </If>

                  {/** Overview Card */}
                  <div className="justify-between mb-4 w-full lg:flex lg:space-x-2">
                    <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplayNull}>
                      <OverviewCard xpEarned={xpEarned} topic={topic} />
                    </ErrorBoundary>
                    <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplayNull}>
                      <ProgramOverviewCard />
                    </ErrorBoundary>
                  </div>

                  {/** Certificate Image */}
                  <If condition={!hasCertificate}>
                    <img
                      className="py-4 m-auto max-h-72"
                      alt=""
                      src="https://images.ctfassets.net/kvf8rpi09wgk/3Ls37Ke5KPtU7N5x9DAdE1/dd1889d8e40806c8b4708de2fd503721/Programming_Cybrary.svg"
                    />
                  </If>
                  <If condition={hasCertificate}>
                    <p className="mb-6 text-lg">Your Certificate of Completion is available below.</p>
                    <CompleteCertificate
                      title={title}
                      certificate={certificate}
                      hasCurriculumItems={hasCurriculumItems}
                      gotoNextActivity={gotoNextActivity}
                      openShareModal={() => setCertificateModalOpen(!certificateModalOpen)}
                      canClaimCertificate={canClaimCertificate}
                      isFreeUser={isFreeUser}
                    />
                    <Modal size="sm" open={certificateModalOpen} toggle={() => setCertificateModalOpen(!certificateModalOpen)} ariaLabelledBy="share-certificate-modal">
                      <div className="flex flex-col items-center p-8 w-full">
                        <Header as="h2" className="mb-6 font-black">
                          Share Your Certificate
                        </Header>
                        <Header as="h2" className="mb-6 font-black">
                          {title}
                        </Header>
                        <CertificateImage />
                        <If condition={certificate?.data?.certificate_id}>
                          <p className="mb-1 text-gray-600">Certificate ID: {certificate?.data?.certificate_id}</p>
                        </If>

                        <If condition={!certificate?.data}>
                          <ShareCertificate certificate={certificate?.data} />
                        </If>
                      </div>
                    </Modal>
                  </If>
                </Fade>
              </If>
              <ContinueLearningButton gotoNextActivity={gotoNextActivity} hasCurriculumItems={hasCurriculumItems} />
            </div>
          </div>
        </div>
        {isFreeUser && <UpgradeBanner />}
      </>
    );
  })
);

function ContinueLearningButton({ gotoNextActivity, hasCurriculumItems, ctaButtonColor = 'pink', omitHeader = false }) {
  if (hasCurriculumItems) {
    return (
      <div className="flex flex-col items-center">
        <If condition={!omitHeader}>
          <p>Ready to keep going? Let&apos;s hone your skills.</p>
        </If>
        <div className="flex justify-evenly items-center space-x-2 w-full">
          <Button color="gray" href="/" className="mt-2 mb-8" icon={{ name: 'chevron-left', position: 'left' }}>
            Back to Dashboard
          </Button>
          <Button onClick={() => gotoNextActivity()} className="mt-2 mb-8" icon={{ name: 'chevron-right', position: 'right' }}>
            Continue Learning
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center">
      <If condition={!omitHeader}>
        <p>Want to hone your skills? Here&apos;s what we recommend next.</p>
      </If>
      <div className="flex justify-evenly items-center space-x-2 w-full">
        <Button color="gray" href="/" className="mt-2 mb-8" icon={{ name: 'chevron-left', position: 'left' }}>
          Back to Dashboard
        </Button>
        <Button href="/recommendations" color={ctaButtonColor} className="mt-2 mb-8" icon={{ name: 'arrow-right', position: 'right' }}>
          Explore Recommended Content
        </Button>
      </div>
    </div>
  );
}

function IncompleteCourse({ gotoFirstIncomplete, isFreeUser }) {
  const ctaButtonColor = isFreeUser ? 'gray' : 'pink';

  return (
    <>
      <Header as="h1" className="my-6 text-4xl font-black text-black sm:text-6xl">
        You&apos;re Not Done Yet
      </Header>
      <Header as="h2" className="mb-6 font-black text-center">
        You&apos;ve reached the end of this course, but still have some open activities.
      </Header>
      <p className="mb-6 text-lg font-bold text-black">You must complete all activities to unlock your Certificate of Completion.</p>
      <Button onClick={() => gotoFirstIncomplete()} color={ctaButtonColor} className="mb-6">
        Complete Activities
      </Button>
    </>
  );
}

function UpgradeBanner() {
  // animate in/out by
  // 1) adding animationClasses to parent component
  const animationClasses = `transition-all duration-1000 ease-in-out`;

  // 2) change height class to animate
  const [cssHeight, setCSSHeight] = useState('h-0');
  const showBanner = (show) => setCSSHeight(show ? 'h-1/4' : 'h-0');

  // 3) start hidden, and set to full height on mount to animate on load
  useEffect(() => showBanner(true), []);

  return (
    <div className={`flex fixed bottom-0 z-50 w-full ${cssHeight} text-white bg-black ${animationClasses}`}>
      <div className="flex justify-center items-center p-4 pb-0 sm:w-1/4">
        <img
          className="max-h-36"
          alt="A programmer learning through Cybrary"
          src="https://images.ctfassets.net/kvf8rpi09wgk/3Ls37Ke5KPtU7N5x9DAdE1/dd1889d8e40806c8b4708de2fd503721/Programming_Cybrary.svg"
        />
      </div>
      <div className="flex flex-col justify-evenly items-center py-2 w-full sm:w-3/4">
        <div className="my-2 w-full text-2xl font-bold text-center">Keep Skilling Up</div>
        <div className="overflow-hidden px-2 m-auto w-full max-w-3xl text-base sm:text-sm">
          Upgrade to Cybrary Insider Pro for unlimited access to professional development courses, certification preparation, hands-on virtual labs, and assessment -- PLUS access
          to guided career paths and a global cybersecurity community.
        </div>
        <div className="my-2 w-full text-center">
          <UpgradeButton color="pink" upgradeText="Upgrade" />
        </div>
      </div>
      <button className="absolute top-2 right-4 p-2 cursor-pointer" title="Dismiss Upgrade Banner" onClick={() => showBanner(false)}>
        <Icon className="w-6 h-6 text-white" name="x" />
      </button>
    </div>
  );
}

const CompleteCertificate = inject('userNotificationStore')(
  observer(({ userNotificationStore, title, certificate, openShareModal, canClaimCertificate }) => {
    const certDate = certificate.data ? certificate.data.issued_date || certificate.data.issued_at || certificate.data.completed_at : null;
    const completedDate = certDate ? moment(certDate.substring(0, 10)).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
    const certificateNotFound = !(certificate?.data && certificate?.data?.title);
    const downloadUrl = certificate?.data?.certificate_id ? FormatUtil.formatCertificateDownloadUrl(certificate?.data?.certificate_id) : null;

    const { isOpen } = useBadgeModal();

    useEffect(() => {
      // Check for unread notifications on activity load
      // This allows us to show badges as we earn them in immersive
      // IMPORTANT Stop checking for unread notifications when the badge modal is open.
      userNotificationStore.checkForUnreadOnInterval(isOpen, { maxAttempts: 10, delay: 5000 });
    }, []);

    return (
      <>
        <div className="flex justify-center items-center mb-4 border-b-xs border-gray-300">
          <div>
            <p className="mb-1 text-lg font-bold text-black">{certificate?.data?.title || title}</p>
            <If condition={canClaimCertificate}>
              <AddLink onClick={openShareModal}>
                <CertificateImage />
              </AddLink>
            </If>
            <If condition={certificateNotFound}>
              <p className="mb-1 text-center text-gray-600">Completed {completedDate}</p>
            </If>
            <If condition={!certificateNotFound}>
              <div className="text-center text-gray-600">
                <p className="mb-1 text-gray-600">Completed {completedDate}</p>
                <If condition={certificate?.data?.certificate_id}>
                  <p className="text-gray-600">Certificate ID: {certificate?.data?.certificate_id}</p>
                </If>
              </div>
              <div className="flex justify-center items-center pb-6 mt-10 space-x-2 w-full">
                <Button color="transparent-gray" onClick={openShareModal} className="mb-2" icon={{ name: 'share', position: 'left' }}>
                  Share Certificate
                </Button>
                <Button color="transparent-gray" target="_blank" href={downloadUrl} className="mb-2" icon={{ name: 'download', position: 'left' }}>
                  Download Certificate
                </Button>
              </div>
            </If>
          </div>
        </div>

        {certificateNotFound && <p className="mt-6 mb-1 text-center text-cyb-blue-500">We will send you a notification with the link to your certificate shortly.</p>}
      </>
    );
  })
);

export default CompleteView;
