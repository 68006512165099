import React, { useMemo } from 'react';
import moment from 'moment';
import Icon from '../../Icon/Icon';
import StyleUtil from '../../../utils/styleUtil';

/**
 * Render a custom 'trending' column for a table. Mainly used in `<AdminTable />` component.
 *
 * @see [BaselineTeamsOverviewTable](src/components/Baseline/modules/BaselineTeamsOverviewTable.js) `renderColumn` func for usage.
 *
 * @param {*} fullRow - all table rows
 * @param {*} headings - all table headings
 * @param {*} key - column key
 * @returns <Icon /> or `null`
 */
export const renderTrendingColumn = (fullRow, headings, key) => {
  const columnIndex = headings.findIndex((heading) => heading.key === key);

  if (columnIndex >= 0) {
    const trendValue = fullRow[columnIndex]?.value;

    if (Number(trendValue) > 0) {
      return <Icon name="trending-up" className="shrink-0 w-7 h-7 fill-green-600" />;
    }
    if (Number(trendValue) < 0) {
      return <Icon name="trending-down" className="shrink-0 w-7 h-7 fill-red-500" />;
    }
    return <Icon name="trending-constant" className="shrink-0 w-6 h-6 fill-slate-400" />;
  }

  return null;
};

/**
 * Render a custom 'community score' column for a table. Mainly used in `<AdminTable />` component.
 *
 * @see [BaselineTeamsOverviewTable](src/components/Baseline/modules/BaselineTeamsOverviewTable.js) `renderColumn` func for usage.
 *
 * @param {*} fullRow - all table rows
 * @param {*} headings - all table headings
 * @param {*} key - column key
 * @returns <Icon /> or `null`
 */
export const renderCommunityScoreColumn = (row, fullRow, headings, percentage, key) => {
  const columnIndex = headings.findIndex((heading) => heading.key === key);

  if (columnIndex >= 0) {
    const companyScore = fullRow[columnIndex]?.value;

    if (companyScore < row.value) {
      return (
        <div className="flex flex-row items-center">
          <span className="mr-2">{percentage}%</span>
          <Icon name="exclamation-circle" className="shrink-0 mr-2 w-6 h-6 fill-yellow-400" />
        </div>
      );
    }
  }

  return `${percentage}%`;
};

/**
 * Callback passed into `formatColumns` prop of `<AdminTable />` component
 *
 * @param {*} columns - table columns
 * @param {*} targetColumns - target columns to be custom rendered
 * @param {*} renderColumn - callback to return custom rendered columns
 *
 * @returns array of formatted columns
 */
export const formatTableColumns = (columns, targetColumns, renderColumn) => {
  const cols = [];

  const targetIndexes =
    columns
      ?.map((column, index) => {
        if (targetColumns.includes(column.key)) {
          return index;
        }
        return null;
      })
      .filter((value) => value !== null) || [];

  targetIndexes.forEach((targetIndex) => {
    const entry = {
      method: (row, fullRow, headings) => renderColumn(row, fullRow, headings, headings[targetIndex], targetIndex),
      colIdx: targetIndex,
    };

    cols.push(entry);
  });

  return cols;
};

/**
 * Transform assessment track/path data to compliant format for table data
 *
 * @param {Track} track
 * @returns {tableData} { columns: [], tableData: [][] }
 */
export const generateTableData = (track) => {
  const tableData = track.children.map((item, index) => [
    {
      value: item['content-id'],
      type: 'string',
    },
    {
      value: item['content-topic'],
      type: 'string',
    },
    {
      value: item.score,
      type: 'string',
    },
    {
      value: item['community-score'],
      type: 'string',
    },
    {
      value: index,
      type: 'string',
    },
  ]);
  return {
    columns: [
      {
        key: 'assessment_id',
        type: 'string',
        showCol: false,
        sortKey: 'course_id',
      },
      {
        key: 'name',
        type: 'string',
        showCol: true,
        display: 'Name',
        sortKey: 'name',
      },
      {
        key: 'user_score',
        type: 'string',
        showCol: true,
        display: 'Your Score',
        sortKey: 'user_score',
        tooltip: 'Click your score to view your personalized recommendations and improve your score!',
      },
      {
        key: 'community_score',
        type: 'string',
        showCol: true,
        display: 'Community Score',
        sortKey: 'community_score',
        tooltip: 'The average score of all completed Topic Assessments within the Assessment Path by all Cybrary users',
      },
      {
        key: 'user_action',
        type: 'string',
        showCol: true,
        display: '',
        sortKey: 'user_action',
      },
    ],
    tableData,
  };
};

/**
 * Custom `tooltip` component for `ResponsiveLine` chart component
 * @param {props} props
 * @returns
 */
export function ChartTooltip(props) {
  const {
    point: { id, data, serieId, serieColor },
  } = props;

  if (data.marker) {
    return null;
  }

  let title = serieId;
  let backgroundColor = serieColor;
  let opacity = 1;

  if (serieId.includes('_Low_Attempts')) {
    [title] = serieId.split('_Low_Attempts');
  }

  if (data.scatteredPointColor) {
    backgroundColor = data.scatteredPointColor;
    opacity = 0.7;
  }

  return (
    <div
      key={id}
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '5px 9px',
      }}
    >
      <div className="flex items-center whitespace-pre">
        <span className="block mr-2 w-3 h-3 rounded-full" style={{ backgroundColor, opacity }} />
        <span>
          <strong>{title}</strong>
        </span>
      </div>
      <div className="flex items-center mt-1 whitespace-pre">
        <span className="mr-3">
          date: <strong>{data.xFormatted}</strong>
        </span>
        <span>
          score: <strong>{data.y}%</strong>
        </span>
      </div>
    </div>
  );
}

/**
 * Custom `sliceTooltip` component for `ResponsiveLine` chart component
 * @param {sliceProps} props
 * @returns
 */
export function ChartSliceTooltip(props) {
  const {
    slice: { points },
  } = props;

  const sortedPoints = useMemo(() => points.sort((a, b) => b.data.y - a.data.y), [points]);

  return (
    <div
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '5px 9px',
      }}
    >
      <div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {sortedPoints.map((point) => (
              <tr key={`${point.id}${point.index}`}>
                <td>
                  <span
                    className="block w-4 h-4 rounded-full"
                    style={{
                      background: point.serieColor,
                    }}
                  />
                </td>
                <td className="py-1 px-2">{point.serieId}</td>
                <td className="py-1 px-2">
                  <span className="font-bold">{point.data.y}%</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

/**
 * Custom `pointSymbol` component for `ResponsiveLine` chart component
 * @param {props} props
 * @returns
 */
export function ChartPointSymbol(props) {
  const { datum, borderColor, size } = props;

  if (datum.marker) {
    return null;
  }

  const fill = datum.scatteredPointColor ? datum.scatteredPointColor : '#FFFFFF';
  const stroke = datum.scatteredPointColor ? datum.scatteredPointColor : borderColor;
  const circleSize = datum.scattered ? size / 2.5 : size / 2;
  const mixBlendMode = datum.scattered ? 'multiply' : 'normal';

  return (
    <g style={{ pointerEvents: 'none' }}>
      <circle r={circleSize} fill={fill} stroke={stroke} strokeWidth={2} style={{ pointerEvents: 'none', mixBlendMode, opacity: datum.scattered ? 0.7 : 1 }} />
    </g>
  );
}

export const graphColorDefaults = StyleUtil.getGraphColorDefaults();

export const getAssessmentPathDisplayName = (str) => {
  const splitName = str.split('-');

  return splitName[1]?.trim() ?? str;
};

export function CustomLineLayer({ series, lineGenerator, xScale, yScale }) {
  return series.map(({ id, data, color }) => {
    let style = {
      strokeWidth: 2,
    };

    if (id.includes('_Low_Attempts') || data.every((entry) => entry.data.marker)) {
      style = {
        strokeWidth: 0,
      };
    }

    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={style}
      />
    );
  });
}

const generateTopicScoreTimeline = (topic, isDayInterval) => {
  const dates = [];
  const currentDate = moment();
  const timespan = moment().subtract(13, 'month');
  const predicate = isDayInterval ? 'day' : 'month';

  while (currentDate.isSameOrAfter(timespan, predicate)) {
    dates.push({
      completed_at: currentDate.set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'),
      content_description_id: topic['content-id'],
      title: topic['content-name'],
      score: null,
      stub: true,
    });
    // move to the previous day or month
    currentDate.subtract(1, predicate);
  }

  return dates;
};

export const generateTemplateCumulativeScores = (currentRole, isDayInterval = false) => {
  const dataSet = [];

  currentRole?.children?.forEach((topic) => {
    const topicScoreTimeline = generateTopicScoreTimeline(topic, isDayInterval);

    dataSet.push([topic['content-name'], topicScoreTimeline]);
  });

  return dataSet;
};
