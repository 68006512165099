import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import useQueryParams from '../../../hooks/useQueryParams';
import Dropdown from '../../Dropdown/Dropdown';
import LoadingText from '../../Loading/LoadingText';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';
import If from '../../If/If';

function AssessmentSummaryHeader({ userId, orgId }) {
  const { currentTrack, currentAssessment, actions, assessmentAttempts, isLoadingAssessmentAttempts } = useCybAssessmentAnalytics();

  const { fromImmersive, enrollmentId: defaultEnrollmentId, targetScore } = useQueryParams();

  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(null);

  const attempts = useMemo(() => {
    const baseKey = `${currentTrack['content-id']}_${currentAssessment['content-id']}`;
    const userKey = `${userId}_${baseKey}`;
    const adminKey = `${orgId}_${userKey}`;

    return assessmentAttempts[userKey] ?? assessmentAttempts[adminKey];
  }, [orgId, currentTrack, currentAssessment, assessmentAttempts]);

  const dropdownOptions = useMemo(
    () =>
      Object.entries(attempts ?? {})
        .map(([enrollmentId, attempt]) => {
          const score = Math.round(parseFloat(attempt.score * 100));
          const date = moment(attempt['submitted-at']).format('MMMM D, YYYY h:mm A');

          return {
            id: enrollmentId,
            key: enrollmentId,
            text: `${date} | ${score}%`,
            value: enrollmentId,
            score,
            date,
            'submitted-at': attempt['submitted-at'],
          };
        })
        .reverse(),
    [attempts]
  );

  const renderOptionComponent = useCallback(
    (props) => {
      const assessment = dropdownOptions?.find((item) => item.id === props.data.value);

      if (!assessment) {
        return null;
      }

      return (
        <div className="flex flex-row justify-center items-center">
          <span className="text-sm">{assessment.date}</span>
          <span className="mx-2">|</span>
          <span className="text-sm font-semibold">{assessment.score}%</span>
        </div>
      );
    },
    [dropdownOptions]
  );

  const onChangeHandler = useCallback(
    ({ value }) => {
      setSelectedEnrollmentId(value);

      const attempt = attempts[value];

      if (attempt) {
        actions.setCurrentAssessmentAttempt(currentTrack['content-id'], userId, attempt);
      }
    },
    [attempts, currentTrack, userId]
  );

  useEffect(() => {
    if (!selectedEnrollmentId && dropdownOptions.length > 0) {
      if (fromImmersive) {
        // we want to display the most recent attempt
        onChangeHandler({ value: dropdownOptions[0].id });
      } else if (defaultEnrollmentId) {
        onChangeHandler({ value: defaultEnrollmentId });
      } else {
        const submissionMatch = dropdownOptions.find((option) => option['submitted-at'] === currentAssessment['submitted-at']);
        const targetScoreMatch = dropdownOptions.find((option) => option.score === parseInt(targetScore ?? '-1', 10));

        if (targetScoreMatch) {
          onChangeHandler({ value: targetScoreMatch.id });
        } else if (submissionMatch) {
          setSelectedEnrollmentId(submissionMatch.id);
        }
      }
    }
  }, [targetScore, fromImmersive, dropdownOptions, selectedEnrollmentId, currentAssessment]);

  return (
    <div className="flex flex-col justify-between items-center p-4 mb-4 w-full rounded-sm border border-neutral-600 md:flex-row">
      <p className="mb-4 text-2xl font-bold md:mb-0">Results: {currentAssessment['content-topic']}</p>
      <div className="flex flex-row items-center">
        <span className="mr-2 font-bold">Attempts:</span>
        <div className="flex flex-col justify-center items-center">
          <If condition={isLoadingAssessmentAttempts}>
            <LoadingText wrapperClassName="w-64 h-10 p-0" className="h-10 rounded" />
          </If>
          <If condition={!isLoadingAssessmentAttempts}>
            <Dropdown
              ariaLabel="View assessment history"
              options={dropdownOptions}
              classes="text-xs sm:my-0 rounded z-20 w-64 h-10"
              value={selectedEnrollmentId}
              onChange={onChangeHandler}
              renderOptionComponent={renderOptionComponent}
              hideSelectedOptions={false}
              isCompact
            />
          </If>
        </div>
      </div>
    </div>
  );
}

export default memo(AssessmentSummaryHeader);
