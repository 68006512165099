import React, { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import GuacConnection from '../../services/GuacConnection';

// This is the terminal container used in the NEW CLAB, the old one can be removed once we have fully shut it down.

// We are going to create some custom events to control the remote copy/paste process
const subscribeToRemoteCopy = (listener) => {
  window.addEventListener('copyToRemote', listener);
};
const unsubscribeFromRemoteCopy = (listener) => {
  window.addEventListener('copyToRemote', listener);
};

const guac = {};

function Terminal({ resource, index, totalIps, errorHandler, isActive = false, token = null }) {
  // Hide the terminal on load to resize it
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);
  const terminalRef = useRef(null);
  const keyboardRef = useRef(null);
  const { id, guacParams, activeTab } = resource;

  useEffect(() => {
    if (token && resource?.guacUrl) {
      guac[index] = new GuacConnection(`${resource.guacUrl.replace('http', 'ws')}`, {});
    }
  }, [token, resource]);

  useEffect(() => {
    // This useEffect is where guac connections are managed
    const display = guac[index]?.client?.getDisplay();
    const hasTerminalElement = terminalRef?.current;
    const containerElement = containerRef?.current;
    if (display && hasTerminalElement && id && containerElement && token) {
      const width = Math.round(containerElement.clientWidth);
      const height = Math.round(containerElement.clientHeight);
      guac[index].connectToNewClab(guacParams, width, height, id, errorHandler);
      terminalRef.current.appendChild(display.getElement());

      guac[index].startKeyboardListener();
      guac[index].startMouseListener();
    }
    return () => {
      return guac[index]?.client?.disconnect();
    };
  }, [id, guacParams, token]);

  const remoteCopyListener = (e) => {
    const { detail } = e || {};
    const { str } = detail || {};
    if (index && str && guac && guac[index]) {
      guac[index].sendToClipboard(str);
    }
  };

  /**
   * Resize onload and when more terminals start
   */
  useEffect(() => {
    const containerElement = containerRef?.current;
    if (containerElement) {
      setTimeout(() => {
        guac[index].resizeTerminal(containerElement);
        setShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      window.addEventListener(
        'resize',
        throttle(() => guac[index].resizeTerminal(containerRef.current), 3000, { leading: false, trailing: true })
      );
    }
    return () => window.removeEventListener('resize', guac[index].resizeTerminal);
  }, []);

  // Resize when the actual element changes size, throttle should prevent this from occuring more than once on window resize
  useEffect(() => {
    if (!terminalRef?.current) {
      return () => null;
    }
    const observer = new ResizeObserver(() => {
      throttle(() => guac[index].resizeTerminal(containerRef.current), 500, { leading: false, trailing: true })();
    });

    observer.observe(terminalRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isActive) {
      subscribeToRemoteCopy(remoteCopyListener);
      return () => unsubscribeFromRemoteCopy(remoteCopyListener);
    }
    return () => null;
  }, [isActive]);

  return (
    <div className={`absolute top-0 left-0 h-full w-full ${activeTab || totalIps === 1 ? '' : 'hidden'}`}>
      <div
        className="relative w-full h-full min-h-[500px] md:min-h-[calc(100vh-64px)] md:max-h-[calc(100vh-64px)] clab-container"
        ref={containerRef}
        onClick={() => keyboardRef.current?.focus()}
        role="application"
      >
        <input id={id} ref={keyboardRef} value="" onChange={() => {}} className="absolute top-10 left-10 bg-gray-800 border-0  outline-none focus:outline-none" />
        <div className={`relative z-50 h-full w-full ${id && show ? 'visible' : 'invisible'}`} ref={terminalRef} />
      </div>
    </div>
  );
}

export default function TerminalContainer({ resources, errorHandler, activeResource = null, token = null }) {
  const connectedResource = resources.find((resource) => resource.connected);
  const hasConnected = !!connectedResource;

  return (
    <>
      {!hasConnected ? <p className="flex justify-center items-center h-full text-white">Connect to a resource to get started!</p> : null}

      {hasConnected && (
        <Terminal
          key={connectedResource.id}
          resource={connectedResource}
          index={connectedResource.id}
          totalIps={1}
          errorHandler={errorHandler}
          isActive={!!activeResource && connectedResource.id === activeResource}
          token={token}
        />
      )}
    </>
  );
}
