import Bugsnag from '@bugsnag/js';
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import Agents from '../agents/agents';
import { getExcludeProgressBefore, normalizeActivity, checkIfItemActivity, normalizeItem } from '../utils/immersiveUtil';
import FormatUtil from '../utils/formatUtil';
import BugsnagUtil from '../utils/bugsnagUtil';

const ImmersiveContext = createContext();

/**
 * Basic provider to make legacy tasks in immersive have state
 * that is elevated (and accessible) beyond the local challenge component.
 */
function ImmersiveProvider({ children }) {
  const [loadingEnrollment, setLoadingEnrollment] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [enrollment, setEnrollment] = useState(null);
  const [completedIds, setCompletedIds] = useState([]);
  const [startedIds, setStartedIds] = useState([]);
  const [item, setItem] = useState(null);
  const [activity, setActivity] = useState(null);
  const [outline, setOutline] = useState(null);
  const [menu, setMenu] = useState(null);
  const [includeA11yPause, setIncludeA11yPause] = useState(false);
  const [a11yPaused, setA11yPaused] = useState(false);
  const [isPrimarySidebarOpen, setIsPrimarySidebarOpen] = useState(false);
  const navigate = useNavigate();

  /**
   * Reset our state to their defaults, called when leaving the immersive.
   */
  const reset = () => {
    setLoadingEnrollment(false);
    setLoadingItem(false);
    setLoadingActivity(false);
    setEnrollment(null);
    setCompletedIds([]);
    setStartedIds([]);
    setItem(null);
    setActivity(null);
    setOutline(null);
    setMenu(null);
    setIncludeA11yPause(false);
    setA11yPaused(false);
  };

  const leaveImmersiveWithError = (errCode) => {
    const param = errCode ? `?immersiveError=${errCode}` : '';
    // Fire bugsnag event to track why we are leaving immersive if it is due to an error
    if (errCode) {
      Bugsnag.notify(new Error(errCode));
    }
    navigate(`/${param}`, { replace: true });
  };

  /**
   * Find the first incomplete item id, returning the first item id if all are complete.  Returns null if no enrollment.
   * @returns {int|null} The curriculum item id, or null if there's no enrollment.
   */
  const findFirstIncompleteItemId = () => {
    if (!enrollment) {
      return null;
    }
    const { content } = enrollment;
    const { curriculum_items: curriculumItems } = content;
    // Variable to store the first id, in case we don't have any incomplete item ids, we just return the start (first item)
    let firstId = null;
    if (curriculumItems?.length) {
      for (let i = 0; i < curriculumItems.length; i++) {
        const { content_description: contentDescription } = curriculumItems[i];
        const { id } = contentDescription;
        if (i === 0) {
          firstId = id;
        }
        if (completedIds.indexOf(id) === -1) {
          return id;
        }
      }
    }
    return firstId;
  };

  /**
   * Find the first incomplete activity id from a set of learning modules, returning the first activity id if all are complete.
   * @param {array|null} $learningModules - Learning modules array, contains activities.
   * @param {int|null} excludedId - The optional id of the activity we will exclude if found to be incomplete.
   * @returns {int|null} The activity id, or null if there's no learning modules.
   */
  const findFirstIncompleteActivityIdFromModules = (learningModules, excludedId = null) => {
    // Variable to store the first id, in case we don't have any incomplete activity ids, we just return the start (first activity)
    let firstId = null;
    if (learningModules?.length) {
      for (let i = 0; i < learningModules.length; i++) {
        const { activities } = learningModules[i];
        for (let k = 0; k < activities.length; k++) {
          const { id } = activities[k];
          if (excludedId !== id && k === 0 && i === 0) {
            firstId = id;
          }
          if (excludedId !== id && completedIds.indexOf(id) === -1) {
            return id;
          }
        }
      }
    }
    return firstId;
  };

  /**
   * Return the learning modules array from the enrollment, or the item (if an item is present)
   * @returns The learning modules array, or null.
   */
  const getLearningModules = () => {
    if (item?.learning_modules) {
      return item.learning_modules;
    }
    return enrollment?.content?.learning_modules || null;
  };

  /**
   * Find the first incomplete activity id, returning the first activity id if all are complete.  Returns null if no enrollment.
   * @param {int|null} excludedId - The optional id of the activity we will exclude if found to be incomplete.
   * @returns {int|null} The activity id, or null if there's no enrollment.
   */
  const findFirstIncompleteActivityId = (excludedId = null) => {
    if (!enrollment) {
      return null;
    }
    const learningModules = getLearningModules();
    return findFirstIncompleteActivityIdFromModules(learningModules, excludedId);
  };

  /**
   * Find the first incomplete activity id, returning the first activity id if all are complete.  Returns null if no item.
   * @returns {int|null} The activity id, or null if there's no item.
   */
  const findFirstIncompleteActivityIdFromItem = () => {
    if (!item) {
      return null;
    }
    const { learning_modules: learningModules } = item;
    return findFirstIncompleteActivityIdFromModules(learningModules);
  };

  /**
   * Find the activity inside of an enrollment, assuming it exists.
   * @param {int} id - The id of the activity we are looking for.
   * @returns {object|null} - The activity, if we found it.
   */
  const findActivityInEnrollment = (id) => {
    if (!enrollment) {
      return null;
    }
    const learningModules = getLearningModules();
    for (let i = 0; i < learningModules.length; i++) {
      const mod = learningModules[i];
      const { activities } = mod;
      for (let k = 0; k < activities.length; k++) {
        if (activities[k].id === id) {
          return activities[k];
        }
      }
    }
    return null;
  };

  /**
   * Find the module for a particular activity
   * @param {int} activityId - ID of the activity we are trying to find the parent module of.
   * @returns int|null - The module id, if we have one.
   */
  const findModuleId = (activityId) => {
    if (!activityId || !enrollment) {
      return null;
    }

    const modules = getLearningModules();
    if (!modules) {
      return null;
    }
    for (let i = 0; i < modules.length; i++) {
      const { activities } = modules[i];
      for (let k = 0; k < activities.length; k++) {
        if (activities[k].id === activityId) {
          return modules[i].id;
        }
      }
    }
    return null;
  };

  /**
   * Find an item within our enrollment.
   * @param {int} id - The id of the item we are attempting to find.
   * @returns {object|null} The found item object within the enrollment, or null.
   */
  const findItem = (id) => {
    if (!enrollment?.hasCurriculumItems) {
      return null;
    }
    const { content } = enrollment;
    const { curriculum_items: curriculumItems } = content;
    for (let i = 0; i < curriculumItems.length; i++) {
      const contentDescriptionId = curriculumItems[i].content_description.id;
      if (id === contentDescriptionId) {
        return curriculumItems[i];
      }
    }
    return null;
  };

  /**
   * Build the appropriate URL for optional activity and item ids, based on the current enrollment information.
   * @param {int|undefined} providedActivityId - The activity id that we are providing when attempting to create a url
   * @param {int|undefined} providedItemId - The item id that we are providing when attempting to create a url
   * @returns string|null
   */
  const createUrl = (providedActivityId, providedItemId) => {
    if (!enrollment) {
      return null;
    }

    const { id: enrollmentId, is_activity: isActivity } = enrollment;
    const itemToUse = providedItemId && providedItemId !== 'enrollment' ? findItem(providedItemId) : item;
    // Construct the item portion of the URL if we have an item
    const itemPortionOfUrl = item ? `/item/${itemToUse.content_description.id}` : '';
    // If the item is an item activity (not a course), use the keyword enrollment instead of the id
    if (itemToUse && checkIfItemActivity(itemToUse, providedItemId)) {
      return `/immersive/${enrollmentId}/item/enrollment/activity/${providedActivityId || providedItemId}`;
    }

    // This is an activity enrollment, it has a special URL structure.
    if (isActivity) {
      return `/immersive/enrollment/activity/${enrollmentId}`;
    }

    if (!activity?.id && !providedActivityId) {
      // We don't have an activity yet, just return the enrollment/item url
      return `/immersive/${enrollmentId}${itemPortionOfUrl}`;
    }
    return `/immersive/${enrollmentId}${itemPortionOfUrl}/activity/${providedActivityId || activity?.id}`;
  };

  /**
   * Find the previous curriculum item from the provided id, if there is one.
   * @param {int} itemContentDescriptionId Item content description id
   * @returns {null|object} The previous curriculum item, or null
   */
  const findPreviousItem = (itemContentDescriptionId) => {
    if (!enrollment || !item) {
      return null;
    }
    let previousItem = null;
    const { content } = enrollment;
    const { curriculum_items: curriculumItems } = content;
    for (let i = 0; i < curriculumItems.length; i++) {
      const { content_description: contentDescription } = curriculumItems[i];
      if (itemContentDescriptionId === contentDescription.id) {
        // We found the current item, return the previous one
        return previousItem;
      }
      previousItem = curriculumItems[i];
    }
    return null;
  };

  /**
   * Find the id and URL for the previous activity before the current item, assuming that we have curriculum items.
   * @returns {object} An object containing the id and url of the previous activity, empty object otherwise
   */
  const findPreviousActivityFromItems = () => {
    if (!item) {
      return {};
    }
    // We have an item, this must be a part of a career path or collection
    const { content_description_id: itemContentDescriptionId } = item;
    const previousItem = findPreviousItem(itemContentDescriptionId);
    if (!previousItem) {
      return {};
    }
    // Find the last activity in the last module of the previous item
    const { learning_modules: learningModules } = previousItem;
    // If there's no learning modules, we are looking at a standalone activity, just return the info
    if (!learningModules) {
      return {
        id: previousItem.content_description.id,
        url: createUrl(previousItem.content_description.id, 'enrollment'),
      };
    }
    const lastLearningModuleIndex = learningModules.length - 1;
    const lastLearningModule = learningModules[lastLearningModuleIndex];
    const { activities } = lastLearningModule;
    const lastActivityIndex = activities.length - 1;
    const lastActivity = activities[lastActivityIndex];
    return {
      id: lastActivity.id,
      url: createUrl(lastActivity.id, previousItem.content_description.id),
    };
  };

  /**
   * Find the next curriculum item from the provided id, if there is one.
   * @param {int} itemContentDescriptionId Item content description id
   * @returns {null|object} The next curriculum item, or null
   */
  const findNextItem = (itemContentDescriptionId) => {
    if (!enrollment || !item) {
      return null;
    }
    const { content } = enrollment;
    const { curriculum_items: curriculumItems } = content;
    let foundCurrentItem = false;
    for (let i = 0; i < curriculumItems.length; i++) {
      if (foundCurrentItem) {
        // We found the current item, which means we are now on the next item
        return curriculumItems[i];
      }
      const { content_description: contentDescription } = curriculumItems[i];
      if (itemContentDescriptionId === contentDescription.id) {
        // We found the current item
        foundCurrentItem = true;
      }
    }
    return null;
  };

  /**
   * Find the id and URL for the next activity after the current item, assuming that we have curriculum items.
   * @returns {object} An object containing the id and url of the next activity, empty object otherwise
   */
  const findNextActivityFromItems = () => {
    if (!item) {
      return {};
    }
    // We have an item, this must be a part of a career path or collection
    const { content_description_id: itemContentDescriptionId } = item;
    const nextItem = findNextItem(itemContentDescriptionId);
    if (!nextItem) {
      return {};
    }
    // Find the first activity in the first module of the previous item
    const { learning_modules: learningModules } = nextItem;
    // If there's no learning modules, we are looking at a standalone activity, just return the info
    if (!learningModules) {
      return {
        id: nextItem.content_description.id,
        url: createUrl(nextItem.content_description.id, 'enrollment'),
      };
    }
    const firstLearningModule = learningModules[0];
    const { activities } = firstLearningModule;
    const firstActivity = activities[0];
    return {
      id: firstActivity.id,
      url: createUrl(firstActivity.id, nextItem.content_description.id),
    };
  };

  /**
   * Enroll in a piece of content, if we haven't started it already
   * @param {in} id - ID of the content we are going to enroll in
   * @returns void
   */
  const enrollInContent = async (id) => {
    if (startedIds.indexOf(id) !== -1) {
      return;
    }
    // Add the id to the started id list
    const newStartedIds = [...startedIds, id];
    setStartedIds(newStartedIds);
    const excludeProgressBefore = getExcludeProgressBefore(item, enrollment);
    const data = { exclude_progress_before: excludeProgressBefore };
    await Agents.enrollments.enroll(id, data);
  };

  /**
   * Enroll in the parent module of an activity, if one exists.
   * @param {int} activityId - Activity ID we are trying to enroll in the parent module of.
   * @returns void
   */
  const enrollInModule = async (activityId) => {
    const moduleId = findModuleId(activityId);
    if (!moduleId) {
      return;
    }
    await enrollInContent(moduleId);
  };

  /**
   * Load an enrollment from the server, and transform it to match the expected format.
   * Will also handle normalizing enrollment activities (such as direct enrollments into labs).
   * If the id passed is the same id that is already contained in the enrollment variable, nothing happens.
   * If this is an enrollment activity, we hit a different backend route to launch the enrollment. Otherwise the process is the same.
   * @param {int} id - The enrollment id.
   * @param {boolean} isEnrollmentActivity - True if this is an enrollment activity.
   * @returns void
   */
  const loadEnrollment = async (id, isEnrollmentActivity = false) => {
    // Only load an enrollment if the id is different from the current one
    if (enrollment?.id === id) {
      return;
    }
    setLoadingEnrollment(true);
    try {
      // Determine the agent to use based on whether or not this is an activity (otherwise everything is the same)
      const agentToUse = isEnrollmentActivity ? Agents.enrollments.getEnrollmentActivity : Agents.enrollments.getEnrollmentById;
      const enrollmentResult = await agentToUse(id);

      const {
        content: enrollmentContent,
        completed_content_description_ids: newCompletedIds,
        started_content_description_ids: newStartedIds,
        is_activity: isActivity,
        content_description_id: contentDescriptionId,
      } = enrollmentResult;

      const { content_description: contentDescription, curriculum_items: curriculumItems, meta, id: enrollmentContentId } = enrollmentContent || {};

      const {
        title,
        ceu_count: ceuCount,
        is_free: isFree,
        thumbnail_url: thumbnail,
        short_description: shortDescription,
        long_description: longDescription,
        instructors_info: instructors,
        duration_seconds: duration,
        content_type: contentType,
      } = contentDescription || {};
      const { id: contentTypeId } = contentType || {};
      const description = shortDescription || longDescription;

      // Filter our Coming Soon curriculum items
      const filteredCurriculumItems = curriculumItems?.filter((curriculumItem) => curriculumItem.content_description.status !== 'Coming Soon');
      const hasCurriculumItems = !!filteredCurriculumItems?.length;

      // Format our resources array (if we have any)
      const resources = meta?.supplementalMaterials?.length
        ? meta.supplementalMaterials.map((material) => {
            return {
              id: material.url,
              label: material.title,
              href: material.url,
            };
          })
        : null;

      // Handle the special case of an Activity Enrollment (enrolling directly into a Lab or some other standalone type).
      const additionalProperties = {};
      if (isActivity) {
        // We need to simulate a learning module with one item in it
        const activityContentDescription = contentDescription || {};
        const activityIsComplete = newCompletedIds.includes(contentDescriptionId);
        const learningModules = [
          {
            title,
            id: enrollmentContentId,
            activities: [
              {
                // We need the whole content description, as well as a content type id to properly simulate an activity
                ...activityContentDescription,
                id: contentDescriptionId,
                title,
                isFree,
                complete: activityIsComplete,
                content_type_id: contentTypeId,
                duration,
              },
            ],
          },
        ];
        additionalProperties.content = enrollmentContent;
        additionalProperties.content.learning_modules = learningModules;
      }

      // Set the enrollment
      const newEnrollment = {
        ...enrollmentResult,
        ...additionalProperties,
        content: {
          ...enrollmentContent,
          curriculum_items: filteredCurriculumItems,
        },
        title,
        thumbnail,
        resources,
        ceuCount,
        description,
        instructors,
        hasCurriculumItems,
      };
      setEnrollment(newEnrollment);
      setCompletedIds(newCompletedIds);
      setStartedIds(newStartedIds);
    } catch (err) {
      Bugsnag.notify(err);
      setEnrollment(null);
    }
    setLoadingEnrollment(false);
  };

  /**
   * Load the curriculum item, unless we have already loaded it.
   * @param {int} id - ID of the curriculum we are going to load
   * @returns void
   */
  const loadItem = async (id) => {
    // Only load an item if the id is different from the current one
    if (item?.id === id) {
      return;
    }
    setLoadingItem(true);
    try {
      const foundItem = findItem(id);
      const preparedItem = normalizeItem(foundItem);
      await enrollInContent(id);
      setItem(preparedItem);
    } catch (err) {
      Bugsnag.notify(err);
      setItem(null);
    }
    setLoadingItem(false);
  };

  /**
   * Formats activity links for next and previous activities, returns an object with id and url for the provided activity.
   * @param {object} act The activity that we are formatting for use as a link
   * @returns {object} An object with the activity id and url.
   */
  const formatActivityLink = (act) => {
    return {
      id: act.id,
      url: createUrl(act.id),
    };
  };

  /**
   * Get the previous activity in a format suitable for the outline, which is either an empty object or one with id and url.
   * @param {object} currentActivity The current activity we are looking at to determine the previous activity in the outline.
   * @returns {object} The formatted activity (id and url) for the previous activity (if found)
   */
  const findPreviousActivityForOutline = (currentActivity) => {
    if (!enrollment || !currentActivity) {
      return {};
    }
    const learningModules = getLearningModules();
    let previousActivity = {};
    for (let i = 0; i < learningModules.length; i++) {
      const learningModule = learningModules[i];
      const { activities } = learningModule;
      // Loop over the activities, returning the previous activity as soon as we reach it
      for (let j = 0; j < activities.length; j++) {
        const act = activities[j];
        if (previousActivity?.id && act.id === currentActivity.id) {
          return formatActivityLink(previousActivity);
        }
        previousActivity = act;
      }
    }
    // If we are still here, the previous activity may be in a different curricula item
    return findPreviousActivityFromItems();
  };

  /**
   * Get the next activity in a format suitable for the outline, which is either an empty object or one with id and url.
   * @param {object} currentActivity The current activity we are looking at to determine the next activity in the outline.
   * @returns {object} The formatted activity (id and url) for the next activity (if found)
   */
  const findNextActivityForOutline = (currentActivity) => {
    if (!enrollment || !currentActivity) {
      return {};
    }
    const learningModules = getLearningModules();
    let captureNext = false;
    for (let i = 0; i < learningModules.length; i++) {
      const learningModule = learningModules[i];
      const { activities } = learningModule;
      // Loop over the activities, returning the next activity as soon as we reach it
      for (let j = 0; j < activities.length; j++) {
        const act = activities[j];
        if (captureNext) {
          return formatActivityLink(act);
        }
        if (act.id === currentActivity.id) {
          captureNext = true;
        }
      }
    }
    // If we are still here, the next activity may be in a different curricula item
    return findNextActivityFromItems();
  };

  /**
   * Check to see if the passed in activity is in the learning modules.
   */
  const activityInLearningModules = (activityId) => {
    const learningModules = getLearningModules();
    if (!learningModules?.length) {
      return false;
    }
    for (let i = 0; i < learningModules.length; i++) {
      const mod = learningModules[i];
      const { activities } = mod;
      for (let k = 0; k < activities.length; k++) {
        const act = activities[k];
        if (act.id === activityId) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Add information obtained by examining the outline to the provided current activity.
   * @param {object} currentActivity The current activity we are preparing to display
   * @returns The fully prepped activity, adding outline-related information to the provided currentActivity object.
   */
  const addActivityOutlineInfo = (currentActivity) => {
    const learningModules = getLearningModules();
    let activitiesTotal = 0;
    let activitiesCompleted = 0;
    let requiredActivitiesTotal = 0;
    let requiredActivitiesCompleted = 0;
    let activityTitle = '';
    let activityHeader = '';
    let activityOptional = false;
    let currentActivityComplete = false;
    let xp = 0;
    let isFree = false;
    learningModules.forEach((mod, i) => {
      const { activities } = mod;
      let activityActive = false;
      activities.forEach((act, j) => {
        activityActive = act.id === currentActivity.id;
        const activityIsComplete = completedIds.indexOf(act.id) !== -1;
        if (activityActive) {
          // Grab some information about our current activity
          activityHeader = `LESSON ${i + 1}.${j + 1}`;
          activityTitle = FormatUtil.formatActivityTitle(act.title, i + 1, j + 1);
          activityOptional = act.optional;
          xp = act.experience_points_total || 0;
          isFree = act.is_free;
          currentActivityComplete = activityIsComplete;
        }

        // Activity totals and progress
        activitiesTotal += 1;
        if (!act.optional) {
          requiredActivitiesTotal += 1;
        }
        if (activityIsComplete) {
          activitiesCompleted += 1;
          if (!act.optional) {
            requiredActivitiesCompleted += 1;
          }
        }
      });
    });

    // Get our previous and next activities (if there are any)
    const previousActivity = findPreviousActivityForOutline(currentActivity);
    const nextActivity = findNextActivityForOutline(currentActivity);

    // Calculate progress
    const progress = activitiesTotal > 0 ? Math.floor((activitiesCompleted / activitiesTotal) * 100) : 0;
    const requiredProgress = requiredActivitiesTotal > 0 ? Math.floor((requiredActivitiesCompleted / requiredActivitiesTotal) * 100) : 100;
    return {
      ...currentActivity,
      activityTitle,
      activityHeader,
      previousActivity,
      nextActivity,
      isFree,
      xp,
      progress,
      requiredProgress,
      optional: activityOptional,
      complete: currentActivityComplete,
    };
  };

  /**
   * Load the activity, unless we have already loaded it.
   * @param {int} id - ID of the activity we are going to load
   * @returns void
   */
  const loadActivity = async (id) => {
    // Only load an activity if the id is different from the current one
    if (activity?.id === id) {
      return;
    }
    // @Immersive If this activity is not in the current learning modules, we are NOT ready to load the activity
    // as we are probably switching controllers within a collection. In this case, load activity will end up being
    // called again, once the current enrollment and item are ready.
    if (!activityInLearningModules(id)) {
      return;
    }
    setLoadingActivity(true);
    let authorized = true;
    try {
      try {
        await enrollInModule(id);
      } catch (e) {
        Bugsnag.notify(e);
      }
      await enrollInContent(id);
      let query = '';
      const excludeProgressBefore = getExcludeProgressBefore(item, enrollment);
      if (excludeProgressBefore) {
        query = `?${queryString.stringify({ exclude_progress_before: excludeProgressBefore })}`;
      }
      const activityResult = await Agents.catalog.launch(id, query);
      const activityResultWithId = { ...activityResult, id, authorized };
      const normalized = normalizeActivity(activityResultWithId);
      setActivity(addActivityOutlineInfo(normalized));
      setLoadingActivity(false);
    } catch (e) {
      if (e && e.response && e.response.status === 403) {
        // This is content that the user does not have access to!
        authorized = false;
        const activityInfo = findActivityInEnrollment(id);
        if (activityInfo) {
          const normalized = normalizeActivity({ ...activityInfo, authorized });
          setActivity(addActivityOutlineInfo(normalized));
        }
        setLoadingActivity(false);
      } else {
        Bugsnag.notify(e);
        setActivity(null);
      }
    }
  };

  /**
   * Enrollment activities are not technically loaded from the server, they are built based on the information already
   * available in the enrollment.  This function handles that logic.
   * @returns void
   */
  const loadEnrollmentActivity = () => {
    setLoadingActivity(true);
    if (!enrollment?.is_activity) {
      return;
    }
    const { content } = enrollment;
    const { content_description: contentDescription, id: activityId } = content;
    const { content_type: contentType, permalink: slug, id: contentDescriptionId } = contentDescription;
    const { nice_name: type } = contentType;

    // Only change the activity if the id is different from the current one
    if (activity?.id === contentDescriptionId) {
      setLoadingActivity(false);
      return;
    }

    // Piece together our activity from what we have in the enrollment
    const simulatedActivity = {
      activityId,
      ...contentDescription,
      ...content,
      id: contentDescriptionId,
      type,
      slug,
      // It appears that enrollment activities should always have authorized set to true
      authorized: true,
    };
    // Now run the simulated activity through the normalization logic, and we are good to go
    const normalized = normalizeActivity(simulatedActivity);
    setActivity(addActivityOutlineInfo(normalized));
    setLoadingActivity(false);
  };

  /**
   * Item activities are basically the same thing as an Enrollment Activity, except they are contained within some sort of collection/curriculum.
   * Like Enrollment Activities, they are not technically loaded from the server, they are built based on the information already
   * available in the enrollment.
   * Unlike Enrollment Activities, the item in this case will share the ID of the activity, so the activity can be loaded normally once the item is prepared.
   * @param {int} id - ID of the activity we are going to load
   * @returns void
   */
  const loadItemActivity = (id) => {
    // Find the item by the activity id
    const itemActivityFromEnrollment = findItem(id);
    if (!itemActivityFromEnrollment) {
      return;
    }
    setLoadingItem(true);
    // Simulate the curriculum item that we found, based on the activity within the enrollment.
    const { content_description: contentDescription } = itemActivityFromEnrollment;
    const { title, duration_seconds: duration, permalink, short_description: shortDescription, content_type: contentType } = contentDescription;
    const { nice_name: type, id: contentTypeId } = contentType;
    const simulatedItem = {
      ...itemActivityFromEnrollment,
      contentDescription,
      content_description_id: id,
      isItemActivity: true,
      learning_modules: [
        {
          type: 'Course Module',
          duration,
          id,
          title,
          isItemActivity: true,
          activities: [
            {
              id,
              title,
              permalink,
              short_description: shortDescription,
              duration,
              type,
              content_type_id: contentTypeId,
              isItemActivity: true,
            },
          ],
        },
      ],
    };
    const preparedItem = normalizeItem(simulatedItem);
    setItem(preparedItem);

    setLoadingItem(false);
  };

  /**
   * Return a "clean" version of the activity, which contains the information necessary to render the outline.
   * @param {object} act The activity object from the learning module.
   * @param {object} _item The item level object from the learning module.
   * @param {boolean} isComplete T/F depending on if the activity is complete
   * @param {boolean} isActive T/F depending on if the activity is the current active activity
   * @param {int} activityNum The index of the activity in the learning module
   * @param {int} moduleNum The index of the learning module in the modules array
   * @returns {object} The properly formatted activity
   */
  const formatActivityForOutline = (act, _item, isComplete, isActive, activityNum, moduleNum) => {
    const xp = act.experience_points_total || 0;
    return {
      id: act.id,
      title: FormatUtil.formatActivityTitle(act.title, moduleNum + 1, activityNum + 1),
      duration: act.duration,
      active: isActive,
      num: activityNum + 1,
      url: createUrl(act.id, _item?.id),
      moduleNum: moduleNum + 1,
      complete: isComplete,
      isFree: act.is_free,
      contentTypeId: act.content_type_id,
      optional: act.optional,
      showUpgradeInterstitial: act.show_upgrade,
      xp,
    };
  };

  /**
   * Examine the enrollment and activity, building and setting the outline
   * @returns void
   */
  const buildOutline = () => {
    if (!enrollment || !activity) {
      return;
    }
    const learningModules = getLearningModules(); // Gets modules for current item

    const convertModulesToOutline = (modules, _item) => {
      const output = {
        activitiesTotal: 0,
        activitiesCompleted: 0,
        requiredActivitiesTotal: 0,
        requiredActivitiesCompleted: 0,
        incompleteOutline: [],
        outline: [],
        progress: 0,
        requiredProgress: 100,
      };
      // Build an outline for each incomplete module
      const incompleteOutline = [];

      // Build an outline for each module
      const newOutline = modules.map((module, i) => {
        const { activities, id, duration, title: moduleTitle } = module;

        // Track any incomplete activities in the module
        const incompleteActivities = [];

        // Track if the module/Activity is active
        let activityActive = false;
        let moduleActive = false;

        // Initialize the module outline item
        const moduleOutlineItem = {
          id: id || i,
          title: moduleTitle,
          duration,
          num: i + 1,
        };

        // Build the activities for the module outline item
        moduleOutlineItem.activities = activities.map((moduleActivity, j) => {
          // Track if the activity is active
          activityActive = moduleActivity.id === activity.id;
          if (activityActive) {
            moduleActive = true;
          }
          // Check if the activity is complete
          const activityIsComplete = completedIds.includes(moduleActivity.id);
          // Format the activity for the outline
          const cleanActivity = formatActivityForOutline(moduleActivity, _item, activityIsComplete, activityActive, j, i);

          // If the activity is not complete and not optional, add it to the incomplete activities array
          // for display at the end of a course to show which items you need to finish in order to complete the course
          if (!activityIsComplete && !moduleActivity.optional) {
            incompleteActivities.push(cleanActivity);
          }
          // Increment the total and required activities
          output.activitiesTotal += 1;
          if (!moduleActivity.optional) {
            output.requiredActivitiesTotal += 1;
          }
          // Increment the completed activities
          if (activityIsComplete) {
            output.activitiesCompleted += 1;
            if (!moduleActivity.optional) {
              output.requiredActivitiesCompleted += 1;
            }
          }

          // Return the formatted activity
          return cleanActivity;
        });

        // If there are any incomplete activities in this module, add them to the incomplete outline
        if (incompleteActivities.length) {
          // Overwrite the module outline item to show only incomplete activities
          const incompleteModule = { ...moduleOutlineItem };
          incompleteModule.activities = incompleteActivities;
          // Add the incomplete module to the incomplete outline
          incompleteOutline.push(incompleteModule);
        }

        // Set helper properties for the module outline item
        moduleOutlineItem.active = moduleActive;
        moduleOutlineItem.experience_points_total = module.experience_points_total;

        return moduleOutlineItem;
      });

      // Set the outline and helper properties
      output.outline = newOutline;
      output.incompleteOutline = incompleteOutline;
      // Set the progress for the content
      const { activitiesTotal, activitiesCompleted, requiredActivitiesTotal, requiredActivitiesCompleted } = output;
      const progress = activitiesTotal > 0 ? Math.floor((activitiesCompleted / activitiesTotal) * 100) : 0;
      const requiredProgress = requiredActivitiesTotal > 0 ? Math.floor((requiredActivitiesCompleted / requiredActivitiesTotal) * 100) : 100;
      output.progress = progress;
      output.requiredProgress = requiredProgress;
      return output;
    };

    // Build the outline for the current course
    const currentCourseOutline = convertModulesToOutline(learningModules);

    let collectionOutline = null;
    let collectionProgress = 0;
    let collectionRequiredProgress = 100;
    const collectionIncompleteCourses = [];

    // Check for enrollment type of collection
    const isCollection = enrollment?.content?.curriculum_items?.length;
    if (isCollection) {
      // Track collection activities
      let collectionActivitiesTotal = 0;
      let collectionActivitiesCompleted = 0;
      let collectionRequiredActivitiesTotal = 0;
      let collectionRequiredActivitiesCompleted = 0;

      if (!enrollment?.content?.children?.length) {
        BugsnagUtil.notifyWithNamedMetadata(new Error('Collection outline'), 'enrollment', enrollment);
      }

      // Build a course outline for each item in the collection
      collectionOutline = enrollment?.content?.children?.map((collectionItem) => {
        const collectionItemOutline = convertModulesToOutline(collectionItem?.content_item?.learning_modules, collectionItem);
        collectionActivitiesTotal += collectionItemOutline?.activitiesTotal || 0;
        collectionActivitiesCompleted += collectionItemOutline?.activitiesCompleted || 0;
        collectionRequiredActivitiesTotal += collectionItemOutline?.requiredActivitiesTotal || 0;
        collectionRequiredActivitiesCompleted += collectionItemOutline?.requiredActivitiesCompleted || 0;

        // If there are any incomplete activities in this item, add it to the collectionIncompleteCourses array
        if (collectionItemOutline?.incompleteOutline?.length) {
          collectionIncompleteCourses.push(collectionItem);
        }

        return {
          ...collectionItem,
          ...collectionItemOutline,
        };
      });

      // Calculate collection progress
      collectionProgress = collectionActivitiesTotal > 0 ? Math.floor((collectionActivitiesCompleted / collectionActivitiesTotal) * 100) : 0;
      collectionRequiredProgress = collectionRequiredActivitiesTotal > 0 ? Math.floor((collectionRequiredActivitiesCompleted / collectionRequiredActivitiesTotal) * 100) : 100;
    }
    // Set the outline
    setOutline({
      enrollmentId: enrollment.id,
      // Collection outline
      collectionOutline,
      collectionProgress,
      collectionRequiredProgress,
      collectionIncompleteCourses,
      // Course outline
      outline: currentCourseOutline?.outline,
      incompleteOutline: currentCourseOutline?.incompleteOutline,
      progress: currentCourseOutline?.progress,
      activitiesTotal: currentCourseOutline?.activitiesTotal,
      activitiesCompleted: currentCourseOutline?.activitiesCompleted,
      requiredProgress: currentCourseOutline?.requiredProgress,
      requiredActivitiesTotal: currentCourseOutline?.requiredActivitiesTotal,
      requiredActivitiesCompleted: currentCourseOutline?.requiredActivitiesCompleted,
    });
  };

  /**
   * Build the menu, used in tab navigation.
   */
  const buildMenu = () => {
    const newMenu = [
      {
        id: 'feedback',
        label: 'Review this course',
      },
      {
        id: 'share',
        label: 'Share',
      },
      {
        id: 'outline',
        label: 'Outline',
        type: 'outline',
      },
    ];
    // Extract the overview and resources from the activity/enrollment
    if (activity && enrollment) {
      const { resources, description, instructors } = item || enrollment;
      if (description) {
        newMenu.push({
          id: 'overview',
          label: 'Overview',
          subtitle: 'Description',
          type: 'markdown',
          value: description,
          instructors,
        });
      }
      if (resources && resources.length) {
        newMenu.push({
          id: 'resources',
          label: 'Resources',
          subtitle: 'Supplemental Materials',
          type: 'list',
          items: resources,
        });
      }
    }
    setMenu(newMenu);
  };

  /**
   * Update completed ids by adding a new id
   * @param {int} id - Add a content id to our array of completed ids
   */
  const addCompleted = (id) => {
    if (completedIds.indexOf(id) === -1) {
      setCompletedIds([...completedIds, id]);
    }
  };

  /**
   * Returns either the item or the enrollment directly above the current activity.
   * @returns {object} The direct parent of the current activity.
   */
  const getDirectParent = () => {
    const directParent = item || enrollment;
    if (!directParent) {
      return {};
    }
    // item has {content_description}, whereas enrollment has {content.content_description}
    directParent.contentDescription =
      !directParent.content_description && directParent.content && directParent.content.content_description
        ? directParent.content.content_description
        : directParent.content_description;
    return directParent;
  };

  const isActivityLastIncomplete = () => {
    if (!enrollment || !activity || !outline) {
      return false;
    }
    const isComplete = completedIds.indexOf(activity.id) !== -1;
    if (isComplete) {
      return false;
    }

    const { activitiesTotal, activitiesCompleted } = outline;
    if (activitiesCompleted !== activitiesTotal - 1) {
      return false;
    }
    // There is one incomplete item left, check to see that it is this one
    const firstIncompleteId = findFirstIncompleteActivityId();
    return firstIncompleteId === activity.id;
  };

  const activityId = activity?.id;

  const nextItem = useMemo(() => {
    if (!item || !enrollment?.content?.curriculum_items?.length) {
      return null;
    }
    const curriculum_items = enrollment?.content?.curriculum_items || [];
    // Given the current item, find the next item in the curriculum
    for (let index = 0; index < curriculum_items.length; index++) {
      const _item = curriculum_items[index];
      const isLastItem = _item.id === curriculum_items[curriculum_items.length - 1].id;
      if (_item.id === item.id) {
        return isLastItem ? null : curriculum_items[index + 1];
      }
    }
    return null;
  }, [item, enrollment]);

  useEffect(() => {
    // This handles the changing of the outline
    if (!enrollment || !activityId || loadingActivity) {
      return;
    }
    buildOutline();
    buildMenu();
  }, [activityId, enrollment, loadingActivity, completedIds]);

  const state = useMemo(
    () => ({
      loadingEnrollment,
      loadingActivity,
      loadingItem,
      enrollment,
      item,
      nextItem,
      activity,
      completedIds,
      startedIds,
      outline,
      menu,
      includeA11yPause,
      a11yPaused,
      isPrimarySidebarOpen,
      reset,
      leaveImmersiveWithError,
      loadEnrollment,
      loadItem,
      loadActivity,
      loadEnrollmentActivity,
      loadItemActivity,
      setCompletedIds,
      setStartedIds,
      findFirstIncompleteItemId,
      findFirstIncompleteActivityId,
      findFirstIncompleteActivityIdFromItem,
      createUrl,
      addCompleted,
      getDirectParent,
      isActivityLastIncomplete,
      findModuleId,
      setIncludeA11yPause,
      setA11yPaused,
      setIsPrimarySidebarOpen,
    }),
    [loadingEnrollment, loadingActivity, loadingItem, enrollment, item, activity, completedIds, startedIds, outline, menu, includeA11yPause, a11yPaused, isPrimarySidebarOpen]
  );

  return <ImmersiveContext.Provider value={state}>{children}</ImmersiveContext.Provider>;
}

export const useImmersive = () => useContext(ImmersiveContext);
export default ImmersiveProvider;
