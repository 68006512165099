import React from 'react';
import LoadSpinner from './LoadSpinner';
import If from '../../../If/If';
import Button from '../../../Button/Button';

// This can be broken down into smaller loading and error components, not sure what those look like yet
export default function LoadingOrError({
  loading,
  loadingText,
  error,
  errorText,
  children,
  className,
  spinnerClass = 'h-14 w-14 text-gray-500',
  textClass = '',
  showReconnect = false,
}) {
  if (loading) {
    return (
      <div className={`${className || 'flex h-full w-full items-center pt-10'}`}>
        <LoadSpinner text={loadingText || 'Loading...'} spinnerClass={spinnerClass} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${className || 'h-full w-full pt-48'}`}>
        <p className={textClass}>{errorText || 'Something went wrong!'}</p>
        <If condition={showReconnect}>
          <div className="flex justify-center items-center my-8">
            <Button color="pink" onClick={() => window.location.reload()}>
              Reconnect
            </Button>
          </div>
        </If>
      </div>
    );
  }

  return children || null;
}
