import React from 'react';
import OrderSummaryRow from './OrderSummaryRow';
import { PADDLE_DISCOUNT_INFO } from '../../../../constants';

/**
 * Discount row for order summary
 * Shows code and formatted discount amount
 * @param {number} discountAmount - Discount amount - Replaces recurring_totals
 * @param {string} code - Discount code or ID
 * @param {string} rowClass - Class to apply to row
 * @returns {React.ReactNode} - Discount row
 */
function OrderSummaryDiscountRow({ discountAmount, code, rowClass }) {
  if (!discountAmount) {
    return null;
  }

  let title = 'Promotional Discount';

  const discountInfo = PADDLE_DISCOUNT_INFO[code];

  if (discountInfo) {
    title = discountInfo.description;
  }

  return <OrderSummaryRow title={title} value={`-${discountAmount}`} className={rowClass} isDiscount />;
}

export default OrderSummaryDiscountRow;
