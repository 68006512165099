import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import './settings.css';
import SettingsCollapsible from './SettingsCollapsible';
import ReauthModal from '../../components/Reauth/ReauthModal';
import Agents from '../../agents/agents';
import Title from '../../components/Title/Title';
import Modal from '../../components/Modal/Modal';

function VerificationEmailModal({ openEmailAlert, toggleVerifyEmailAlert }) {
  return (
    <Modal paddingBottom="pb-0" position="center" size="md" ariaLabelledBy="verification-modal-header" open={openEmailAlert} toggle={toggleVerifyEmailAlert} omitCloseX>
      <div className="p-6">
        <h2 className="my-4 text-base font-semibold text-center" id="verification-modal-header">
          Alternate Email Added!
        </h2>
        <div className="mb-6 text-left">
          <p>You will receive an email containing a password reset link at your alternate email address.</p>
          <p>
            Please click the link to verify your alternate email and complete the process. <b>Link expires in 24 hours</b>
          </p>
        </div>
        <div className="text-right">
          <button className="font-semibold text-right text-cyb-pink-500 hover:underline" onClick={toggleVerifyEmailAlert}>
            OK, Got it!
          </button>
        </div>
      </div>
    </Modal>
  );
}

/**
 * Edit User Account
 */
const SettingsAccount = inject(
  'authStore',
  'userStore',
  'commonStore',
  'profileStore',
  'notificationsStore'
)(
  observer(
    class SettingsAccount extends Component {
      state = {
        uId: null,
        modalOpen: false,
        reauthModalOpen: false,
        intervalId: null,
        openEmailAlert: false,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle(`Account Settings | Cybrary`);
        this.props.profileStore.setActiveTab('Account', 'setting');
        this.props.profileStore.getProfileData('settings');
        const currentState = { ...this.state, uId: this.props.userStore.user.id };
        this.setState(currentState);
        this.props.profileStore.getUserAccounts();
        this.props.profileStore.setUserCurrentEmail(this.props.userStore.user.email);
      }

      componentWillUnmount() {
        this.props.profileStore.setDefaultProfileData('settings');
        this.props.profileStore.setDefaultProfileData('email');
        this.props.profileStore.setDefaultProfileData('password');
        this.props.profileStore.setInactiveTab('Account', 'settings');
      }

      requiresReauth = (tokenCreateTime) => {
        const currentTime = moment();
        return currentTime.diff(tokenCreateTime, 'minutes') >= 5;
      };

      /**
       * Initiate export data
       */
      handleInitiateDataExport = () => {
        this.props.profileStore
          .initiateDataExport()
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'Request for data export sent. Check your email for the next step.',
              icon: 'mail',
            });
          })
          .catch((error) => {
            Bugsnag.notify(error);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Export has not been initiated. Please try again. If this issue persists, please contact support@cybrary.it.',
            });
          });
      };

      /**
       * Delete account
       */
      handleDeleteAccount = async () => {
        try {
          await Agents.authGoogle.magicAction({ action: 'deleteUser' });
          const newState = {
            ...this.state,
            reauthModalOpen: true,
            modalOpen: false,
          };
          this.setState(newState);
        } catch (e) {
          this.props.commonStore.triggerToast('error', {
            content: 'Something went wrong. Account has not been deleted. Please try again. If this issue persists, please contact support@cybrary.it.',
          });
        }
      };

      /**
       * Delete accoutn confirmation modal
       */
      toggleConfirmationModal = () => {
        const currentState = { ...this.state, modalOpen: !this.state.modalOpen };
        this.setState(currentState);
      };

      /**
       * Add default values to fields
       */
      addDefaultValues = (form, storeData) => {
        const newForm = { ...form };
        const storeDataKeys = Object.keys(storeData);
        storeDataKeys.forEach((item) => {
          const dataItem = storeData[item];
          if (!!dataItem && !!newForm.fields[item]) {
            if (item === 'notifications') {
              if (dataItem['learning-reminders'] && dataItem['learning-reminders'].channels && dataItem['learning-reminders'].channels.indexOf('mail') === -1) {
                newForm.fields[item].defaultValue = ['Opt Out'];
              }
            } else {
              newForm.fields[item].defaultValue = dataItem;
            }
          }
        });
        return newForm;
      };

      /**
       * handle opt in submit
       */
      handleOptInSubmit = (data) => {
        const transformData = {
          tos: true,
          subscribe: !data?.cybrary_mailing_list?.length, // opt-out button, subscribe should be the inverse of the button value
        };
        Agents.profile
          .putTosOptIn(transformData)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'Settings have been updated',
            });
          })
          .catch(() => {
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Information not submitted. Please try again later. If this issue persists, please contact support@cybrary.it.',
            });
          });
      };

      handlePreferencesSubmit = (data) => {
        // Format data to send to notification preferences route
        const formatData = {
          notifications: [
            {
              type: 'learning-reminders',
              channels: ['database', 'mail'],
            },
          ],
          pausedUntil: null,
        };
        if (data.notifications && data.notifications.length) {
          if (data.notifications.indexOf('Opt Out') !== -1) {
            formatData.notifications[0].channels.pop();
          }
        }

        this.props.notificationsStore
          .updatePreferences(formatData)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'Notification preferences have been updated.',
            });
          })
          .catch(() => {
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Unable to update notification preferences.',
            });
          });
      };

      /**
       * handle email change callback
       * construct toaster styling and message
       */
      handleEmailChangeCallback = (type) => {
        if (type === 'success') {
          this.props.commonStore.triggerToast('success', {
            content: 'Settings have been updated',
          });
        } else if (type === 'unprocessed') {
          this.props.commonStore.triggerToast('error', {
            content: 'This email address has been taken already',
          });
        } else {
          this.props.commonStore.triggerToast('error', {
            content: 'Something went wrong. Information not submitted. Please try again later. If this issue persists, please contact support@cybrary.it.',
          });
        }
      };

      /**
       * Handle adding new emails
       */
      handleAddAdditionalEmail = async () => {
        try {
          await Agents.authGoogle.magicAction({ action: 'addAccount', email: this.props.profileStore.newEmailInput });
          const newState = {
            ...this.state,
            reauthModalOpen: true,
          };
          this.setState(newState);
        } catch (e) {
          let toastMessage = 'Something went wrong. Unable to change primary email.';
          const emailError = e.response?.data?.errors?.email?.[0];

          if (emailError) {
            toastMessage = emailError;
          }

          Bugsnag.notify(e);

          this.props.commonStore.triggerToast('error', {
            content: toastMessage,
          });
        }
      };

      /**
       * Handle removing backup emails
       */
      handleRemoveAdditionalEmail = async (email) => {
        try {
          await Agents.authGoogle.magicAction({ action: 'removeAccount', email });
          const newState = {
            ...this.state,
            reauthModalOpen: true,
          };
          this.setState(newState);
        } catch (e) {
          Bugsnag.notify(e);
          this.props.commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to remove this backup email account.',
          });
        }
      };

      /**
       * Handle removing backup emails
       */
      handleVerificationAndForgotPassword = async (email, isReset) => {
        const toastContent = isReset
          ? 'Password reset email has been sent. Please check your email for instructions on resetting your password.'
          : 'Verification email has been sent. Please check your email for instructions on verifying your email.';
        if (isReset) {
          try {
            await Agents.authGoogle.passwordResetEmail({ email });
            this.props.commonStore.triggerToast('success', {
              content: toastContent,
            });
          } catch (e) {
            Bugsnag.notify(e);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Unable to process this request.',
            });
          }
        } else {
          this.props.profileStore
            .resetPassword({ email })
            .then(() => {
              this.props.commonStore.triggerToast('success', {
                content: toastContent,
              });
            })
            .catch(() => {
              this.props.commonStore.triggerToast('error', {
                content: 'Something went wrong. Unable to process this request.',
              });
            });
        }
      };

      handleMakeEmailPrimary = async (email) => {
        try {
          await Agents.authGoogle.magicAction({ action: 'setPrimary', email });
          const newState = {
            ...this.state,
            reauthModalOpen: true,
            modalOpen: false,
          };
          this.setState(newState);
        } catch (e) {
          Bugsnag.notify(e);
          this.props.commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to change primary email.',
          });
        }
      };

      toggleVerifyEmailAlert = () => {
        const newState = {
          ...this.state,
          openEmailAlert: !this.state.openEmailAlert,
        };
        this.setState(newState);
      };

      dismissReauthModal = () => {
        const newState = {
          ...this.state,
          reauthModalOpen: false,
        };
        this.setState(newState, () => {
          this.props.profileStore.getUserAccounts();
          this.props.profileStore.setProfileDataLoading('accounts', false);
        });
      };

      render() {
        const { modalOpen, openEmailAlert } = this.state;
        const userName = this.props.userStore.user.name ? this.props.userStore.user.name : this.props.userStore.user.email;
        setTimeout(() => {
          this.props.profileStore.setProfileDataLoading('email', false);
          this.props.profileStore.setProfileDataLoading('password', false);
        }, 250);
        return (
          <Container className="settings" size="md">
            <Title title="Account Settings" />
            <SettingsCollapsible
              userName={userName}
              userEmails={this.props.profileStore.profileData.accounts}
              userNewEmailInput={this.props.profileStore.newEmailInput}
              setNewEmailInput={this.props.profileStore.setNewEmailInput}
              handleAddAdditionalEmail={this.handleAddAdditionalEmail}
              handleRemoveAdditionalEmail={this.handleRemoveAdditionalEmail}
              handleMakeEmailPrimary={this.handleMakeEmailPrimary}
              handleVerificationAndForgotPassword={this.handleVerificationAndForgotPassword}
              location={this.props.location}
              preferencesData={this.props.notificationsStore.preferencesData}
              settingsData={this.props.profileStore.profileData.settings}
              addDefaultValues={this.addDefaultValues}
              handlePreferencesSubmit={this.handlePreferencesSubmit}
              handleOptInSubmit={this.handleOptInSubmit}
              handleInitiateDataExport={this.handleInitiateDataExport}
              modalOpen={modalOpen}
              handleDeleteAccount={this.handleDeleteAccount}
              showVerficationEmailModal={this.showVerficationEmailModal}
              toggleConfirmationModal={this.toggleConfirmationModal}
            />
            <VerificationEmailModal openEmailAlert={openEmailAlert} toggleVerifyEmailAlert={this.toggleVerifyEmailAlert} />
            <ReauthModal open={this.state.reauthModalOpen} handleButtonClick={this.dismissReauthModal} />
          </Container>
        );
      }
    }
  )
);

export default withRouter(SettingsAccount);
