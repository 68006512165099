import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import WidgetContainer from '../../components/Container/WidgetContainer';
import RoleDropdown from '../../components/Dashboard/CybraryAssessmentsWidget/RoleDropdown';
import Loading from '../../components/Loading/Loading';
import If from '../../components/If/If';
import StyledError from '../../components/Error/StyledError';
import AssessmentResultsGraphWidget from '../../components/Measure/Assessments/AssessmentResultsGraphWidget';
import AssesmentResultsTableWidget from '../../components/Measure/Assessments/AssesmentResultsTableWidget';
import useQueryParams from '../../hooks/useQueryParams';

const AssessmentResults = inject(
  'authStore',
  'userStore',
  'reportsStore',
  'commonStore'
)(
  observer(({ authStore, userStore, reportsStore, commonStore }) => {
    const team = userStore?.teamData;

    const { downloadReport, downloadLoading } = reportsStore;

    const navigate = useNavigate();

    const { memberId } = useQueryParams();

    const { tracks, error, isLoading: isLoadingTracks } = useCybAssessmentAnalytics();

    const roles = useMemo(
      () =>
        tracks?.[userStore.user?.id]
          ?.filter((track) => track.status !== 'Unlisted')
          .concat()
          .reverse(),
      [tracks, userStore.user?.id]
    );

    const [selectedRole, setSelectedRole] = useState(roles?.[0] ?? null);

    const onChangeRole = useCallback(
      (roleId) => {
        const role = roles?.find((item) => item['content-id'] === roleId);

        if (role) {
          setSelectedRole(role);
        }

        if (memberId) {
          navigate(window.location.pathname);
        }
      },
      [roles, memberId]
    );

    useEffect(() => {
      commonStore.setPageTitle('Measure - Assessment Results | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    useEffect(() => {
      if (roles?.length > 0 && !selectedRole) {
        setSelectedRole(roles[0]);
      }
    }, [roles]);

    return (
      <Container className="pt-0 sm-576:px-4" md omitPadding>
        <Title title="Assessment Results" omitPadding wrapperClasses="my-8" classes="text-2xl lg:text-2xl" />
        <div className="flex flex-col">
          <div className="flex flex-col py-8 px-14 mb-8 text-[15px] bg-gray-100">
            <p>Review your Team&apos;s assessment results to gain insights into their strengths and weaknesses by topic.</p>
          </div>
          <If condition={isLoadingTracks}>
            <Loading message="Loading..." />
          </If>
          <If condition={error}>
            <StyledError error={error} />
          </If>
          <If condition={!isLoadingTracks && !error}>
            <WidgetContainer omitPadding omitBorder omitBackground>
              <div className="flex absolute top-0 right-0 z-20 flex-row items-center mt-4 mr-4">
                <span className="mr-2 font-bold capitalize">role:</span>
                <RoleDropdown optionData={roles} controlledValue={selectedRole} onChange={onChangeRole} isCompact />
              </div>
              <AssessmentResultsGraphWidget team={team} selectedRole={selectedRole} />
              <div className="my-5 mx-auto" />
              <AssesmentResultsTableWidget team={team} selectedRole={selectedRole} downloadReport={downloadReport} downloadLoading={downloadLoading} />
            </WidgetContainer>
          </If>
        </div>
      </Container>
    );
  })
);

export default AssessmentResults;
